import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import persistAutoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import AsyncStorage from '@react-native-async-storage/async-storage';
import rootSaga from './rootSaga';
//reducers
import appActivityReducer from './appActivity/appActivity.slice';
import { IInitialState } from './appActivity/appActivity.types';
import authReducer from './auth/auth.reducer';
import biteReducer from './bite/bite.reducer';
import playlistReducer from './playlist/playlist.reducer';
import createPlaylistReducer from './createPlaylist/createPlaylist.reducer';
import iconReducer from './icons/icons.reducer';
import biteQuestionReducer from './biteQuestion/biteQuestion.reducer';
import createBiteReducer from './createBite/createBite.reducer';
import biteSummaryReducer from './biteSummary/biteSummary.reducer';
import sharePanelReducer from './sharePanel/sharePanel.slice';
import bottomPanelReducer from './bottomPanel/bottomPanel.slice';
import orgReducer from './org/org.slice';
import homeScreenReducer from './homeScreen/homeScreen.slice';
import exploreBitesSlice from './explore/explore.slice';
import menuStateSlice from './menuState/menuState.slice';
import modalControllerSlice from './modalController/modalController.slice';
import appModalsSlice from './appModals/appModals.slice';
import draftsSlice from './drafts/drafts.slice';
import feedSlice from './feed/feed.slice';
import bitesFeedSlice from './bitesFeed/bitesFeed.slice';
import questionnaireSlice from './questionnaire/questionnaire.slice';
import { isWeb } from '../utils/dimensions';
import authFormSlice from './authForm/authForm.slice';
import analyticsSlice from './analytics/analytics.slice';
import cloudAssetsSlice from './cloudAssets/cloudAssets.slice';
import analyticsOverviewSlice from './analyticsOverview/analyticsOverview.slice';
import videoEditorSlice from './videoEditor/videoEditor.slice';
import videoEditorLeftSidebarSlice from './videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import attributesSlice from './attributes/attributes.slice';
import editAiGenerationSlice from './editAiGeneration/editAiGeneration.slice';
import aiGenerationSlice from './aiGeneration/aiGeneration.slice';
import aiGenerationScriptsSlice from './aiGenerationScripts/aiGenerationScripts.slice';

const appActivityPersistConfig = {
  key: 'appActivity',
  storage: AsyncStorage,
  stateReconciler: persistAutoMergeLevel1,
  whitelist: ['acrossSessionsId', 'currentFlow', 'banubaDraftIds', 'isGoogleSlidesExplainerVisibleInitial'],
};

const rootReducer = combineReducers({
  appActivity: persistReducer<IInitialState>(appActivityPersistConfig, appActivityReducer),
  auth: authReducer,
  bite: biteReducer,
  playlist: playlistReducer,
  createPlaylist: createPlaylistReducer,
  icons: iconReducer,
  biteQuestion: biteQuestionReducer,
  createBite: createBiteReducer,
  biteSummary: biteSummaryReducer,
  homeScreen: homeScreenReducer,
  exploreBites: exploreBitesSlice,
  org: orgReducer,
  sharePanel: sharePanelReducer,
  bottomPanel: bottomPanelReducer,
  menuState: menuStateSlice,
  modalController: modalControllerSlice,
  appModals: appModalsSlice,
  drafts: draftsSlice,
  feed: feedSlice,
  bitesFeed: bitesFeedSlice,
  questionnaire: questionnaireSlice,
  authForm: authFormSlice,
  analytics: analyticsSlice,
  analyticsOverview: analyticsOverviewSlice,
  cloudAssets: cloudAssetsSlice,
  videoEditor: videoEditorSlice,
  videoEditorLeftSidebar: videoEditorLeftSidebarSlice,
  attributes: attributesSlice,
  editAiGeneration: editAiGenerationSlice,
  aiGeneration: aiGenerationSlice,
  aiGenerationScripts: aiGenerationScriptsSlice,
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (__DEV__) {
  if (!isWeb) {
    const createReduxDebuggerMiddleware = require('redux-flipper').default;
    middlewares.push(createReduxDebuggerMiddleware());
  }
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
export default store;

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type IStore = typeof store;

// @ts-ignore
window.store = store;
