import { RootState } from '../index';

export const cloudAssetsSelector = (state: RootState) => state.editAiGeneration.cloudAssets;

export const cloudAssetIsLoadingSelector = (state: RootState) => {
  return state.editAiGeneration.cloudAssets.some((asset) => asset.isLoading);
};

export const isRestartScreenSelector = (state: RootState) => state.editAiGeneration.isRestartScreen;

export const editGenerationIdSelector = (state: RootState) => state.editAiGeneration.editGenerationId;

export const generationConfigSelector = (state: RootState) => state.editAiGeneration.generationConfig;
