import { useCallback, useEffect, useRef, useState } from 'react';

const useAudioPlayer = () => {
  const audioRef = useRef(new Audio());
  const [activeAudioId, setActiveAudioId] = useState(null);

  useEffect(() => {
    audioRef.current.onended = () => setActiveAudioId(null);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current.pause();
      setActiveAudioId(null);
    };
  }, []);

  const handlePlayVoice = useCallback(
    (id, src) => {
      if (activeAudioId === id) {
        audioRef.current.pause();
        setActiveAudioId(null);
      } else {
        audioRef.current.src = src;
        audioRef.current.play();
        setActiveAudioId(id);
      }
    },
    [activeAudioId],
  );

  return { handlePlayVoice, activeAudioId };
};

export default useAudioPlayer;
