import { useMemo } from 'react';
import { IGeneration } from '../../../../store/aiGeneration/aiGeneration.types';
import { useSelector } from 'react-redux';
import { generationByIdSelector } from '../../../../store/aiGeneration/aiGeneration.selector';
import {
  generationScriptsSelector,
  scriptsMapSelector,
} from '../../../../store/aiGenerationScripts/aiGenerationScripts.selector';
import { bitesStateMapSelector } from '../../../../store/bite/bite.selectors';
import { BITE_ERROR_MESSAGE } from '../../../aiContent/aiContent.const';

const CARDS_NUM = 4;
export const useGenerationResultItemData = (generationId: IGeneration['id']) => {
  const generation = useSelector(generationByIdSelector(generationId));
  const scriptIds = useSelector(generationScriptsSelector(generation.id));
  const scriptsMap = useSelector(scriptsMapSelector);
  const bitesStatusMap = useSelector(bitesStateMapSelector);

  const scriptIdsWithDeletedBites = useMemo(
    () =>
      scriptIds
        .map((scriptId) =>
          scriptsMap[scriptId]?.biteId && bitesStatusMap[scriptsMap[scriptId]?.biteId]?.error === BITE_ERROR_MESSAGE
            ? scriptId
            : null,
        )
        .filter(Boolean),
    [bitesStatusMap, scriptIds, scriptsMap],
  );

  const scriptBiteIds = useMemo(
    () =>
      scriptIds
        .map((scriptId) =>
          scriptsMap[scriptId]?.biteId && !scriptIdsWithDeletedBites.includes(scriptId)
            ? scriptsMap[scriptId]?.biteId
            : null,
        )
        .filter(Boolean),
    [scriptIds, scriptIdsWithDeletedBites, scriptsMap],
  );

  const activeScripts = useMemo(
    () => scriptIds.filter((id) => !scriptIdsWithDeletedBites.includes(id)),
    [scriptIds, scriptIdsWithDeletedBites],
  );

  const overflowCount = Math.max(activeScripts.length - CARDS_NUM, 0);

  return {
    CARDS_NUM,
    generation,
    scriptBiteIds,
    overflowCount,
    activeScripts,
  };
};
