import React from 'react';
import Modal from '../../../components/modals/ModalController';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import styled from 'styled-components/native';
import Button from '../../../components/shared/buttons/Button';

const DeleteScriptModal = ({ isVisible, onConfirm, onClose }) => {
  return (
    <Modal isVisible={isVisible} onBackdropPress={onClose}>
      <S.ModalContainer>
        <S.ModalTitle>Delete this Bite?</S.ModalTitle>
        <S.ModalDescription>
          We’ve noticed it’s a valuable piece of content for this project. Before you delete, consider reviewing the
          script and video. You might find a way to refine it to better fit your needs. Remember, you can always create
          new Bites later.
        </S.ModalDescription>
        <S.Footer>
          <S.CancelButton onPress={onClose} content={'Don’t delete'} />
          <S.DeleteButton onPress={onConfirm} content={'Delete'} />
        </S.Footer>
      </S.ModalContainer>
    </Modal>
  );
};

const S = {
  ModalContainer: styled.View`
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    padding: ${calcHeight(20)}px;
    width: ${calcWidth(440)}px;
  `,
  ModalTitle: styled.Text`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    margin-top: ${calcHeight(16)}px;
    margin-bottom: ${calcHeight(21)}px;
    font-weight: 700;
  `,
  ModalDescription: styled.Text`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    margin-bottom: ${calcHeight(33)}px;
    font-weight: 400;
  `,
  Footer: styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  ButtonContainer: styled.View``,
  CancelButton: styled(Button)`
    width: ${calcWidth(170)}px;
    max-height: ${calcHeight(47)}px;
  `,
  DeleteButton: styled(Button)`
    width: ${calcWidth(130)}px;
    max-height: ${calcHeight(47)}px;
    background-color: ${({ theme }) => theme.colors.deleteButton};
  `,
};

export default DeleteScriptModal;
