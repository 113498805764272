import { RootState } from '../index';

export const scriptSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.scriptsMap[scriptId] || null;

export const scriptsMapSelector = (state: RootState) => state.aiGenerationScripts.scriptsMap;

export const isFullScriptLoadingSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.fullScriptsStatusMap[scriptId]?.isLoading || false;

export const isScriptDeletingSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.deleteScriptStatusMap[scriptId]?.isLoading || false;

export const isSavingScriptLoadingSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.savingScriptsStatusMap[scriptId]?.isLoading || false;

export const isCreateVideoLoadingSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.createVideoStatusMap[scriptId]?.isLoading || false;

export const generationScriptsSelector = (generationId: string) => (state: RootState) =>
  state.aiGenerationScripts.generationScriptsMap[generationId] || [];

export const fullScriptErrorSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.fullScriptsStatusMap[scriptId]?.error || null;

export const createVideoErrorSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.createVideoStatusMap[scriptId]?.error || null;

export const savingScriptErrorSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.savingScriptsStatusMap[scriptId]?.error || null;

export const editScriptSelector = (scriptId: string) => (state: RootState) =>
  state.aiGenerationScripts.editScriptsMap[scriptId] || null;
