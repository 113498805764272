import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from './aiGenerationScripts.types';
import { removeGeneration } from '../aiGeneration/aiGeneration.slice';

export const initialState: IInitialState = {
  generationScriptsMap: {},
  fullScriptsStatusMap: {},
  createVideoStatusMap: {},
  savingScriptsStatusMap: {},
  deleteScriptStatusMap: {},
  scriptsMap: {},
  editScriptsMap: {},
};

const AI_GENERATION_SCRIPTS = 'AI_GENERATION_SCRIPTS';

export const createVideo = createAction<{ scriptId: string }>(`${AI_GENERATION_SCRIPTS}/createVideo`);

export const saveScriptChanges = createAction<{ scriptId: string }>(`${AI_GENERATION_SCRIPTS}/saveScriptChanges`);

export const searchGeneratedScript = createAction<{ scriptId: string }>(
  `${AI_GENERATION_SCRIPTS}/searchGeneratedScript`,
);

export const startScriptPolling = createAction<{ scriptId: string }>(`${AI_GENERATION_SCRIPTS}/startScriptPolling`);

export const assignBiteIdToScript = createAction<{ scriptId: string; biteId: number }>(
  `${AI_GENERATION_SCRIPTS}/assignBiteIdToScript`,
);

const aiGenerationScriptsSlice = createSlice({
  name: AI_GENERATION_SCRIPTS,
  initialState,
  reducers: {
    updateScript: (state, action) => {
      state.scriptsMap[action.payload.scriptId] = action.payload.script;
    },
    setScriptsMap: (state, action) => {
      state.scriptsMap = { ...state.scriptsMap, ...action.payload };
    },
    setGenerationScripts: (state, action) => {
      const scriptsMap = action.payload.scripts.reduce((acc, script) => {
        acc[script.id] = script;
        return acc;
      }, {});

      state.scriptsMap = { ...state.scriptsMap, ...scriptsMap };
      state.generationScriptsMap[action.payload.generationId] = Object.keys(scriptsMap);
    },
    setGenerationScriptsMap: (state, action) => {
      state.generationScriptsMap = { ...state.generationScriptsMap, ...action.payload };
    },
    setGenerationScript: (state, action) => {
      state.scriptsMap[action.payload.id] = action.payload;

      delete state.fullScriptsStatusMap[action.payload.id];
    },
    setFullScriptsStatusError: (state, action) => {
      state.fullScriptsStatusMap[action.payload.id] = {
        isLoading: false,
        error: action.payload.error,
      };
    },
    setCreateVideoStatusIsLoading: (state, action) => {
      if (!action.payload.state) {
        delete state.createVideoStatusMap[action.payload.id];
        return;
      }

      state.createVideoStatusMap[action.payload.id] = {
        isLoading: action.payload.state,
        error: null,
      };
    },
    updateScriptGeneratedText: (state, action) => {
      const editedScript =
        state.editScriptsMap[action.payload.scriptId] ||
        JSON.parse(JSON.stringify(state.scriptsMap[action.payload.scriptId]));

      editedScript.config.itemsMap[action.payload.itemId].generatedMeta.text = action.payload.value;
      state.editScriptsMap[action.payload.scriptId] = editedScript;
    },
    removeEditScript: (state, action) => {
      delete state.editScriptsMap[action.payload.scriptId];
      delete state.savingScriptsStatusMap[action.payload.scriptId];
    },
    setCreateVideoStatusError: (state, action) => {
      state.createVideoStatusMap[action.payload.id] = {
        isLoading: false,
        error: action.payload.error,
      };
    },
    setSavingScriptsStatusIsLoading: (state, action) => {
      if (!action.payload.state) {
        delete state.savingScriptsStatusMap[action.payload.scriptId];
        return;
      }

      state.savingScriptsStatusMap[action.payload.scriptId] = {
        isLoading: action.payload.state,
        error: null,
      };
    },
    setSavingScriptsStatusError: (state, action) => {
      state.savingScriptsStatusMap[action.payload.scriptId] = {
        isLoading: false,
        error: action.payload.error,
      };
    },
    fetchFullScript: (state, action) => {
      state.fullScriptsStatusMap[action.payload.scriptId] = {
        isLoading: true,
        error: null,
      };
    },
    deleteScript: (state, action: PayloadAction<{ scriptId: string }>) => {
      state.deleteScriptStatusMap[action.payload.scriptId] = {
        isLoading: true,
        error: null,
      };
    },
    deleteScriptSuccess: (state, action: PayloadAction<{ scriptId: string }>) => {
      const generationId = state.scriptsMap[action.payload.scriptId].generationId;
      state.generationScriptsMap[generationId] = state.generationScriptsMap[generationId].filter(
        (id) => id !== action.payload.scriptId,
      );

      delete state.scriptsMap[action.payload.scriptId];
      delete state.deleteScriptStatusMap[action.payload.scriptId];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeGeneration, (state, action) => {
      if (!state.generationScriptsMap[action.payload]) {
        return;
      }
      state.generationScriptsMap[action.payload].forEach((scriptId) => {
        delete state.scriptsMap[scriptId];
      });
      delete state.generationScriptsMap[action.payload];
    });
  },
});

export const {
  updateScript,
  setScriptsMap,
  setGenerationScripts,
  setGenerationScriptsMap,
  setGenerationScript,
  setFullScriptsStatusError,
  setSavingScriptsStatusIsLoading,
  fetchFullScript,
  setSavingScriptsStatusError,
  setCreateVideoStatusIsLoading,
  setCreateVideoStatusError,
  updateScriptGeneratedText,
  deleteScript,
  deleteScriptSuccess,
  removeEditScript,
} = aiGenerationScriptsSlice.actions;

export default aiGenerationScriptsSlice.reducer;
