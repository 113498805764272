import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../../themes/defaultTheme';
import CaretDownIcon from '../../../../assets/icons/videoEditor/leftSidebar/caret-down.svg';

type Props = {
  label: string;
  isSelected: boolean;
  renderContent: ({ onClose }: { onClose: () => void }) => React.ReactNode;
  onClick?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
};
const Select = ({ label, isSelected, renderContent, onClick, onOpen, onClose }: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleDropdownClick = useCallback(
    (e) => {
      e.stopPropagation();
      setIsOpened((prev) => !prev);
      if (!isOpened) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    [isOpened, onClose, onOpen],
  );

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick();
        return;
      }
      handleDropdownClick(e);
    },
    [handleDropdownClick, onClick],
  );

  const handleClose = useCallback(() => {
    setIsOpened(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    const handleDocumentClick = () => {
      setIsOpened(false);
      onClose?.();
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [onClose]);

  useEffect(() => {
    if (!isOpened) {
      return;
    }

    const controlRect = ref.current.getBoundingClientRect();

    const contentNode = contentRef.current;
    contentNode.style.top = `${controlRect.bottom}px`;
  }, [isOpened]);

  return (
    <>
      <S.ControlContainer isSelected={isOpened || isSelected} ref={ref}>
        <S.ButtonContainer isSplit={!!onClick} onClick={handleClick}>
          <S.ButtonText>{label}</S.ButtonText>
        </S.ButtonContainer>
        <S.CaretDownContainer isSplit={!!onClick} onClick={handleDropdownClick}>
          <CaretDownIcon />
        </S.CaretDownContainer>
      </S.ControlContainer>
      {isOpened && (
        <S.ContentContainer ref={contentRef}>
          {renderContent({
            onClose: handleClose,
          })}
        </S.ContentContainer>
      )}
    </>
  );
};
export default Select;

const BTN_WIDTH = 80;
const DROPDOWN_TOGGLE_WIDTH = 20;

const S = {
  ControlContainer: styled.div<{
    isSelected: boolean;
  }>`
    display: flex;
    align-items: stretch;
    height: 30px;
    color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.primaryBlue : defaultTheme.colors.gray)};
    font-size: 13px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    line-height: 16px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    border-radius: 8px;
    cursor: pointer;

    flex-shrink: 1; /* Allow buttons to shrink */
    flex-grow: 0; /* Prevent buttons from growing */
    min-width: ${BTN_WIDTH +
    DROPDOWN_TOGGLE_WIDTH}px; /* Allow the width of buttons to shrink below their content size */
    overflow: hidden; /* Prevent overflowing content */

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  ButtonContainer: styled.div<{ isSplit: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ isSplit }) => (isSplit ? '0 4px 0 8px' : '0 0 0 8px')};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    flex-shrink: 1; /* Allow the text to shrink */
    flex-grow: 1; /* Allow the text to grow to fill available space */
    min-width: ${BTN_WIDTH}px; /* Allow the text to shrink completely */

    &:hover {
      background-color: ${({ isSplit }) =>
        isSplit ? defaultTheme.colors.lightGray34 : defaultTheme.colors.transparent};
    }
  `,
  ButtonText: styled.div`
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  CaretDownContainer: styled.div<{ isSplit: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: ${DROPDOWN_TOGGLE_WIDTH}px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    &:hover {
      background-color: ${({ isSplit }) =>
        isSplit ? defaultTheme.colors.lightGray34 : defaultTheme.colors.transparent};
    }
  `,
  ContentContainer: styled.div`
    z-index: 100;
    position: absolute;
    left: 12px;
    right: 5px;
    bottom: 5px;
    border-radius: 10px;
    background: ${defaultTheme.colors.white};
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  `,
};
