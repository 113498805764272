const HomeStack = {
  StackName: 'HomeStack',
  Home: 'Home',
};

const CreateBiteStack = {
  StackName: 'CreateBiteStack',
  CreateBiteInfo: 'CreateBiteInfo',
  BiteTellYourStory: 'BiteTellYourStory',
  AIExpectationFirst: 'AIExpectationFirst',
  AIExpectationSecond: 'AIExpectationSecond',
  AddQuestion: 'AddQuestion',
  SummaryNotes: 'SummaryNotes',
  CreateSummaryNotes: 'CreateSummaryNotes',
  BitesMagic: 'BitesMagic',
  FinalScreen: 'FinalScreen',
  BiteEditQuestion: 'BiteEditQuestion',
  BiteGoogleSlides: 'BiteGoogleSlides',
  ExistingVideos: 'ExistingVideos',
  BiteVideoEditor: 'BiteVideoEditor',
};
const CreatePlaylistStack = {
  StackName: 'CreatePlaylistStack',
  CreatePlaylistInfo: 'CreatePlaylistInfo',
  NameYourPlaylist: 'NameYourPlaylist',
  AddBites: 'AddBites',
  OrganizePlaylist: 'OrganizePlaylist',
  PlaylistCreated: 'PlaylistCreated',
};

const BiteQuestionStack = {
  StackName: 'BiteQuestionStack',
  CreateQuizBiteFinalScreen: 'CreateQuizBiteFinalScreen',
  QuestionsIntro: 'QuestionsIntro',
  QuestionType: 'QuestionType',
  TypeYourQuestion: 'TypeYourQuestion',
  AddAnswers: 'AddAnswers',
  MarkCorrectAnswer: 'MarkCorrectAnswer',
};

const EditBiteStack = {
  StackName: 'EditBiteStack',
  EditEnhancements: 'EditEnhancements',
  EditMain: 'EditMain',
  EditMedia: 'EditMedia',
  EditSummaryNotes: 'EditSummaryNotes',
  BiteEditQuestion: 'BiteEditQuestion',
  AddQuestion: 'AddQuestion',
};

const EditPlaylistStack = {
  StackName: 'EditPlaylistStack',
  NameYourPlaylist: 'NameYourPlaylist',
  PlaylistSettings: 'PlaylistSettings',
  AddBites: 'AddBites',
  OrganizePlaylist: 'OrganizePlaylist',
};

const HomeDrawer = {
  StackName: 'HomeDrawer',
};

const MainStack = {
  StackName: 'MainStack',
  JoinOrganization: 'JoinOrganization',
  AIContent: 'AIContent',
};

const CreateQuizStack = {
  StackName: 'CreateQuizStack',
  NameYourQuiz: 'NameYourQuiz',
  QuizIntro: 'QuizIntro',
  QuizTellYourStory: 'QuizTellYourStory',
  QuizSettings: 'QuizSettings',
  BiteGoogleSlides: 'BiteGoogleSlides',
};

const EditQuizStack = {
  StackName: 'EditQuizStack',
  EditQuiz: 'EditQuiz',
  AddQuestion: 'AddQuestion',
  QuizEditQuestion: 'QuizEditQuestion',
  QuizCreated: 'QuizCreated',
};

const AuthStack = {
  StackName: 'AuthStack',
  Auth: 'Auth',
  Initial: 'Initial',
};

const PreviewStack = {
  StackName: 'PreviewStack',
  PreviewBite: 'PreviewBite',
  PreviewExploreBite: 'PreviewExploreBite',
  PreviewPlaylist: 'PreviewPlaylist',
  Dashboard: 'Dashboard',
};

const AnalyticsStack = {
  StackName: 'AnalyticsStack',
  BiteOverview: 'BiteOverview',
  BitePreview: 'BitePreview',
  Stats: 'Stats',
};

const Routes = {
  AuthStack,
  MainStack,
  HomeDrawer,
  HomeStack,
  CreateBiteStack,
  EditBiteStack,
  BiteQuestionStack,
  CreatePlaylistStack,
  EditPlaylistStack,
  CreateQuizStack,
  EditQuizStack,
  PreviewStack,
  AnalyticsStack,
};

export default Routes;
