import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IVideo } from '../../../../../store/videoEditor/videoEditor.types';
import { biteItemSelector, bitesLoadingStateMapSelector } from '../../../../../store/bite/bite.selectors';
import Skeleton from '../../../../../components/shared/Skeleton';
import { useBiteCoverUrl } from '../../../../../hooks/useBiteCoverUrl';
import { getCreatedDateString } from '../../../../../utils/getCreatedDateString';
import styled from 'styled-components';
import defaultTheme from '../../../../../themes';
import { setBite, setScopeSource } from '../../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';

export const BiteResultItem = memo(
  ({ biteId, video, onClose }: { biteId: number; video: IVideo; onClose: () => void }) => {
    const dispatch = useDispatch();

    const bite = useSelector(biteItemSelector(biteId));
    const { isLoading, error } = useSelector(bitesLoadingStateMapSelector(biteId));

    const coverUrl = useBiteCoverUrl(biteId);

    const handleClick = useCallback(() => {
      if (!bite) {
        return;
      }
      dispatch(
        setBite({
          id: biteId,
          name: bite.subject,
          videoId: video.id,
        }),
      );
      dispatch(setScopeSource('ANOTHER_BITE'));
      onClose();
    }, [bite, biteId, dispatch, onClose, video.id]);

    if (error) {
      return null;
    }

    if (isLoading) {
      <S.ResultContainer>
        <S.ImageContainer>
          <Skeleton width={48} height={66} borderRadius={11} />
        </S.ImageContainer>
        <S.ContentContainer>
          <Skeleton width={200} height={16} />
          <Skeleton width={200} height={16} />
          <Skeleton width={200} height={16} />
          <Skeleton width={200} height={16} />
        </S.ContentContainer>
      </S.ResultContainer>;
    }

    if (!bite) {
      return null;
    }

    const createdDateText = getCreatedDateString(new Date(bite.created_at));

    return (
      <S.ResultContainer onClick={handleClick}>
        <S.ImageContainer>
          <S.Image src={coverUrl} />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.Title>{bite.subject}</S.Title>
          <S.Description>{bite.owner_name}</S.Description>
          <S.Description>{video.assetIds.length} assets</S.Description>
          <S.Description>{createdDateText}</S.Description>
        </S.ContentContainer>
      </S.ResultContainer>
    );
  },
);

export const S = {
  ResultContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 14px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  ImageContainer: styled.div`
    width: 48px;
    height: 66px;
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    border-radius: 11px;
    object-fit: cover;
  `,
  ContentContainer: styled.div``,
  Title: styled.div`
    overflow: hidden;
    color: ${defaultTheme.colors.text};
    text-overflow: ellipsis;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    line-height: 16px;
  `,
  Description: styled.div`
    color: ${defaultTheme.colors.gray19};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 13px;
    line-height: 16px;
  `,
};
