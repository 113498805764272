import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { I18nManager, StyleProp, TouchableWithoutFeedback, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getBuildNumber, getVersion } from 'react-native-device-info';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';
import styled, { css, useTheme } from 'styled-components/native';
import DrawerNavItem, { INavItem } from './DrawerNavItem';
import { CustomText, Divider as SharedDivider } from '../../../../components/shared';
import { navigate, navigationRef } from '../../../RootNavigation';
import {
  userSelector,
  activeOrganizationSelector,
  isContentCreatorSelector,
} from '../../../../store/auth/auth.selectors';
import useToken from '../../../../hooks/useToken';
import { APP_STORE_URL, DASHBOARD_URL, PLAY_STORE_URL } from '../../../../utils/constants/urls';
import { BITES_ORGANIZATION_ID, FEATURE_FLAG_IDS } from '../../../../utils/constants/org';
import { WEB_DRAWER_WIDTH } from '../../../../utils/constants/drawer';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';

import { drawerModalSelector } from '../../../../store/menuState/menuState.selectors';
import { closeDrawerModal, EDrawerModal, setDrawerModal } from '../../../../store/menuState/menuState.slice';
import appQRCode from '../../../../assets/images/app-qr-code.png';
import appStoreImage from '../../../../assets/images/app-store.png';
import playStoreImage from '../../../../assets/images/play-store.png';
import WorkspaceIcon from '../../../../assets/icons/workspace.svg';
import PdfIcon from '../../../../assets/icons/aiGeneration/pdf-icon.svg';

import Routes from '../../../routes';
import { homeTabsActiveTabSelector } from '../../../../store/homeScreen/homeScreen.selectors';

import FeedIcon from '../../../../assets/icons/menu/feed-outline-gradient.svg';
import ExploreIcon from '../../../../assets/icons/menu/explore-outline-gradient.svg';
import DashboardIcon from '../../../../assets/icons/menu/dashboard.svg';
import ContactUsIcon from '../../../../assets/icons/menu/contact-us.svg';
import InviteIcon from '../../../../assets/icons/menu/invite.svg';
import ProfileIcon from '../../../../assets/icons/menu/profile.svg';
import ChevronRightIcon from '../../../../assets/icons/menu/chevron-right.svg';
import ProjectsIcon from '../../../../assets/icons/menu/projects.svg';
import StarsIcon from '../../../../assets/icons/aiGeneration/small-stars.svg';
import Profile from '../../../../screens/appSettings/Profile';
import OrganizationList from '../DrawerContent/Organizations/OrganizationList';

import { setHomeActiveTab } from '../../../../store/homeScreen/homeScreen.slice';

import { Tabs as TabsEnum } from '../../../../store/homeScreen/homeScreen.types';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import Modal from '../../../../components/modals/ModalController';

import { hasBitesSelector } from '../../../../store/bite/bite.selectors';
import PlusIcon from '../../../../assets/icons/plus.svg';
import Tooltip, { ITooltipMethods } from '../../../../components/Tooltip/Tooltip';
import DrawerModal from '../DrawerModal/DrawerModal';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import UserInvitation from '../../../../screens/UserInvitation';
import WebViewModal from '../../../../components/modals/WebViewModal/WebViewModal';
import useKeyboardHeight from '../../../../hooks/useKeyboardHeight';
import BlueAndRedButtonsModal from '../../../../components/modals/BlueAndRedButtonsModal';
import { currentFlowSelector } from '../../../../store/appActivity/appActivity.selectors';
import { EAppFlow, EFeatureFlag } from '../../../../store/appActivity/appActivity.types';
import { draftsSelector } from '../../../../store/drafts/drafts.selectors';
import startBiteCreationFlow from '../../../../utils/bite/startBiteCreationFlow';
import startPlaylistCreationFlow from '../../../../utils/playlist/startPlaylistCreationFlow';
import startQuizCreationFlow from '../../../../utils/quiz/startQuizCreationFlow';
import {
  createBtnDataSet,
  dataSetDownloadApp as cyDataSetDownloadApp,
  dataSetNewPlaylist,
  dataSetWorkspace as cyDataSetWorkspace,
  navigateHomeDataSet,
  newBiteDataSet,
  newProjectDataSet,
  newQuizDataSet,
  profileDataSet,
} from './drawerContent.constants';
import { URL_PRIVACY } from '../../../../utils/constants/auth';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import methods from '../../../../services/intercom';
import { resetEditGeneration } from '../../../../store/editAiGeneration/editAiGeneration.slice';
import { organizationFeaturesSelector } from '../../../../store/org/org.selectors';

interface IProps extends DrawerContentComponentProps {}

interface IPosition {
  x: number;
  y: number;
}

const version = isWeb
  ? `${process.env.BITES_VERSION_NAME} ${process.env.BITES_BUILD_NUMBER}`
  : `${getVersion()} ${getBuildNumber()}`;

const DrawerContent: React.FC<IProps> = (props) => {
  const drawerIsOpen = !!props.state?.history?.some((h) => h.type === 'drawer');
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const createTooltipRef = useRef<ITooltipMethods>();
  const playlistTooltipRef = useRef<ITooltipMethods>({});

  const isContentCreator = useSelector(isContentCreatorSelector);
  const openedDrawerModal = useSelector(drawerModalSelector);
  const activeOrganization = useSelector(activeOrganizationSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const hasDashboardAccess = activeOrganization?.show_dashboard;
  const user = useSelector(userSelector);
  const { draftsMap } = useSelector(draftsSelector);
  const { token } = useToken();
  const homeActiveTab = useSelector(homeTabsActiveTabSelector);
  const hasBites = useSelector(hasBitesSelector);
  const { keyboardHeight } = useKeyboardHeight();
  const [isDisplayPrivacyPolicy, setIsDisplayPrivacyPolicy] = useState(false);
  const [isLeavingModalVisible, setIsLeavingModalVisible] = useState(false);
  const [requestedTab, setRequestedTab] = useState<TabsEnum>(TabsEnum.FEED);
  const aiButtonFeatureFlag = useFeatureFlag(EFeatureFlag.aiButton);
  const organizationFeatures = useSelector(organizationFeaturesSelector);
  const aiStudioFeatureFlag = organizationFeatures.includes(FEATURE_FLAG_IDS.AiStudio);
  const showAiButton = isWeb && aiButtonFeatureFlag;
  const showAiStudioButton = isWeb && aiStudioFeatureFlag;

  const handleCloseDrawerModal = useCallback(() => {
    dispatch(closeDrawerModal());
  }, [dispatch]);

  const handleOpenProfile = useCallback(() => {
    dispatch(setDrawerModal(EDrawerModal.Profile));
  }, [dispatch]);

  const handleOpenOrganizationMenu = useCallback(() => {
    dispatch(setDrawerModal(EDrawerModal.Organization));
  }, [dispatch]);

  const handleOpenInviteCollaborators = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'invite_collaborators',
      }),
    );
    dispatch(setDrawerModal(EDrawerModal.InviteCollaborators));
  }, [dispatch]);

  const handleHelp = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'open_intercom_help',
      }),
    );
    methods.displayHelp();
  }, [dispatch]);

  useEffect(() => {
    if (drawerIsOpen) {
      dispatch(
        trackEvent({
          event: 'opening_side_menu',
        }),
      );
    }
    return () => {
      handleCloseDrawerModal();
    };
  }, [dispatch, drawerIsOpen, handleCloseDrawerModal]);

  const onAppStoreClick = useCallback(() => {
    window.open(APP_STORE_URL);
  }, []);

  const onPlayStoreClick = useCallback(() => {
    window.open(PLAY_STORE_URL);
  }, []);

  const handleCloseLeavingModal = useCallback(() => {
    setIsLeavingModalVisible(false);
  }, []);

  const handleOpenExplore = useCallback(() => {
    dispatch(setHomeActiveTab(TabsEnum.EXPLORE));
    dispatch(
      trackEvent({
        event: 'home_screen_tab',
        props: { tab: 'EXPLORE' },
      }),
    );
    navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [dispatch]);

  const handleOpenFeed = useCallback(() => {
    dispatch(setHomeActiveTab(TabsEnum.FEED));
    dispatch(
      trackEvent({
        event: 'home_screen_tab',
        props: { tab: 'FEED' },
      }),
    );
    navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [dispatch]);

  const handleOpenProjects = useCallback(() => {
    dispatch(setHomeActiveTab(TabsEnum.GENERATIONS));
    dispatch(
      trackEvent({
        event: 'home_screen_tab',
        props: { tab: 'GENERATIONS_FEED' },
      }),
    );
    navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [dispatch]);

  const handleContinueLeavingPress = useCallback(
    (tab) => {
      setIsLeavingModalVisible(false);
      if (tab === TabsEnum.FEED) {
        handleOpenFeed();
        return;
      }

      if (tab === TabsEnum.GENERATIONS) {
        handleOpenProjects();
        return;
      }

      handleOpenExplore();
    },
    [handleOpenExplore, handleOpenFeed, handleOpenProjects],
  );

  const handleContinueLeavingPressRequestedTab = useCallback(() => {
    handleContinueLeavingPress(requestedTab);
  }, [handleContinueLeavingPress, requestedTab]);

  const handleLeaveModal = useCallback(
    (tab: TabsEnum) => {
      setRequestedTab(tab);
      if (!!draftsMap?.[currentFlow?.biteId] && currentFlow?.flow === EAppFlow.BITE_CREATION) {
        setIsLeavingModalVisible(true);
        return;
      }
      handleContinueLeavingPress(tab);
    },
    [currentFlow?.biteId, currentFlow?.flow, draftsMap, handleContinueLeavingPress],
  );

  const handleOpenDashboard = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'redirect_to_dashboard',
        props: { target_url: '/' },
      }),
    );
    if (isWeb) {
      window.open(`${DASHBOARD_URL}/?token=${token}`);
    } else {
      navigationRef.current.navigate(Routes.PreviewStack.StackName, {
        screen: Routes.PreviewStack.Dashboard,
        params: {
          path: '/',
        },
      });
    }
  }, [dispatch, token]);

  const menuLinks: INavItem[] = useMemo(
    () =>
      [
        {
          dataSet: navigateHomeDataSet,
          text: t('homeScreen.myBites'),
          Icon: FeedIcon,
          action: () => handleLeaveModal(TabsEnum.FEED),
          isActive: homeActiveTab === TabsEnum.FEED && openedDrawerModal === null,
        },
        {
          dataSet: { 'intercom-target': 'explore-tab' },
          text: t('homeScreen.explore'),
          Icon: ExploreIcon,
          action: () => handleLeaveModal(TabsEnum.EXPLORE),
          isActive: homeActiveTab === TabsEnum.EXPLORE && openedDrawerModal === null,
        },
        showAiStudioButton && {
          dataSet: { 'intercom-target': 'projects-tab' },
          text: t('drawerNavigator.projects'),
          Icon: ProjectsIcon,
          action: () => handleLeaveModal(TabsEnum.GENERATIONS),
          isActive: homeActiveTab === TabsEnum.GENERATIONS && openedDrawerModal === null,
        },
        {
          dataSet: { 'intercom-target': 'dashboard-tab' },
          isHidden: !hasDashboardAccess,
          text: t('drawerNavigator.dashboard'),
          Icon: DashboardIcon,
          action: handleOpenDashboard,
        },
        isContentCreator &&
          activeOrganization.id !== BITES_ORGANIZATION_ID && {
            text: t('drawerNavigator.inviteCollaborators'),
            Icon: InviteIcon,
            action: handleOpenInviteCollaborators,
            isActive: openedDrawerModal === EDrawerModal.InviteCollaborators,
            dataSet: { 'intercom-target': 'invite-collaborators-tab' },
          },
        !showAiButton
          ? {
              dataSet: { 'intercom-target': 'help-button' },
              text: t('drawerNavigator.help'),
              Icon: ContactUsIcon,
              action: handleHelp,
            }
          : null,
      ].filter((_) => _),
    [
      t,
      homeActiveTab,
      openedDrawerModal,
      showAiStudioButton,
      hasDashboardAccess,
      handleOpenDashboard,
      isContentCreator,
      activeOrganization.id,
      handleOpenInviteCollaborators,
      showAiButton,
      handleHelp,
      handleLeaveModal,
    ],
  );

  const handleProfilePress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'profile',
      }),
    );
    openedDrawerModal === EDrawerModal.Profile ? handleCloseDrawerModal() : handleOpenProfile();
  }, [dispatch, handleCloseDrawerModal, handleOpenProfile, openedDrawerModal]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createButtonPosition, setCreateButtonPosition] = useState<IPosition>({ x: 0, y: 0 });

  const handleCreateClick = useCallback(
    (e) => {
      const btn = e.target.closest('[data-create-btn]');
      if (!isContentCreator) {
        createTooltipRef.current?.show();
        return;
      }
      const pos = btn.getBoundingClientRect();
      setCreateButtonPosition({
        x: pos.x,
        y: pos.y,
      });
      setIsCreateModalOpen(true);
      dispatch(
        trackEvent({
          event: 'create_click',
        }),
      );
    },
    [isContentCreator, dispatch],
  );
  const handleCloseCreateModal = useCallback(() => {
    setIsCreateModalOpen(false);
  }, []);

  const handleCreateBite = useCallback(() => {
    startBiteCreationFlow();
    handleCloseCreateModal();
  }, [handleCloseCreateModal]);

  const handleCreatePlaylist = useCallback(() => {
    if (!hasBites) {
      playlistTooltipRef.current?.show?.();
      return;
    }

    startPlaylistCreationFlow();
    handleCloseCreateModal();
  }, [hasBites, handleCloseCreateModal]);

  const handleCreateQuiz = useCallback(() => {
    startQuizCreationFlow();
    handleCloseCreateModal();
  }, [handleCloseCreateModal]);

  const handleCreateGeneration = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'create_generation',
      }),
    );
    dispatch(resetEditGeneration());
    navigate(Routes.MainStack.AIContent);
    handleCloseCreateModal();
  }, [dispatch, handleCloseCreateModal]);

  const renderOrganizationList = useCallback((onClosePanel) => <OrganizationList onClosePanel={onClosePanel} />, []);
  const renderProfile = useCallback((onClosePanel, isVisible) => <Profile isVisible={isVisible} />, []);
  const renderInvitation = useCallback((onClosePanel) => <UserInvitation onClosePanel={onClosePanel} />, []);

  const showCreateDescription = useMemo(() => {
    if (!isWeb || !isCreateModalOpen) {
      return false;
    }

    const currentRouteName = navigationRef.current.getCurrentRoute()?.name;

    const descriptionRoutes = [
      ...Object.values(Routes.CreateBiteStack).filter((route) => route !== Routes.CreateBiteStack.CreateBiteInfo),
      ...Object.values(Routes.CreatePlaylistStack).filter(
        (route) => route !== Routes.CreatePlaylistStack.CreatePlaylistInfo,
      ),
      ...Object.values(Routes.CreateQuizStack),
    ];

    return descriptionRoutes.includes(currentRouteName);
  }, [isCreateModalOpen]);

  const handleOpenPrivacyPolicy = useCallback(() => {
    if (isWeb) {
      window.open(URL_PRIVACY, '_blank');
      return;
    }

    setIsDisplayPrivacyPolicy(true);
  }, []);

  const handleClosePrivacyPolicy = useCallback(() => {
    setIsDisplayPrivacyPolicy(false);
  }, []);

  const renderContent = () => {
    return (
      <>
        {isWeb && (
          <S.CompanyWrapper>
            <S.CompanyLogo
              source={{
                uri: activeOrganization.brand_icon,
              }}
            />
          </S.CompanyWrapper>
        )}

        <S.Content>
          <S.NavbarContainer>
            <S.MainNavbar>
              {menuLinks.map((itemProps, idx) => (
                <DrawerNavItem key={idx} {...itemProps} />
              ))}
            </S.MainNavbar>
            {isWeb && (
              <Tooltip
                methodsRef={createTooltipRef}
                text={t('homeScreen.notCreator')}
                fontSize={theme.fontSizes.s11}
                withGradient
              >
                <S.CreateCTAButtonContainer>
                  <S.CreateCTAButton
                    isDisabled={!isContentCreator}
                    onPress={handleCreateClick}
                    // @ts-ignore
                    dataSet={createBtnDataSet}
                  >
                    <S.ButtonText>{t('drawerNavigator.create')}</S.ButtonText>
                  </S.CreateCTAButton>
                </S.CreateCTAButtonContainer>
              </Tooltip>
            )}
            {showAiButton && <AiButton />}

            <S.Flex />
            <S.Divider />
            <S.BottomLinkContainer onPress={handleOpenOrganizationMenu} dataSet={cyDataSetWorkspace}>
              <S.BottomLinkIconContainer>
                <S.BottomLinkImageContainer>
                  {activeOrganization?.brand_share ? (
                    <S.BottomLinkImage
                      source={{
                        uri: activeOrganization?.brand_share,
                      }}
                    />
                  ) : (
                    <WorkspaceIcon style={S.WorkspaceIconStyles} />
                  )}
                </S.BottomLinkImageContainer>
              </S.BottomLinkIconContainer>
              <S.BottomLinkLabelsContainer>
                <S.BottomLinkLabelsTop>{t('drawerNavigator.workspace')}</S.BottomLinkLabelsTop>
                <S.BottomLinkLabelsBottom>{activeOrganization.name}</S.BottomLinkLabelsBottom>
              </S.BottomLinkLabelsContainer>
              <S.BottomLinkChevron>
                <ChevronRightIcon height={calcHeight(15)} width={calcWidth(15)} />
              </S.BottomLinkChevron>
            </S.BottomLinkContainer>
            <S.Divider />
            <S.BottomLinkContainer
              onPress={handleProfilePress}
              // @ts-ignore
              dataSet={profileDataSet}
            >
              <S.BottomLinkIconContainer>
                <ProfileIcon height={calcHeight(26)} width={calcWidth(26)} />
              </S.BottomLinkIconContainer>
              <S.BottomLinkLabelsContainer>
                <S.BottomLinkLabelsTop>{`${user?.first_name} ${user?.last_name}`}</S.BottomLinkLabelsTop>
                <S.BottomLinkLabelsBottom>{user?.email}</S.BottomLinkLabelsBottom>
              </S.BottomLinkLabelsContainer>
              <S.BottomLinkChevron>
                <ChevronRightIcon height={calcHeight(15)} width={calcWidth(15)} />
              </S.BottomLinkChevron>
            </S.BottomLinkContainer>
            <S.Divider />
            {isWeb && (
              <>
                <S.AppQRContainer dataSet={cyDataSetDownloadApp}>
                  <S.AppQRLabelsContainer>
                    <S.AppQRLabelTop>{t('drawerNavigator.store.line1')}</S.AppQRLabelTop>
                    <S.AppQRLabelBottom>{t('drawerNavigator.store.line2')}</S.AppQRLabelBottom>
                  </S.AppQRLabelsContainer>
                  <S.AppQRImage source={appQRCode} />
                </S.AppQRContainer>
                <S.AppLinksContainer>
                  <TouchableWithoutFeedback onPress={onAppStoreClick}>
                    <S.AppStoreImage source={appStoreImage} />
                  </TouchableWithoutFeedback>
                  <TouchableWithoutFeedback onPress={onPlayStoreClick}>
                    <S.PlayStoreImage source={playStoreImage} />
                  </TouchableWithoutFeedback>
                </S.AppLinksContainer>

                <S.Divider />
              </>
            )}
            <S.BottomContainer>
              <S.VersionText>{t('drawerNavigator.versionNumber', { version })}</S.VersionText>
              <S.PrivacyPolicyButton onPress={handleOpenPrivacyPolicy}>
                <S.PrivacyPolicyText>{t('drawerNavigator.privacyPolicy')}</S.PrivacyPolicyText>
              </S.PrivacyPolicyButton>
            </S.BottomContainer>
          </S.NavbarContainer>

          <S.OrgranizationListContainer>
            <OrganizationList onClosePanel={handleCloseDrawerModal} />
          </S.OrgranizationListContainer>
        </S.Content>
        <BlueAndRedButtonsModal
          isVisible={isLeavingModalVisible}
          title={t('leaveConfirmationModal.title')}
          onLeftButtonClick={handleCloseLeavingModal}
          onRightButtonClick={handleContinueLeavingPressRequestedTab}
          rightButtonLabel={t('common.Continue')}
          leftButtonLabel={t('common.discard')}
        />
        <Modal
          isVisible={isCreateModalOpen}
          onBackdropPress={handleCloseCreateModal}
          backdropOpacity={0.4}
          hideModalContentWhileAnimating
          isFullScreen
          onModalHide={handleCloseCreateModal}
          animationIn={'fadeIn'}
          animationOut={'fadeOut'}
        >
          <S.CreatePanel pos={createButtonPosition}>
            <ShadowedContainer>
              <S.CreateButton
                onPress={handleCreateBite}
                // @ts-ignore
                dataSet={newBiteDataSet}
              >
                <PlusIcon width={calcWidth(14)} height={calcWidth(14)} fill={theme.colors.primaryBlue} />
                <S.ButtonText withMarginLeft color={theme.colors.primaryBlue}>
                  {t('homeScreen.newBite')}
                </S.ButtonText>
              </S.CreateButton>
            </ShadowedContainer>
            <S.Spacing size={16} />
            <Tooltip methodsRef={playlistTooltipRef} text={t('homeScreen.noBitesForPlaylist')} withGradient>
              <ShadowedContainer>
                <S.CreateButton
                  onPress={handleCreatePlaylist}
                  isGrayed={!hasBites}
                  //@ts-ignore
                  dataSet={dataSetNewPlaylist}
                >
                  <PlusIcon
                    width={calcWidth(14)}
                    height={calcWidth(14)}
                    fill={!hasBites ? theme.colors.gray19 : theme.colors.lightblue6}
                  />
                  <S.ButtonText withMarginLeft color={!hasBites ? theme.colors.gray19 : theme.colors.lightblue6}>
                    {t('homeScreen.newPlaylist')}
                  </S.ButtonText>
                </S.CreateButton>
              </ShadowedContainer>
            </Tooltip>
            <S.Spacing size={16} />
            <ShadowedContainer>
              <S.CreateButton
                onPress={handleCreateQuiz}
                //@ts-ignore
                dataSet={newQuizDataSet}
              >
                <PlusIcon width={calcWidth(14)} height={calcWidth(14)} fill={theme.colors.purpleChoose} />
                <S.ButtonText withMarginLeft color={theme.colors.purpleChoose}>
                  {t('homeScreen.newQuiz')}
                </S.ButtonText>
              </S.CreateButton>
            </ShadowedContainer>
            {showAiStudioButton && (
              <>
                <S.Spacing size={16} />
                <ShadowedContainer>
                  <S.CreateButton
                    onPress={handleCreateGeneration}
                    //@ts-ignore
                    dataSet={newProjectDataSet}
                  >
                    <PdfIcon width={calcWidth(24)} height={calcWidth(24)} />
                    <S.ButtonText withMarginLeft color={theme.colors.primaryBlue}>
                      {t('homeScreen.newProject')}
                    </S.ButtonText>
                    <StarsIcon style={S.StarsIconStyles} />
                  </S.CreateButton>
                </ShadowedContainer>
              </>
            )}
            {showCreateDescription && (
              <S.CreatePanelDescription>{t('drawerNavigator.createModalDescription')}</S.CreatePanelDescription>
            )}
          </S.CreatePanel>
        </Modal>
        <DrawerModal
          title={t('appSettingsStack.profileScreen.title')}
          isVisible={openedDrawerModal === EDrawerModal.Profile}
          onClose={handleCloseDrawerModal}
          renderContent={renderProfile}
        />
        <DrawerModal
          title={t('appSettingsStack.workspaceModal.title')}
          isVisible={openedDrawerModal === EDrawerModal.Organization}
          onClose={handleCloseDrawerModal}
          renderContent={renderOrganizationList}
        />
        <DrawerModal
          isVisible={openedDrawerModal === EDrawerModal.InviteCollaborators}
          onClose={handleCloseDrawerModal}
          renderContent={renderInvitation}
        />

        <WebViewModal
          url={URL_PRIVACY}
          isVisible={isDisplayPrivacyPolicy}
          onClose={handleClosePrivacyPolicy}
          withCloseIcon
        />
      </>
    );
  };

  return isWeb ? (
    <S.DrawerContainer>{renderContent()}</S.DrawerContainer>
  ) : (
    <S.Container isOverflowVisible={!!keyboardHeight} insets={insets}>
      {renderContent()}
    </S.Container>
  );
};

const dataSetAiButton = { 'intercom-target': 'ai-video-button' };

const AiButton = () => {
  const { t } = useTranslation();

  return (
    <S.AiButtonContainer>
      <S.AiButton
        // @ts-ignore
        dataSet={dataSetAiButton}
      >
        <S.AiButtonText>{t('drawerNavigator.aiButton')}</S.AiButtonText>
        <S.AlphaBlank>Alpha</S.AlphaBlank>
      </S.AiButton>
    </S.AiButtonContainer>
  );
};

const S = {
  AlphaBlank: styled.Text`
    background: ${({ theme }) => theme.colors.darkGray11};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    padding: ${calcHeight(2)}px ${calcWidth(4)}px;
    margin-left: ${calcWidth(4)}px;
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  AiButtonContainer: styled.View`
    padding-right: 24px;
  `,
  AiButton: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.lightPrimaryBlue};
    border-radius: 32px;
    padding: ${calcHeight(6)}px ${calcWidth(12)}px;
    margin: auto;
  `,
  AiButtonText: styled.Text`
    text-align: center;
    color: ${({ theme }) => theme.colors.lightPrimaryBlue};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
  `,
  DrawerContainer: styled.View`
    height: 100%;
    width: ${WEB_DRAWER_WIDTH}px;
    border: 0px solid ${({ theme }) => theme.colors.lightGray1};
    border-right-width: 1px;
  `,

  Container: styled.View<{ isOverflowVisible: boolean; insets: EdgeInsets }>`
    flex: 1;
    padding-top: ${({ insets }) => insets.top}px;
    padding-bottom: ${({ insets }) => insets.bottom}px;
    position: relative;
    overflow: ${({ isOverflowVisible }) => (isOverflowVisible ? 'visible' : 'hidden')};
  `,

  CompanyLogo: styled.Image`
    margin-top: ${calcHeight(16)}px;
    margin-bottom: ${calcHeight(16)}px;
    width: ${calcHeight(122)}px;
    height: ${calcHeight(32)}px;
    resize-mode: contain;
  `,

  Divider: styled(SharedDivider)(
    ({ theme }) => css`
      margin: 0 ${calcHeight(24)}px 0 ${calcHeight(0)}px;
      background-color: ${theme.colors.lightGray1};
    `,
  ),

  Content: styled.View`
    flex: 1;
    flex-direction: row;
    overflow: hidden;
  `,

  NavbarContainer: styled.View`
    padding-left: ${calcWidth(17)}px;
    width: 100%;
  `,

  OrgranizationListContainer: styled.View`
    padding-left: ${calcWidth(17)}px;
    width: 100%;
    overflow: hidden;
  `,

  MainNavbar: styled.View`
    padding-top: ${calcHeight(4)}px;
    padding-bottom: ${calcHeight(18)}px;
  `,
  CompanyWrapper: styled.View`
    margin-bottom: ${calcWidth(0)}px;
  `,
  FooterNavbar: styled.View`
    padding-top: ${calcHeight(28)}px;
    ${() => (isWeb ? '' : `padding-bottom: ${calcHeight(24)}px;`)}
  `,

  AppQRContainer: styled.View`
    flex-direction: row;
    align-items: stretch;
    padding-top: ${calcHeight(16)}px;
    padding-right: ${calcWidth(10)}px;
    ${() => (isWeb ? 'cursor: default;' : '')}
  `,

  AppQRLabelTop: styled.Text`
    margin-left: ${calcWidth(4)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
  `,

  AppQRLabelBottom: styled.Text`
    margin-top: ${calcWidth(4)}px;
    margin-left: ${calcWidth(4)}px;
    height: ${calcHeight(15)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    color: ${({ theme }) => theme.colors.gray19};
  `,

  AppQRLabelsContainer: styled.View`
    flex: 1;
  `,

  AppLinksContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: ${calcHeight(8)}px;
    padding-left: ${calcWidth(3)}px;
    padding-right: ${calcWidth(5)}px;
    padding-bottom: ${calcHeight(16)}px;
  `,
  AppQRImage: styled.Image`
    align-self: center;
    margin-right: ${calcWidth(4)}px;
    width: ${calcWidth(60)}px;
    height: ${calcHeight(60)}px;
    resize-mode: contain;
  `,
  AppStoreImage: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(105)}px;
    height: ${calcHeight(31)}px;
    align-self: center;
    ${({}) => (isWeb ? 'cursor: pointer;' : '')}
  `,
  PlayStoreImage: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(120)}px;
    height: ${calcHeight(31)}px;
    align-self: center;
    ${({}) => (isWeb ? 'cursor: pointer;' : '')}
  `,

  BottomContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${calcWidth(8)}px ${calcWidth(0)}px ${calcWidth(8)}px ${calcWidth(2)}px;
  `,
  VersionText: styled(CustomText)(
    ({ theme }) => css`
      font-size: ${theme.fontSizes.s11}px;
      line-height: ${theme.fontSizes.s11}px;
      color: ${theme.colors.lightGray5};
      font-family: ${theme.fontFamilies.Arimo};
      text-align: left;
    `,
  ),
  PrivacyPolicyButton: styled.TouchableOpacity`
    margin-right: ${calcWidth(15)}px;
  `,
  PrivacyPolicyText: styled(CustomText)(
    ({ theme }) => css`
      font-size: ${theme.fontSizes.s11}px;
      line-height: ${theme.fontSizes.s11}px;
      color: ${theme.colors.lightGray5};
      font-family: ${theme.fontFamilies.Arimo};
      text-align: right;
    `,
  ),

  BottomLinkContainer: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    padding: ${calcWidth(16)}px ${calcWidth(8)}px ${calcWidth(16)}px ${calcWidth(0)}px;
  `,
  BottomLinkIconContainer: styled.View`
    width: ${calcWidth(40)}px;
  `,
  BottomLinkLabelsContainer: styled.View`
    flex: 1;
  `,
  BottomLinkLabelsTop: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme }) => theme.colors.text};
    text-align: left;
  `,
  BottomLinkLabelsBottom: styled.Text`
    margin-top: ${calcWidth(2)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    color: ${({ theme }) => theme.colors.gray19};
    text-align: left;
  `,
  BottomLinkImageContainer: styled.View`
    width: ${calcWidth(32)}px;
    height: ${calcHeight(32)}px;
  `,
  BottomLinkImage: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(32)}px;
    height: ${calcHeight(32)}px;
  `,
  BottomLinkChevron: styled.View`
    margin-right: ${calcWidth(8)}px;
    width: ${calcWidth(20)}px;
    transform: ${I18nManager.isRTL ? 'rotate(180deg)' : ''};
  `,

  Flex: styled.View`
    flex: 1;
  `,

  CreateCTAButtonContainer: styled.View<{ disabled?: boolean }>`
    padding-right: ${calcHeight(24)}px;
  `,
  CreateCTAButton: styled.TouchableOpacity<{ isDisabled?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: ${calcHeight(16)}px;
    width: 100%;
    height: ${calcHeight(45)}px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
  `,
  ButtonText: styled.Text<{ withMarginLeft?: boolean; color?: string }>`
    ${({ withMarginLeft }) => (withMarginLeft ? `margin-left: ${calcWidth(16)}px` : '')};
    color: ${({ color, theme }) => color || theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    text-transform: capitalize;
  `,

  CreatePanel: styled.View<{ pos: IPosition }>`
    position: absolute;
    top: ${({ pos }) => (pos.y - calcHeight(100) >= 0 ? pos.y - calcHeight(100) : 0)}px;
    left: ${({ pos }) => (pos.x >= 0 ? pos.x : 0)}px;
    padding: ${calcHeight(16)}px;
    width: ${calcWidth(300)}px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  CreatePanelDescription: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    width: ${calcWidth(240)}px;
    left: ${calcWidth(330)}px;
    font-size: ${({ theme }) => theme.fontSizes.s16};
    line-height: ${({ theme }) => theme.fontSizes.s16 * 2};
    text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 4px;
  `,
  Spacing: styled.View<{ size: number }>`
    height: ${({ size }) => calcHeight(size)}px;
  `,
  CreateButton: styled.TouchableOpacity<{ disabled?: boolean; isGrayed?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: ${calcHeight(50)}px;
    padding: ${calcWidth(16)}px 0;
    border-radius: ${calcWidth(50)}px;
    background-color: ${({ theme, disabled, isGrayed }) =>
      disabled || isGrayed ? theme.colors.lightGray1 : theme.colors.white};
  `,
  TooltipText: styled.Text`
    color: ${({ theme }) => theme.colors.white};
  `,
  TooltipStyles: {
    marginTop: 7,
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
    // backgroundColor: `${defaultTheme.colors.primaryBlue}`,
  } as StyleProp<ViewStyle>,
  TooltipArrowStyles: {
    marginTop: -7,
  } as StyleProp<ViewStyle>,
  TooltipBackgroundStyles: {
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
  WorkspaceIconStyles: {
    resizeMode: 'contain',
    width: calcWidth(36),
    height: calcWidth(36),
    marginLeft: calcWidth(-4),
  } as StyleProp<ViewStyle>,
  StarsIconStyles: { marginBottom: 10 } as StyleProp<ViewStyle>,
};

export default DrawerContent;
