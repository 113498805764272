import { RootState } from '../index';

export const generationByIdSelector = (generationId: string) => (state: RootState) =>
  state.aiGeneration.generationsMap[generationId] || null;

export const isGenerationLoadingSelector = (generationId: string) => (state: RootState) =>
  state.aiGeneration.createGenerationStatusMap[generationId]?.isLoading || false;

export const generationErrorSelector = (generationId: string) => (state: RootState) =>
  state.aiGeneration.createGenerationStatusMap[generationId]?.error || null;

export const editGenerationErrorSelector = (state: RootState) =>
  state.aiGeneration.createGenerationStatusMap[state.editAiGeneration.editGenerationId]?.error || null;

export const generationsFeedSelector = (state: RootState) => state.aiGeneration.generationsFeed;

export const generationsFeedDataSelector = (state: RootState) => state.aiGeneration.generationsFeed.data;
