export interface IInitialState {
  generationsMap: {
    [key: string]: IGeneration;
  };
  generationsFeed: {
    data: { generation: IGeneration }[];
    page: number;
    total: number;
    isLoading: boolean;
    error: any;
  };
  createGenerationStatusMap: {
    [key: string]: {
      isLoading: boolean;
      error: any;
    };
  };
}

export enum EGenerationStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  DONE = 'DONE',
  OUTDATED = 'OUTDATED',
  DELETED = 'DELETED',
}

export interface IGeneration {
  id: string;
  name: string;
  description: string;
  inputCloudAssetIds: string[];
  topics: string[];
  withIntro: boolean;
  withOuttro: boolean;
  withBgMusic: boolean;
  voiceId: string;
  status: EGenerationStatus;
  regeneratedFromId: string;
  orgId: number;
  creatorId: number;
  createDate: Date;
  updateDate: Date;
}

export type LoadGenerations = {
  generationIds: string[];
  processId?: string;
};

export type GetProjectsFeed = {
  reset?: boolean;
  search?: string;
  processId?: string;
};
export type SearchGenerations = {
  filters?: {
    search?: string;
    statuses?: EGenerationStatus[];
  };
  additionalFields?: ('activeShortVideoScripts' | 'assetsNum' | 'creator')[];
  page: number;
  pageSize?: number;
  onDone?: (results: any) => void;
  onError?: (error: any) => void;
  withThrow?: boolean;
  processId?: string;
};
