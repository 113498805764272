export interface IInitialState {
  // scripts slice
  generationScriptsMap: {
    [generationId: string]: string[]; // script ids list
  };
  fullScriptsStatusMap: {
    [key: string]: {
      isLoading: boolean;
      error: any;
    };
  };
  createVideoStatusMap: {
    [key: string]: {
      isLoading: boolean;
      error: any;
    };
  };
  savingScriptsStatusMap: {
    [key: string]: {
      isLoading: boolean;
      error: any;
    };
  };
  deleteScriptStatusMap: {
    [key: string]: {
      isLoading: boolean;
      error: any;
    };
  };
  scriptsMap: {
    [key: string]: IScript;
  };
  editScriptsMap: {
    [key: string]: IScript;
  };
}

export enum EVideoScriptStatus {
  INITIAL = 'INITIAL',
  SCRIPT_IN_REVIEW = 'SCRIPT_IN_REVIEW', // the script config is generated
  GENERATING_VIDEO = 'GENERATING_VIDEO',
  GENERATING_VIDEO_FAILED = 'GENERATING_VIDEO_FAILED', // in progress of generating video config
  VIDEO_IN_REVIEW = 'VIDEO_IN_REVIEW', // video config is generated
  BITE_GENERATED = 'BITE_GENERATED', // bite object was created
  OUTDATED = 'OUTDATED', // if a new script object was generated
  DELETED = 'DELETED', // soft deletion
}

export interface IVideoScriptConfig {
  resolution: {
    width: number;
    height: number;
  };
  duration: number;
  itemsMap: {
    [key: string]: {
      id: string;
      start: number;
      end: number;
      type: string;
      width: number;
      height: number;
      volume: number;
      generatedMeta: {
        generatedFrom: string;
        provider: string;
        keywords: string[];
        text: string;
      };
    };
  };
  timelineLayers: { id: string; timeline: string[] }[];
}

export interface IScript {
  id: string;
  order: number; // user can update it later with drag and drop
  generationId: string;
  name: string; // script name, name for the future bite
  description: string;
  promptVersion: string; // prompt logic used to generate the initialConfig
  initialConfig: IVideoScriptConfig | null;
  config: IVideoScriptConfig | null;
  status: EVideoScriptStatus;
  videoId: string | null; // null until video is generated
  biteId: number | null; // null until bite is generated
  orgId: number;
  creatorId: number;
  createDate: Date;
  updateDate: Date;
}
