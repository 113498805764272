import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EVideoScriptStatus, IScript } from '../../../store/aiGenerationScripts/aiGenerationScripts.types';
import Modal from '../../../components/modals/ModalController';
import { calcHeight, calcWidth, deviceHeight } from '../../../utils/dimensions';
import styled, { useTheme } from 'styled-components/native';
import Button from '../../../components/shared/buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  createVideo,
  fetchFullScript,
  removeEditScript,
  saveScriptChanges,
} from '../../../store/aiGenerationScripts/aiGenerationScripts.slice';
import FormContainer from './FormContainer';
import CreationAnimation from '../../../components/shared/CreationAnimation/CreationAnimation.web';
import {
  createVideoErrorSelector,
  fullScriptErrorSelector,
  isCreateVideoLoadingSelector,
  isFullScriptLoadingSelector,
  isSavingScriptLoadingSelector,
  savingScriptErrorSelector,
  scriptSelector,
} from '../../../store/aiGenerationScripts/aiGenerationScripts.selector';
import Preloader from '../../../components/shared/Preloader';
import ScriptGeneratedTextItem from './ScriptGeneratedTextItem/ScriptGeneratedTextItem';
import Intercom from '../../../services/intercom/intercom';
import { configSelector } from '../../../store/appActivity/appActivity.selectors';

interface IProps {
  isVisible: boolean;
  scriptId: IScript['id'];
  onClose: () => void;
  onContinue: (script: IScript) => void;
}

enum EModalType {
  PREPARING_VIDEO = 'preparingVideo',
  MOVIE_IS_READY = 'movieIsReady',
  SCRIPT_CONFIG = 'scriptConfig',
}

const ScriptModal: React.FC<IProps> = ({ isVisible, scriptId, onClose, onContinue }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const config = useSelector(configSelector);
  const script = useSelector(scriptSelector(scriptId));
  const isLoading = useSelector(isCreateVideoLoadingSelector(scriptId));
  const isFullScriptLoading = useSelector(isFullScriptLoadingSelector(scriptId));
  const isSavingScriptLoading = useSelector(isSavingScriptLoadingSelector(scriptId));

  const savingScriptError = useSelector(savingScriptErrorSelector(scriptId));
  const fullScriptError = useSelector(fullScriptErrorSelector(scriptId));
  const createVideoError = useSelector(createVideoErrorSelector(scriptId));

  const [isShowBorderLine, setIsShowBorderLine] = useState(false);

  useEffect(() => {
    if (script?.config || !isVisible) {
      return;
    }

    dispatch(fetchFullScript({ scriptId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isVisible]);

  const orderedScriptItems = useMemo(() => {
    if (!script?.config?.itemsMap) {
      return [];
    }

    return Object.values(script.config.itemsMap)
      .filter((item) => item.generatedMeta?.generatedFrom === 'text-to-speech' && !!item.generatedMeta.text)
      .sort((a, b) => a.start - b.start);
  }, [script?.config?.itemsMap]);

  const handleRetryOnError = useCallback(() => {
    if (savingScriptError) {
      dispatch(saveScriptChanges({ scriptId }));
    }

    if (fullScriptError) {
      dispatch(fetchFullScript({ scriptId }));
    }
  }, [dispatch, fullScriptError, savingScriptError, scriptId]);

  const handleCreateVideo = useCallback(() => {
    dispatch(createVideo({ scriptId }));
  }, [dispatch, scriptId]);

  const handleContinue = useCallback(() => {
    onContinue(script);
  }, [onContinue, script]);

  const handleResetChanges = useCallback(() => {
    dispatch(removeEditScript({ scriptId }));
  }, [dispatch, scriptId]);

  const renderErrorText = useCallback(() => {
    if (savingScriptError) {
      return 'Failed to save';
    }

    if (fullScriptError) {
      return 'Failed to load';
    }

    if (createVideoError) {
      return 'Failed to create video';
    }

    return null;
  }, [createVideoError, fullScriptError, savingScriptError]);

  const handleContentSizeChange = useCallback((_, height) => {
    setIsShowBorderLine(height > deviceHeight - 300);
  }, []);

  const handleArticleClick = useCallback(() => {
    Intercom.displayArticle(config.intercom.aiStudioArticleId);
  }, [config]);

  const currentModal = useMemo(() => {
    if (isLoading) {
      return EModalType.PREPARING_VIDEO;
    }

    if (script?.status === EVideoScriptStatus.VIDEO_IN_REVIEW) {
      return EModalType.MOVIE_IS_READY;
    }

    return EModalType.SCRIPT_CONFIG;
  }, [isLoading, script?.status]);

  const renderModal = useCallback(() => {
    switch (currentModal) {
      case EModalType.PREPARING_VIDEO:
        return (
          <>
            <S.ScriptName>{script?.name}</S.ScriptName>
            <S.WavesContainer>
              <CreationAnimation width={216} />
            </S.WavesContainer>
            <S.ModalTitle>Preparing Your Video</S.ModalTitle>
            <S.ModalDescription textAlign='center'>
              Please wait while we process the approved script and generate a video. This may take a few moments.
            </S.ModalDescription>
            <S.ModalSubtitle>What Happens Next?</S.ModalSubtitle>
            <S.ModalDescription>
              Once your video is ready, you will be redirected to the video editor. You’ll be able to review the video,
              make any necessary edits, and to proceed to the next stages of the Bite creation.
            </S.ModalDescription>
            <S.ModalSubtitle>Want To Keep Going? No Need To Wait.</S.ModalSubtitle>
            <S.ModalDescription>
              You don't have to wait here. Click “Choose Another Bite” to continue working on other scripts.
            </S.ModalDescription>
            <S.ButtonContainer>
              <S.CreateButton
                width={200}
                onPress={onClose}
                fontFamily={theme.fontFamilies.Arimo}
                content={'Choose another bite'}
              />
            </S.ButtonContainer>
            <S.ArticleLinkContainer>
              <S.ArticleLink onClick={handleArticleClick}>Read More About the Video Editor</S.ArticleLink>
            </S.ArticleLinkContainer>
          </>
        );
      case EModalType.MOVIE_IS_READY:
        return (
          <>
            <S.ScriptName>{script?.name}</S.ScriptName>
            <S.ModalTitle>Your Video is Ready - Let’s Continue!</S.ModalTitle>
            <S.ModalDescription textAlign='center'>
              After clicking “Continue to Bite”, you will be taken to the video editor to review and make any edits to
              the video.
            </S.ModalDescription>
            <S.ButtonContainer marginTop={24}>
              <S.CreateButton
                width={200}
                onPress={handleContinue}
                fontFamily={theme.fontFamilies.Arimo}
                content={'Continue to Bite'}
              />
            </S.ButtonContainer>
            <S.ArticleLinkContainer>
              <S.ArticleLink onClick={handleArticleClick}>Read More About the Video Editor</S.ArticleLink>
            </S.ArticleLinkContainer>
          </>
        );
      default:
        return (
          <>
            <S.ScriptTitle>{script?.name}</S.ScriptTitle>
            <S.ModalSubtitle>Video Script</S.ModalSubtitle>
            <S.ScriptDescription>{script?.description}</S.ScriptDescription>
            <S.ScriptConfigItemsList onContentSizeChange={handleContentSizeChange}>
              {isFullScriptLoading ? (
                <Preloader />
              ) : (
                orderedScriptItems.map((item, index) => (
                  <ScriptGeneratedTextItem key={index} scriptId={scriptId} item={item} />
                ))
              )}
              {fullScriptError && (
                <S.ScriptErrorContainer>
                  <S.ErrorText>{'Failed to load script'}</S.ErrorText>{' '}
                  <S.RetryButton>
                    <S.RetryButtonText onPress={handleRetryOnError}>Retry</S.RetryButtonText>
                  </S.RetryButton>
                </S.ScriptErrorContainer>
              )}
            </S.ScriptConfigItemsList>
            <S.Footer isShowBorderLine={isShowBorderLine}>
              {savingScriptError || createVideoError ? (
                <S.ErrorContainer>
                  <S.ErrorText>{renderErrorText()}</S.ErrorText>
                  {savingScriptError && (
                    <>
                      <S.RetryButton>
                        <S.RetryButtonText onPress={handleRetryOnError}>Retry</S.RetryButtonText>
                      </S.RetryButton>
                      <S.ErrorTextSeparator> | </S.ErrorTextSeparator>
                      <S.RetryButton>
                        <S.RetryButtonText onPress={handleResetChanges}>Reset</S.RetryButtonText>
                      </S.RetryButton>
                    </>
                  )}
                </S.ErrorContainer>
              ) : (
                <S.EmptyView />
              )}
              <S.ButtonContainer>
                <S.CreateButton
                  disabled={isFullScriptLoading || isSavingScriptLoading || savingScriptError || fullScriptError}
                  width={160}
                  onPress={handleCreateVideo}
                  content={'Create Video'}
                />
              </S.ButtonContainer>
            </S.Footer>
          </>
        );
    }
  }, [
    currentModal,
    script?.name,
    script?.description,
    onClose,
    theme.fontFamilies.Arimo,
    handleArticleClick,
    handleContinue,
    handleContentSizeChange,
    isFullScriptLoading,
    orderedScriptItems,
    fullScriptError,
    handleRetryOnError,
    isShowBorderLine,
    savingScriptError,
    createVideoError,
    renderErrorText,
    handleResetChanges,
    isSavingScriptLoading,
    handleCreateVideo,
    scriptId,
  ]);

  return (
    <Modal isVisible={isVisible} onBackdropPress={onClose}>
      <S.ModalContainer>
        <FormContainer width={currentModal === EModalType.SCRIPT_CONFIG ? calcWidth(700) : calcWidth(537)}>
          <S.Content>{renderModal()}</S.Content>
        </FormContainer>
      </S.ModalContainer>
    </Modal>
  );
};

const S = {
  ModalContainer: styled.View`
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  `,
  Content: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 0;
  `,
  ScriptTitle: styled.Text`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray19};
    line-height: 15px; /* 100% */
    padding: 0 60px;
  `,
  ScriptDescription: styled.Text`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    padding: 0 60px;
    margin-bottom: 24px;
  `,
  ModalTitle: styled.Text`
    width: 100%;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    margin: 10px 0;
    padding: 0 60px;
  `,
  ModalSubtitle: styled.Text`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    margin: 10px 0;
    padding: 0 60px;
  `,
  ModalDescription: styled.Text<{ textAlign?: string }>`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray19};
    padding: 0 60px;
    margin-bottom: 36px;
    text-align: ${({ textAlign }) => textAlign || 'left'};
  `,
  ScriptConfigItemsList: styled.ScrollView`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 60px;
    min-height: 100px;
    max-height: ${deviceHeight - 300}px;
  `,
  Footer: styled.View<{ isShowBorderLine: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 60px 0;
    border-top-width: 1px;
    border-top-color: ${({ theme, isShowBorderLine }) =>
      isShowBorderLine ? theme.colors.lightGray45 : theme.colors.white};
  `,
  ButtonContainer: styled.View<{ marginTop?: number }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  `,
  CreateButton: styled(Button)<{ isWhite?: boolean; width: number; disabled: boolean }>`
    width: ${({ width }) => calcWidth(width)}px;
    max-height: ${calcHeight(41)}px;
    min-height: ${calcHeight(41)}px;
    border-radius: 30px;
    background-color: ${({ theme, isWhite, disabled }) =>
      isWhite ? theme.colors.white : disabled ? theme.colors.darkGray3 : theme.colors.primaryBlue};
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.colors.darkGray3 : theme.colors.primaryBlue)};
    color: ${({ theme, isWhite }) => (isWhite ? theme.colors.primaryBlue : theme.colors.white)};
  `,
  ImproveScriptButton: styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  ImproveScriptText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-weight: 700;
    margin-right: 8px;
  `,
  WavesContainer: styled.View`
    width: 216px;
    height: 76px;
    margin-bottom: 37px;
  `,
  ScriptName: styled.Text`
    width: 100%;
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray19};
    line-height: 15px; /* 100% */
    padding: 0 60px;
    margin-bottom: 37px;
  `,
  ErrorContainer: styled.View`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
  ScriptErrorContainer: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  `,
  ErrorText: styled.Text`
    color: ${({ theme }) => theme.colors.pinkError};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin-right: 10px;
  `,
  ErrorTextSeparator: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
  RetryButton: styled.TouchableOpacity`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
  RetryButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
  EmptyView: styled.View`
    flex: 1;
  `,
  ArticleLinkContainer: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  `,
  ArticleLink: styled.Text`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-weight: 400;
    line-height: 150%;
  `,
};

export default ScriptModal;
