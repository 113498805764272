export const profileDataSet = { 'intercom-target': 'profile', cy: 'drawer-content-profile-btn' };
export const createBtnDataSet = {
  'create-btn': true,
  'intercom-target': 'create-btn',
  cy: 'drawer-content-create-btn',
};
export const newBiteDataSet = { 'intercom-target': 'new-bite', cy: 'drawer-content-create-bite-btn' };
export const newQuizDataSet = { 'intercom-target': 'new-quiz', cy: 'drawer-content-create-quiz-btn' };
export const newProjectDataSet = { 'intercom-target': 'new-project', cy: 'drawer-content-create-project-btn' };
export const dataSetNewPlaylist = { 'intercom-target': 'new-playlist', cy: 'drawer-content-create-playlist-btn' };

export const navigateHomeDataSet = { 'intercom-target': 'my-bites-tab', cy: 'my-bites-tab-button' };

export const dataSetWorkspace = { 'intercom-target': 'workspace-tab', cy: 'workspace-change' };
export const dataSetDownloadApp = { 'intercom-target': 'download-app', cy: 'download-app' };

export const workspaceItemDataSet = (orgId) => ({
  cy: `workspace-item-${orgId}`,
});
