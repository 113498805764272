import React, { CSSProperties } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import webStyled from 'styled-components';
import styled from 'styled-components/native';

interface IProps {
  viewStyle?: StyleProp<ViewStyle>;
  containerViewStyle?: CSSProperties;
  distance?: number;
  shadowColor?: string;
  offset?: [number, number];
  children: React.ReactNode;
}

const DEFAULT_OFFSET: [number, number] = [0, 7];

const ShadowedContainer: React.FC<IProps> = ({
  viewStyle,
  containerViewStyle,
  children,
  distance = 24,
  offset = DEFAULT_OFFSET,
  shadowColor,
}) => {
  return (
    <S.Shadow offset={offset} shadowColor={shadowColor} distance={distance} style={containerViewStyle}>
      <S.Container style={viewStyle}>{children}</S.Container>
    </S.Shadow>
  );
};

const S = {
  Shadow: webStyled.div<{ distance: number; shadowColor?: string; offset: [number, number] }>`
		border-radius: 29px;
		box-shadow: ${({ distance, shadowColor, offset }) =>
      `${offset[0]}px ${offset[1]}px ${distance}px ${shadowColor ? shadowColor : 'rgba(0, 0, 0, 0.10)'}`};
	`,
  Container: styled.View`
    flex: 1;
  `,
};

export default ShadowedContainer;
