import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components/native';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { EPanel, Tabs as TabsEnum } from '../../../store/homeScreen/homeScreen.types';
import { IStackNavigation } from '../../../navigation/types';

import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { homeTabsActiveTabSelector } from '../../../store/homeScreen/homeScreen.selectors';
import BottomPanel from './components/BottomPanel';
import ArrowDownIcon from '../../../assets/icons/feed/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import ExploreBitesFeed from './components/ExploreBitesFeed';
import Feed from './components/Feed';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import SearchPanel from '../../../components/shared/SearchPanel';
import ShadowedContainer from '../../../components/ShadowedContainer';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import { exploreBitesSelector } from '../../../store/explore/explore.selectors';
import {
  isLoadingSelector as feedIsLoadingSelector,
  searchStrSelector as feedSearchStrSelector,
} from '../../../store/feed/feed.selectors';
import { loadNextPage, setSearchStr } from '../../../store/feed/feed.slice';
import Panels from './components/Panels';
import { togglePanel } from '../../../store/homeScreen/homeScreen.slice';
import FeedControls from './components/FeedControls';
import ExploreBiteTutorialVideo from '../../../components/ExploreBiteTutorialVideo';
import i18next from 'i18next';
// import MediaProcessingBanner from '../../../components/shared/MediaProcessingBanner/MediaProcessingBanner';
import useRestoreVideoModal from '../../../hooks/useRestoreVideoModal';
import { getCreators } from '../../../store/org/org.slice';
import { StackNavigationProp } from '@react-navigation/stack';
import { searchButtonDataSet } from './components/component.constants';
import ProjectsFeed from './components/ProjectsFeed';

interface IProps extends IStackNavigation {}

const Home: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const theme = useTheme();

  const restoreVideoModal = useRestoreVideoModal();

  const homeActiveTab = useSelector(homeTabsActiveTabSelector);
  const activeOrganization = useSelector(activeOrganizationSelector);
  const { isFetching: isExploreLoading } = useSelector(exploreBitesSelector);
  const isFeedLoading = useSelector(feedIsLoadingSelector);
  const feedSearchStr = useSelector(feedSearchStrSelector);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [exploreSearchStr, setExploreSearchStr] = useState('');
  const [isExploreTutorialVideoVisible, setIsExploreTutorialVideoVisible] = useState(false);

  const handleCloseExploreTutorialVideo = useCallback(() => {
    setIsExploreTutorialVideoVisible(false);
  }, []);

  const handleToggleOrganizationPanel = useCallback(() => {
    dispatch(togglePanel(EPanel.ORGANIZATIONS));
  }, [dispatch]);

  const isSearching = useMemo(() => {
    return homeActiveTab === TabsEnum.EXPLORE
      ? isExploreLoading
      : homeActiveTab === TabsEnum.FEED
      ? isFeedLoading
      : false;
  }, [isExploreLoading, isFeedLoading, homeActiveTab]);

  const searchInputValue = useMemo(() => {
    return homeActiveTab === TabsEnum.EXPLORE
      ? exploreSearchStr
      : homeActiveTab === TabsEnum.FEED
      ? feedSearchStr
      : null;
  }, [exploreSearchStr, feedSearchStr, homeActiveTab]);

  const handleChangeSearchStr = useCallback(
    (newSearchStr) => {
      if (newSearchStr.length === 1) {
        dispatch(
          trackEvent({
            event: 'search_typing',
            props: { search_category: homeActiveTab === TabsEnum.FEED ? 'feed' : 'explore' },
          }),
        );
      }
      if (searchInputValue === newSearchStr) {
        return;
      }
      setExploreSearchStr(newSearchStr);
      if (homeActiveTab === TabsEnum.FEED) {
        dispatch(setSearchStr(newSearchStr));
        dispatch(loadNextPage({ reset: true, debounce: true }));
      }
    },
    [dispatch, searchInputValue, homeActiveTab],
  );

  const handleOpenSearchPanel = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'search_open',
        props: { search_category: homeActiveTab === TabsEnum.FEED ? 'feed' : 'explore' },
      }),
    );
    setIsSearchOpen(true);
  }, [dispatch, homeActiveTab]);

  const handleCloseSearchPanel = useCallback(() => {
    if (!isSearchOpen) {
      return;
    }

    dispatch(
      trackEvent({
        event: 'search_close',
        props: { search_category: homeActiveTab === TabsEnum.FEED ? 'feed' : 'explore' },
      }),
    );

    handleChangeSearchStr('');
    setIsSearchOpen(false);
  }, [dispatch, handleChangeSearchStr, isSearchOpen, homeActiveTab]);

  const headerTitle = useCallback(() => {
    switch (homeActiveTab) {
      case TabsEnum.EXPLORE:
        return (
          <S.HeaderWrapper>
            <S.SecondaryTitleConainer>
              <S.SecondaryTitle>{t('homeScreen.exploreHeaderMobile')}</S.SecondaryTitle>
            </S.SecondaryTitleConainer>
          </S.HeaderWrapper>
        );
      case TabsEnum.GENERATIONS:
        return (
          <S.HeaderWrapper>
            <S.SecondaryTitleConainer>
              <S.SecondaryTitle>{t('homeScreen.projects')}</S.SecondaryTitle>
            </S.SecondaryTitleConainer>
          </S.HeaderWrapper>
        );
      default:
        return (
          <S.HeaderLabel disabled={isWeb} onPress={handleToggleOrganizationPanel}>
            <S.HeaderLabelText>{activeOrganization.name}</S.HeaderLabelText>
            {!isWeb ? <ArrowDownIcon width={calcWidth(25)} height={calcHeight(24)} /> : null}
          </S.HeaderLabel>
        );
    }
  }, [activeOrganization.name, handleToggleOrganizationPanel, homeActiveTab, t]);

  const renderHeaderTitle = useCallback(() => (isSearchOpen ? null : headerTitle()), [isSearchOpen, headerTitle]);

  const renderHeaderRight = useCallback(() => {
    return (
      <>
        {isSearchOpen ? (
          <SearchPanel
            isSearching={isSearching}
            value={searchInputValue}
            onClosePanel={handleCloseSearchPanel}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
            onChangeText={handleChangeSearchStr}
            expandedPanelWidth={isWeb ? calcWidth(263) : calcWidth(240)}
            autoFocus
          />
        ) : (
          <ShadowedContainer>
            <S.SearchIconWrapper
              // @ts-ignore
              dataSet={searchButtonDataSet}
              onPress={handleOpenSearchPanel}
            >
              <SearchIcon color={theme.colors.primaryBlue} />
            </S.SearchIconWrapper>
          </ShadowedContainer>
        )}
      </>
    );
  }, [
    isSearchOpen,
    isSearching,
    searchInputValue,
    handleCloseSearchPanel,
    handleChangeSearchStr,
    handleOpenSearchPanel,
    theme.colors.primaryBlue,
  ]);

  useEffect(() => {
    handleCloseSearchPanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeActiveTab]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    navigation.setOptions({
      headerTitle: renderHeaderTitle,
      headerRight: homeActiveTab !== TabsEnum.GENERATIONS ? renderHeaderRight : undefined,
    });
  }, [isSearchOpen, navigation, renderHeaderRight, renderHeaderTitle, isFocused, homeActiveTab]);

  useEffect(() => {
    if (!activeOrganization?.id) {
      return;
    }
    dispatch(getCreators());
  }, [activeOrganization?.id, dispatch]);

  return (
    <S.Container isExploreTab={homeActiveTab === TabsEnum.EXPLORE}>
      <S.MainContent>
        {homeActiveTab === TabsEnum.FEED && <FeedControls />}
        <S.ListContainer isExploreTab={homeActiveTab === TabsEnum.EXPLORE}>
          {homeActiveTab === TabsEnum.FEED && (
            <>
              <Feed />
            </>
          )}
          {homeActiveTab === TabsEnum.EXPLORE && <ExploreBitesFeed searchStr={exploreSearchStr} />}
          {homeActiveTab === TabsEnum.GENERATIONS && <ProjectsFeed />}
          {/*<MediaProcessingBanner containerStyle={S.MediaProcessingBannerContainerStyle} withFetchBite />*/}
        </S.ListContainer>
      </S.MainContent>
      <ExploreBiteTutorialVideo isVisible={isExploreTutorialVideoVisible} onClose={handleCloseExploreTutorialVideo} />
      <Panels />
      <BottomPanel />
      {restoreVideoModal}
    </S.Container>
  );
};

const S = {
  Container: styled.View<{ isExploreTab: boolean }>`
    position: relative;
    flex: 1;
    margin-top: ${({ isExploreTab }) => calcHeight(isWeb || isExploreTab ? 0 : 16)}px;
    ${({ theme }) => css`
      background-color: ${theme.colors.white};
    `}
  `,
  SearchIconWrapper: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
  HeaderLabel: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
  `,
  HeaderLabelText: styled.Text`
    font-size: ${calcFontSize(14)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  MainContent: styled.View`
    flex: 1;
  `,
  ListContainer: styled.View<{ isExploreTab: boolean }>`
    flex: 1;
    margin-top: ${({ isExploreTab }) => (isExploreTab ? 0 : calcHeight(16))}px;
  `,
  HeaderWrapper: styled.View`
    ${isWeb ? `margin-top: ${calcHeight(20)}px;` : ''}
  `,
  SecondaryTitleConainer: styled.View`
    flex-direction: ${() => (isWeb && i18next.dir(i18next.language) === 'rtl' ? 'row-reverse' : 'row')};
    top: ${calcHeight(isWeb ? 0 : 5)}px;
    width: ${calcWidth(250)}px;
    align-items: center;
    justify-content: center;
  `,
  SecondaryTitle: styled.Text`
    font-size: ${({ theme }) => (isWeb ? theme.fontSizes.s17 : theme.fontSizes.s15)};
    color: ${({ theme }) => theme.colors.black};
    line-height: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    ${isWeb ? 'font-weight: bold;' : ''};
    text-align: center;
  `,
  TemplateButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
    text-decoration: ${({ theme }) => theme.colors.primaryBlue} underline;
    line-height: ${({ theme }) => theme.fontSizes.s14};
  `,
  MediaProcessingBannerContainerStyle: isWeb ? { top: calcHeight(20) } : { bottom: calcHeight(90) },
};

export default Home;
