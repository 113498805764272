import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import withRetry from '../../../utils/withRetry';
import Search from './Search.web';
import { useDispatch, useSelector } from 'react-redux';
import { searchCloudAssets } from '../../../store/api/bites-api/calls/cloudAssets.calls';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import VideoModal from '../../../components/shared/VideoModal';
import { createCloudAssetCache, setCloudAsset, setCloudAssets } from '../../../store/cloudAssets/cloudAssets.slice';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineItemWithCloudAssetDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import { v4 as uuid } from 'uuid';
import { getItemCanvasSize } from '../utils/getItemCanvasSize';
import { videoResolutionSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { videoEditorPortal } from '../videoEditor.constants';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import AudioModal from '../../../components/shared/AudioModal';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import { cloudFileTypeSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { logError } from '../../../store/appActivity/appActivity.slice';
import CloudSearchItem from './CloudSearchItem';
import { IInitialState } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.types';
import defaultTheme from '../../../themes/defaultTheme';
import { useIsMounted } from '../../../hooks/useIsMounted';

type Props = {
  cloudResults: IInitialState['cloudResults'];
  renderFilterControls?: () => React.ReactElement;
  additionalRequestFilters?: {
    favoriteByUserId?: number;
    isBrandAsset?: boolean;
    usedInVideoId?: string;
    generationId?: string;
  };
  setCloudResults: (cloudResults: Partial<IInitialState['cloudResults']>) => void;
  isFlaggedToReset?: boolean;
};
const Cloud = ({
  cloudResults,
  renderFilterControls,
  additionalRequestFilters,
  setCloudResults,
  isFlaggedToReset,
}: Props) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();

  const org = useSelector(activeOrganizationSelector);
  const resolution = useSelector(videoResolutionSeletor);
  const fileType = useSelector(cloudFileTypeSelector);

  const requestIdRef = useRef(0);

  useEffect(() => {
    console.log('\n\nCloud mount');

    return () => {
      console.log('Cloud unmount');
    };
  }, []);

  const handleSearch = useCallback(
    async ({
      query,
      withReset,
      withDelayedReset,
    }: {
      query: string;
      withReset?: boolean;
      withDelayedReset?: boolean;
    }) => {
      const requestId = ++requestIdRef.current;

      try {
        dispatch(
          setCloudResults({
            data: withReset ? null : cloudResults.data,
            page: withReset ? 0 : cloudResults.page,
            totalPages: withReset ? null : cloudResults.totalPages,
            isLoading: true,
            error: null,
          }),
        );

        const { data: result } = await withRetry(
          () =>
            searchCloudAssets({
              filters: {
                orgId: org.id,
                search: query?.trim() || undefined,
                fileType: fileType || undefined,
                ...(additionalRequestFilters || {}),
              },
              page: withReset || withDelayedReset ? 0 : cloudResults.page + 1,
            }),
          {
            errorContext: {
              data: {
                action: 'Cloud.handleSearch',
              },
            },
          },
        );

        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        const cloudAssets = result.results.map(({ cloudAsset }) => cloudAsset);
        dispatch(setCloudAssets({ cloudAssets }));

        const data = result.results.map(({ cloudAsset }) => cloudAsset.id);
        dispatch(
          setCloudResults({
            data: withReset || withDelayedReset ? data : [...(cloudResults.data || []), ...data],
            page: result.page,
            totalPages: result.totalPages,
            isLoading: false,
          }),
        );
      } catch (error) {
        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          logError({
            event: 'Cloud.handleSearch: error',
            data: {
              error,
            },
          }),
        );

        dispatch(
          setCloudResults({
            data: withDelayedReset ? [] : cloudResults.data,
            page: withDelayedReset ? 0 : cloudResults.page,
            totalPages: withDelayedReset ? null : cloudResults.totalPages,
            isLoading: false,
            error,
          }),
        );
      }
    },
    [
      additionalRequestFilters,
      cloudResults.data,
      cloudResults.page,
      cloudResults.totalPages,
      dispatch,
      fileType,
      isMountedRef,
      org.id,
      setCloudResults,
    ],
  );

  const getItemKey = useCallback((id: ICloudAsset['id']) => id, []);

  const [selectedItem, setSelectedItem] = useState<ICloudAsset>(null);

  const handleVideoUnselect = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const applyItem = useCallback(
    (cloudAsset: ICloudAsset) => {
      dispatch(setCloudAsset({ cloudAsset }));

      const timelineItemId = uuid();
      const timelineItem: Omit<ITimelineItem, 'start' | 'end'> = {
        id: timelineItemId,
        type: cloudAsset.fileType as ITimelineItem['type'],
        cloudAssetId: cloudAsset.id,
        x: 0,
        y: 0,
      };

      dispatch(
        addToTimeline({
          timelineItem,
          duration:
            cloudAsset.fileType === 'video' || cloudAsset.fileType === 'audio' ? cloudAsset.fileMeta.duration : null,
        }),
      );
      dispatch(
        updateTimelineItemLayer({
          timelineItemId,
        }),
      );
      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      const retryFunctionId = uuid();
      const processingLabel = `Cloud ${cloudAsset.fileType}`;

      const applyItemFunction = async () => {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            isProcessing: true,
            isDone: false,
            retryFunctionId,
          }),
        );

        dispatch(
          createCloudAssetCache({
            cloudAsset,
            timelineItems: [timelineItem],
            onCacheReady: () => {
              dispatch(
                removeUndoRedoById({
                  id: undoId,
                }),
              );

              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId,
                  label: processingLabel,
                  isProcessing: false,
                  isDone: true,
                }),
              );
              setTimeout(() => {
                dispatch(
                  deleteProcessingTimelineItemState({
                    timelineItemId,
                  }),
                );
              }, 5000);

              delete retryFunctions[retryFunctionId];

              const isOnTimeline = getIsItemOnTimeline(timelineItemId);

              if (!isOnTimeline) {
                return;
              }

              if (cloudAsset.fileType !== 'audio') {
                const canvasSize = getItemCanvasSize({
                  width: cloudAsset.fileMeta.width,
                  height: cloudAsset.fileMeta.height,
                  resolution,
                  scaleUp: cloudAsset.fileType !== 'gif',
                });

                timelineItem.width = canvasSize.width;
                timelineItem.height = canvasSize.height;
              }

              dispatch(updateTimelineItem(timelineItem));

              if (cloudAsset.fileType === 'video' || cloudAsset.fileType === 'audio') {
                dispatch(
                  updateTimelineItemWithCloudAssetDuration({
                    timelineItemId,
                  }),
                );
              }

              dispatch(updateCanvas({}));
              dispatch(
                addUndo({
                  id: undoId,
                }),
              );
              dispatch(saveVideo({}));
            },
            onFail: (error: any) => {
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId,
                  label: processingLabel,
                  isProcessing: false,
                  isDone: false,
                  error: {
                    details: error,
                  },
                  retryFunctionId,
                }),
              );
            },
          }),
        );
      };

      retryFunctions[retryFunctionId] = applyItemFunction;
      applyItemFunction();
    },
    [dispatch, resolution],
  );

  const handleUseItem = useCallback(
    async (item: ICloudAsset) => {
      if (item.fileType === 'video' || item.fileType === 'audio') {
        setSelectedItem(item);
        return;
      }

      applyItem(item);
    },
    [applyItem],
  );

  const handleUseVideo = useCallback(async () => {
    setSelectedItem(null);
    applyItem(selectedItem);
  }, [applyItem, selectedItem]);

  const renderItem = useCallback(
    ({ item }: { item: ICloudAsset['id'] }) => {
      return <CloudSearchItem cloudAssetId={item} onSelect={handleUseItem} />;
    },
    [handleUseItem],
  );

  return (
    <>
      {/* <S.SearchContainer> */}
      <Search
        results={cloudResults.data}
        isLoading={cloudResults.isLoading}
        isError={cloudResults.error}
        hasMore={cloudResults.totalPages && cloudResults.totalPages > cloudResults.page + 1}
        withSearchInput
        layout='list'
        onSearch={handleSearch}
        renderFilterControls={renderFilterControls}
        renderItem={renderItem}
        getItemKey={getItemKey}
        isFlaggedToReset={isFlaggedToReset}
        key={fileType}
      />
      {/* </S.SearchContainer> */}
      {selectedItem?.fileType === 'video' &&
        createPortal(
          <S.PreviewContainer>
            <VideoModal
              isInline
              isVisible
              videoUrl={selectedItem.storage.url}
              btnRightLabel={'Use video'}
              btnRightStyle={'primary'}
              onBtnRightClick={handleUseVideo}
              btnLeftLabel={'Close'}
              btnLeftStyle={'secondary'}
              onBtnLeftClick={handleVideoUnselect}
            />
          </S.PreviewContainer>,
          videoEditorPortal.current,
        )}
      {selectedItem?.fileType === 'audio' &&
        createPortal(
          <S.PreviewContainer>
            <AudioModal
              isInline
              isVisible
              url={selectedItem.storage.url}
              btnRightLabel={'Use audio'}
              btnRightStyle={'primary'}
              onBtnRightClick={handleUseVideo}
              btnLeftLabel={'Close'}
              btnLeftStyle={'secondary'}
              onBtnLeftClick={handleVideoUnselect}
            />
          </S.PreviewContainer>,
          videoEditorPortal.current,
        )}
    </>
  );
};
export default memo(Cloud);

const S = {
  // SearchContainer: styled.div`
  //   margin: 0 12px;
  // `,
  PreviewContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${defaultTheme.colors.transparentBlack2};
  `,
};
