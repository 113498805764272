import React, { useCallback, useEffect } from 'react';
import SplashScreen from 'react-native-lottie-splash-screen';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from './navigation';
import { initAuthRequest } from './store/auth/auth.actions';
import { authSelector } from './store/auth/auth.selectors';
import useI18n from './hooks/useI18n';
import { Platform } from 'react-native';
import { getIsMobileWeb, isWeb } from './utils/dimensions';
import { IS_PROD } from './utils/constants/env';
import useVersionCheck from './hooks/useVersionCheck';
import UpgradeToContinueModal from './components/modals/UpgradeToContinueModal';
import useOnAppStateChange, { EAppState } from './hooks/useOnAppStateChange';
import SsoAuthLoader from './screens/auth/common/SsoAuthLoader';
import useSWMessages from './hooks/useSWMessages';
import Intercom from './services/intercom';
import './services/recaptcha';
import ConnectionLostModal from './components/modals/ConnectionLostModal';
import useDeepLinking from './hooks/useDeepLinking';
import { loadConfig, setAppState, setIsConnected, log, initCaptcha } from './store/appActivity/appActivity.slice';
import { configSelector, translationsAreInitializedSelector } from './store/appActivity/appActivity.selectors';
import Toast from 'react-native-toast-message';
import TOAST_CONFIG from './utils/constants/toastConfig';
import Smartlook from 'smartlook-react-native-wrapper';
import { initDataDogRUM } from './services/datadog';
import { useNetInfo } from '@react-native-community/netinfo';
import SlowConnectionModal from './components/modals/SlowConnectionModal';
import { GoogleOAuthProvider } from '@react-oauth/google';
import oAuthClientKeys from './utils/constants/oauthConfig';
import { isAuthDataMissingSelector } from './store/authForm/authForm.selectors';
import useUserIPInfo from './hooks/useUserIPInfo';
import { sessionId } from './store/appActivity/appActivity.constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import objectSupport from 'dayjs/plugin/objectSupport';
import 'react-native-get-random-values';
import RedirectToStores from './screens/auth/RedirectToStores';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(objectSupport);

const initialLocationSearch = isWeb && window.location?.search;
const isMobileWeb = getIsMobileWeb();

const AppInit: React.FC = () => {
  const dispatch = useDispatch();
  const { isConnected } = useNetInfo();

  const translationsAreInitialized = useSelector(translationsAreInitializedSelector);

  const { handleCheckVersion, isAppOutdated, isInProgress, appStoreUrl } = useVersionCheck();

  const handleAppStateChange = useCallback(
    (nextAppState: EAppState) => {
      if (isWeb) {
        return;
      }

      switch (nextAppState) {
        case EAppState.active:
          dispatch(setAppState(EAppState.active));
          dispatch(
            log({
              event: 'app_state_foreground',
            }),
          );
          handleCheckVersion();
          break;
        case EAppState.inactive:
          dispatch(setAppState(EAppState.inactive));
          dispatch(
            log({
              event: 'app_state_inactive',
            }),
          );
          handleCheckVersion();
          break;
        case EAppState.background:
          dispatch(setAppState(EAppState.background));
          dispatch(
            log({
              event: 'app_state_background',
            }),
          );
          break;
        case EAppState.launch:
          dispatch(setAppState(EAppState.launch));
          dispatch(
            log({
              event: 'app_state_launch',
            }),
          );
          handleCheckVersion();
          break;
        default:
          break;
      }
    },
    [dispatch, handleCheckVersion],
  );

  useOnAppStateChange(handleAppStateChange);

  useSWMessages();
  useDeepLinking();
  useUserIPInfo();

  useEffect(() => {
    dispatch(loadConfig());
    if (!isWeb) {
      dispatch(initCaptcha());
    }
    handleCheckVersion(true);
    dispatch(initAuthRequest(initialLocationSearch));
    Intercom.boot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setIsConnected(isConnected));
  }, [dispatch, isConnected]);

  useEffect(() => {
    Intercom.updateUser({
      customAttributes: {
        sessionId,
      },
    });
  }, []);

  if (!translationsAreInitialized || isInProgress) {
    return <SsoAuthLoader />;
  }

  if (isWeb) {
    if (isMobileWeb) {
      return <RedirectToStores />;
    }

    return (
      <GoogleOAuthProvider clientId={oAuthClientKeys.googleClientId}>
        <App isAppOutdated={isAppOutdated} appStoreUrl={appStoreUrl} />
      </GoogleOAuthProvider>
    );
  }

  return <App isAppOutdated={isAppOutdated} appStoreUrl={appStoreUrl} />;
};

interface IAppProps {
  isAppOutdated: boolean;
  appStoreUrl: string;
}

const App: React.FC<IAppProps> = ({ isAppOutdated, appStoreUrl }) => {
  const { isAuthenticated, isFinishedInitAuth } = useSelector(authSelector);
  const config = useSelector(configSelector);

  const { initPreferedLanguage, initPreferedLangFinished } = useI18n();
  const isAuthDataMissing = useSelector(isAuthDataMissingSelector);

  useEffect(() => {
    initPreferedLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFinishedInitAuth && initPreferedLangFinished) {
      if (!isAuthenticated) {
        if (!isWeb) {
          setTimeout(() => SplashScreen.hide(), 0);
        }
        Intercom.registerUnidentifiedUser();
        // Intercom.showChat();
        // Intercom.trackEvent('logged_out');
        Intercom.updateUser({
          name: `Guest (${Platform.OS})`,
        });
      } else {
        // Intercom.hideChat();
        // Intercom.trackEvent('logged_in');
      }

      if (!isWeb && IS_PROD) {
        Smartlook.setupAndStartRecording?.('d1bd26af6955012040985d379bd31fe89dba06c0');
      }
    }
  }, [isFinishedInitAuth, isAuthenticated, initPreferedLangFinished]);

  useEffect(() => {
    initDataDogRUM();
  }, []);

  if (!initPreferedLangFinished || !config) {
    return <SsoAuthLoader />;
  }

  if (isAppOutdated) {
    return (
      <>
        <SsoAuthLoader />
        <UpgradeToContinueModal storeUrl={appStoreUrl} />
      </>
    );
  }

  return (
    <>
      <ConnectionLostModal />
      <SlowConnectionModal />
      <Navigation isSignedIn={isAuthenticated && !isAuthDataMissing} />
      <Toast config={TOAST_CONFIG} />
    </>
  );
};

export default AppInit;
