import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import StoryIcon from '../../../assets/icons/biteIntro/story-icon.svg';
import LoaderWavesAnimation from '../../../components/shared/LoaderWavesAnimation';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import Button from '../../../components/shared/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { EVideoScriptStatus, IScript } from '../../../store/aiGenerationScripts/aiGenerationScripts.types';
import {
  createVideoErrorSelector,
  isCreateVideoLoadingSelector,
  isScriptDeletingSelector,
  scriptSelector,
} from '../../../store/aiGenerationScripts/aiGenerationScripts.selector';
import { startScriptPolling } from '../../../store/aiGenerationScripts/aiGenerationScripts.slice';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import BiteCornerImage from '../../../assets/images/corner-bite.png';
import { TouchableOpacity } from 'react-native';

interface IProps {
  scriptId: string;
  onCreateVideoPress: (script: IScript) => void;
  onCreateBitePress: (script: IScript) => void;
  onDeletePress: (script: IScript) => void;
}

const STATUS_TEXT_MAP = {
  [EVideoScriptStatus.GENERATING_VIDEO]: 'In progress',
  [EVideoScriptStatus.VIDEO_IN_REVIEW]: 'Video ready',
};

const ScriptItem: React.FC<IProps> = ({ scriptId, onCreateVideoPress, onCreateBitePress, onDeletePress }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const script = useSelector(scriptSelector(scriptId));
  const createVideoError = useSelector(createVideoErrorSelector(scriptId));
  const isLoading = useSelector(isCreateVideoLoadingSelector(scriptId));
  const activeOrganization = useSelector(activeOrganizationSelector);
  const isDeleting = useSelector(isScriptDeletingSelector(scriptId));

  const isFirstSetIsTextExpandedRef = useRef(false);
  const [isTextExpanded, setIsTextExpanded] = useState(true);

  const orgLogoSource = useMemo(() => ({ uri: activeOrganization?.brand_icon }), [activeOrganization?.brand_icon]);

  const status = isLoading ? EVideoScriptStatus.GENERATING_VIDEO : script?.status;

  useEffect(() => {
    if (status === EVideoScriptStatus.GENERATING_VIDEO) {
      dispatch(startScriptPolling({ scriptId }));
    }
  }, [dispatch, scriptId, status]);

  const handleCreateVideo = useCallback(() => {
    onCreateVideoPress(script);
  }, [onCreateVideoPress, script]);

  const handleCreateBite = useCallback(() => {
    onCreateBitePress(script);
  }, [onCreateBitePress, script]);

  const handleDeletePress = useCallback(() => {
    onDeletePress(script);
  }, [onDeletePress, script]);

  const handleMorePress = useCallback(() => {
    setIsTextExpanded(true);
  }, []);

  const handleLayout = useCallback((event) => {
    if (isFirstSetIsTextExpandedRef.current) {
      return;
    }
    setIsTextExpanded(event.nativeEvent.layout.height <= 182);
    isFirstSetIsTextExpandedRef.current = event.nativeEvent.layout.height > 182;
  }, []);

  const renderPreview = useCallback(() => {
    if (status === EVideoScriptStatus.VIDEO_IN_REVIEW) {
      return <StoryIcon />;
    }

    if (status === EVideoScriptStatus.GENERATING_VIDEO) {
      return (
        <S.AnimationContainer>
          <LoaderWavesAnimation withBlackIcons />
        </S.AnimationContainer>
      );
    }

    return (
      <S.LogoContainer>
        <S.OrgLogo resizeMode='contain' source={orgLogoSource} />
        <S.BiteCornerImage source={BiteCornerImage} />
      </S.LogoContainer>
    );
  }, [orgLogoSource, status]);

  const renderRightContainer = useCallback(() => {
    if (isDeleting) {
      return (
        <>
          <S.StatusContainer>
            <S.StatusText status={'deleting'}>Deleting</S.StatusText>
          </S.StatusContainer>
          <S.ButtonContainer />
        </>
      );
    }

    if (status === EVideoScriptStatus.SCRIPT_IN_REVIEW || status === EVideoScriptStatus.GENERATING_VIDEO_FAILED) {
      return (
        <>
          <S.DeleteButtonContainer>
            <S.DeleteButton onPress={handleDeletePress}>
              <DeleteIcon width={16} height={16} color={theme.colors.lightGray40} />
            </S.DeleteButton>
          </S.DeleteButtonContainer>
          <S.ButtonContainer>
            <S.CreateButton
              isWhite
              onPress={handleCreateVideo}
              content={<S.ButtonText isWhiteButton>Create Story</S.ButtonText>}
            />
          </S.ButtonContainer>
        </>
      );
    }

    if (status === EVideoScriptStatus.VIDEO_IN_REVIEW) {
      return (
        <>
          <S.StatusContainer>
            <S.StatusText status={status}>{STATUS_TEXT_MAP[status]}</S.StatusText>
          </S.StatusContainer>
          <S.ButtonContainer>
            <S.CreateButton onPress={handleCreateBite} content={'Create Bite'} />
          </S.ButtonContainer>
        </>
      );
    }

    return (
      <>
        <S.StatusContainer>
          <S.StatusText status={status}>{STATUS_TEXT_MAP[status]}</S.StatusText>
        </S.StatusContainer>
        <S.ButtonContainer />
      </>
    );
  }, [isDeleting, status, handleDeletePress, theme.colors.lightGray40, handleCreateVideo, handleCreateBite]);

  if (status === EVideoScriptStatus.BITE_GENERATED || !script) {
    return null;
  }

  return (
    <S.ScriptItemContainer status={status} onLayout={handleLayout}>
      <S.LeftContainer>
        <S.ScriptPreviewContainer>{renderPreview()}</S.ScriptPreviewContainer>
        <S.TextContainer>
          <S.ScriptItemTitle>{script.name}</S.ScriptItemTitle>
          <S.ScriptItemDescription isExpanded={isTextExpanded}>{script.description}</S.ScriptItemDescription>
          {!isTextExpanded && (
            <TouchableOpacity onPress={handleMorePress}>
              <S.MoreButtonText>More</S.MoreButtonText>
            </TouchableOpacity>
          )}
          {(createVideoError || status === EVideoScriptStatus.GENERATING_VIDEO_FAILED) && (
            <S.ErrorText>An error occurred while creating the video. Please try again</S.ErrorText>
          )}
        </S.TextContainer>
      </S.LeftContainer>
      <S.RightContainer>{renderRightContainer()}</S.RightContainer>
    </S.ScriptItemContainer>
  );
};

const S = {
  AnimationContainer: styled.View`
    align-items: center;
    justify-content: center;
    width: ${calcWidth(85)}px;
    height: ${calcHeight(120)}px;
  `,
  DeleteButtonContainer: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
    height: 50px;
  `,
  DeleteButton: styled.TouchableOpacity`
    width: ${calcWidth(41)}px;
    height: ${calcWidth(41)}px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  `,
  ButtonContainer: styled.View`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 200px;
    height: 100px;
  `,
  CreateButton: styled(Button)<{ isWhite?: boolean }>`
    width: 160px;
    border-radius: 30px;
    background-color: ${({ theme, isWhite }) => (isWhite ? theme.colors.white : theme.colors.primaryBlue)};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme, isWhite }) => (isWhite ? theme.colors.primaryBlue : theme.colors.white)};
  `,
  ButtonText: styled.Text<{ isWhiteButton?: boolean }>`
    color: ${({ theme, isWhiteButton }) => (isWhiteButton ? theme.colors.primaryBlue : theme.colors.white)};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-style: normal;
    font-weight: 700;
  `,
  StatusContainer: styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100px;
    height: 50px;
  `,
  StatusText: styled.Text<{ status: EVideoScriptStatus | 'deleting' }>`
    color: ${({ theme, status }) =>
      status === EVideoScriptStatus.GENERATING_VIDEO
        ? theme.colors.orange2
        : status === 'deleting'
        ? theme.colors.red1
        : theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 700;
    margin-right: 10px;
  `,
  ScriptItemContainer: styled.View<{ status: EVideoScriptStatus }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: ${calcHeight(150)}px;
    border: 1px solid
      ${({ theme, status }) =>
        status === EVideoScriptStatus.VIDEO_IN_REVIEW
          ? theme.colors.primaryBlue
          : status === EVideoScriptStatus.GENERATING_VIDEO
          ? theme.colors.orange2
          : theme.colors.lightGray45};
    border-radius: 30px;
    margin-bottom: ${calcHeight(20)}px;
    padding: ${calcHeight(15)}px ${calcWidth(10)}px;
  `,
  LeftContainer: styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  RightContainer: styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  `,
  ScriptItemTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 6px;
  `,
  ScriptItemDescription: styled.Text<{ isExpanded: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    max-width: ${calcWidth(400)}px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: ${({ isExpanded }) => (isExpanded ? 'none' : calcHeight(110))}px;
  `,
  ScriptPreviewContainer: styled.View`
    width: ${calcWidth(85)}px;
    height: ${calcHeight(120)}px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray45};
    border-radius: 20px;
    margin: 0 24px 0 6px;
    align-items: center;
    justify-content: center;
  `,
  TextContainer: styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `,
  ErrorText: styled.Text`
    color: ${({ theme }) => theme.colors.failRed};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  `,
  OrgLogo: styled.Image`
    width: ${calcWidth(40)}px;
    height: ${calcHeight(40)}px;
    margin-top: 22px;
  `,
  LogoContainer: styled.View`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  BiteCornerImage: styled.Image`
    position: absolute;
    width: ${calcWidth(24)}px;
    height: ${calcHeight(32)}px;
    bottom: 0;
    left: 0;
  `,
  MoreButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    text-decoration: underline;
  `,
};

export default ScriptItem;
