import { all, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createGeneration,
  INITIAL_GENERATION_ID,
  loadGenerationCloudAssets,
  openProject,
  resetEditGeneration,
  setEditGenerationId,
  setGenerationConfig,
  startGenerationPolling,
  setGenerationCloudAssets,
} from './editAiGeneration.slice';
import { cloudAssetsSelector, editGenerationIdSelector, generationConfigSelector } from './editAiGeneration.selector';
import withRetry from '../../utils/withRetry';
import * as calls from '../api/bites-api/calls/aiGeneration.calls';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { IAction } from '../common/types';
import { getCloudAsset } from '../api/bites-api/calls/cloudAssets.calls';
import { generationByIdSelector, isGenerationLoadingSelector } from '../aiGeneration/aiGeneration.selector';
import { EGenerationStatus } from '../aiGeneration/aiGeneration.types';
import {
  removeGeneration,
  setGeneration,
  setGenerationError,
  setGenerationLoading,
  unshiftGenerationToFeed,
} from '../aiGeneration/aiGeneration.slice';
import { setGenerationScripts } from '../aiGenerationScripts/aiGenerationScripts.slice';
import Routes from '../../navigation/routes';
import { navigate } from '../../navigation/RootNavigation';
import { ILocalCloudAsset } from './editAiGeneration.types';

function* createGenerationSaga() {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const { topics, inputCloudAssetIds, withIntro, withBgMusic, voiceId } = yield select(generationConfigSelector);
  const editGenerationId = yield select(editGenerationIdSelector);
  const editGeneration = yield select(generationByIdSelector(editGenerationId));
  const cleanTopics = topics.map((topic) => (topic.trim().length > 0 ? topic.trim() : null)).filter(Boolean);

  try {
    yield put(setGenerationLoading({ generationId: editGenerationId, state: true }));

    const {
      data: { generation },
    } = yield withRetry(
      () =>
        calls.createGeneration({
          orgId,
          generation: {
            inputCloudAssetIds,
            topics: cleanTopics,
            withIntro,
            withBgMusic,
            voiceId,
            regeneratedFromId: editGeneration ? editGenerationId : undefined,
          },
        }),
      {
        errorContext: {
          data: {
            action: 'createGenerationSaga: createGeneration',
          },
        },
      },
    );

    if (editGeneration) {
      yield calls.updateGeneration({
        orgId,
        generationId: editGenerationId,
        generation: {
          status: EGenerationStatus.OUTDATED,
        },
      });
      yield put(removeGeneration(editGenerationId));
    }

    yield withRetry(
      () =>
        calls.runGeneration({
          orgId,
          generationId: generation.id,
        }),
      {
        errorContext: {
          data: {
            action: 'createGenerationSaga: runGeneration',
          },
        },
      },
    );

    yield put(setEditGenerationId(generation.id));
    yield put(setGeneration(generation));
    yield put(
      unshiftGenerationToFeed({
        generation,
      }),
    );

    yield spawn(startGenerationStatusPollingSaga, { payload: { generationId: generation.id } });
    yield put(setGenerationLoading({ generationId: INITIAL_GENERATION_ID, state: false }));
  } catch (e) {
    yield put(setGenerationError({ generationId: editGenerationId, error: e.message }));
  }
}

function* startGenerationStatusPollingSaga({ payload: { generationId } }: IAction<{ generationId: string }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const isGenerationLoading = yield select(isGenerationLoadingSelector(generationId));

  if (isGenerationLoading) {
    return;
  }

  yield put(setGenerationLoading({ generationId, state: true }));
  while (true) {
    try {
      const {
        data: { generation },
      } = yield calls.getGeneration({
        orgId,
        generationId,
      });

      if (generation.status === EGenerationStatus.OUTDATED) {
        yield put(setGenerationLoading({ generationId, state: false }));
        yield put(removeGeneration(generationId));
        break;
      }

      const currentGeneration = yield select(generationByIdSelector(generationId));
      if (currentGeneration.status !== generation.status) {
        yield put(setGeneration(generation));
      }

      if (generation.status === EGenerationStatus.DONE) {
        const {
          data: { results: scripts },
        } = yield calls.searchGeneratedScripts({ orgId, filters: { generationId } });
        yield put(setGenerationScripts({ generationId, scripts: scripts.map(({ videoScript }) => videoScript) }));

        yield put(setGenerationLoading({ generationId, state: false }));
        break;
      }

      if (generation.status === EGenerationStatus.FAILED) {
        yield put(setGenerationError({ generationId, error: generation.error }));
        break;
      }
    } catch (e) {
    } finally {
      yield new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }
}

function* loadGenerationCloudAssetsSaga({ payload: { generationId } }: IAction<{ generationId: string }>) {
  const generation = yield select(generationByIdSelector(generationId));
  const loadedCloudAssets: ILocalCloudAsset[] = yield select(cloudAssetsSelector);

  const loadedCloudAssetIds = new Set(
    loadedCloudAssets
      .map((asset) => (asset.downloadError || asset.createError ? null : asset.data.cloudAssetId))
      .filter(Boolean),
  );
  const notLoadedCloudAssetIds = generation?.inputCloudAssetIds?.filter((id) => !loadedCloudAssetIds.has(id)) || [];

  if (notLoadedCloudAssetIds.length === 0) {
    return;
  }

  const notLoadedCloudAssets = notLoadedCloudAssetIds.map((id) => ({
    key: id,
    data: { name: null, fileType: null, cloudAssetId: id },
    isLoading: true,
    createError: null,
    downloadError: null,
  }));

  yield put(setGenerationCloudAssets(notLoadedCloudAssets));

  const cloudAssets = yield Promise.allSettled(
    notLoadedCloudAssets.map(({ data }) => getCloudAsset({ id: data.cloudAssetId })),
  );

  const formattedCloudAssets = cloudAssets.map((response, index) => {
    if (response.status === 'fulfilled') {
      return {
        key: notLoadedCloudAssets[index].key,
        data: {
          name: response.value.data.cloudAsset.fileMeta.name,
          fileType: response.value.data.cloudAsset.fileMeta.mimeType,
          cloudAssetId: response.value.data.cloudAsset.id,
        },
        isLoading: false,
        createError: null,
        downloadError: null,
      };
    }

    return {
      key: notLoadedCloudAssets[index].key,
      downloadError: response.reason,
    };
  });

  yield put(setGenerationCloudAssets(formattedCloudAssets));
}

function* openProjectSaga({ payload: { generationId } }) {
  const generation = yield select(generationByIdSelector(generationId));

  yield put(resetEditGeneration());
  yield put(setEditGenerationId(generationId));
  yield put(
    setGenerationConfig({
      topics: [...(generation.topics || []), ''],
      inputCloudAssetIds: generation.inputCloudAssetIds,
      withIntro: generation.withIntro,
      withBgMusic: generation.withBgMusic,
      voiceId: generation.voiceId,
    }),
  );

  navigate(Routes.MainStack.AIContent);
}

export default function* editAiGenerationSaga() {
  yield all([
    takeLatest(openProject, openProjectSaga),
    takeLatest(createGeneration, createGenerationSaga),
    takeLatest(loadGenerationCloudAssets, loadGenerationCloudAssetsSaga),
    takeEvery(startGenerationPolling, startGenerationStatusPollingSaga),
  ]);
}
