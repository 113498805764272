import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import ScriptItem from './ScriptItem';
import ScriptModal from './ScriptModal';
import { loadVideo, webVideoExport } from '../../../store/videoEditor/videoEditor.slice';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../../navigation/routes';
import { StackNavigationProp } from '@react-navigation/stack';
import DeleteScriptModal from './DeleteScriptModal';
import { IScript } from '../../../store/aiGenerationScripts/aiGenerationScripts.types';
import { deleteScript, removeEditScript } from '../../../store/aiGenerationScripts/aiGenerationScripts.slice';
import { editGenerationIdSelector } from '../../../store/editAiGeneration/editAiGeneration.selector';
import { clearCreateBiteState, setCreateBiteFromGenerationScript } from '../../../store/createBite/createBites.actions';
import { cleanEditAndCreateBiteState } from '../../../store/bite/bite.actions';
import { loadBiteHelperConfigs } from '../../../store/appActivity/appActivity.slice';
import {
  setGenerationId,
  setPrimaryTab,
  setScopeSource,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';

const ScriptList = ({ scriptIds }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const editGenerationId = useSelector(editGenerationIdSelector);

  const [activeScriptId, setActiveScriptId] = useState<IScript['id']>(null);
  const [isCreateVideoModalVisible, setIsCreateVideoModalVisible] = useState(false);
  const [isDeleteScriptModalVisible, setIsDeleteScriptModalVisible] = useState(false);

  const handleCreateVideoPress = useCallback((script: IScript) => {
    setActiveScriptId(script.id);
    setIsCreateVideoModalVisible(true);
  }, []);

  const handleCreateBitePress = useCallback(
    (script: IScript) => {
      dispatch(
        loadVideo({
          videoId: script.videoId,
        }),
      );
      setIsCreateVideoModalVisible(false);

      dispatch(loadBiteHelperConfigs());
      dispatch(clearCreateBiteState());
      dispatch(cleanEditAndCreateBiteState());
      dispatch(setCreateBiteFromGenerationScript({ generationId: editGenerationId, scriptId: script.id }));

      // prepare sources sidebar in video editor
      dispatch(setGenerationId(editGenerationId));
      dispatch(setPrimaryTab('SCOPED'));
      dispatch(setScopeSource('GENERATION'));

      navigation.navigate(Routes.CreateBiteStack.StackName, {
        screen: Routes.CreateBiteStack.BiteVideoEditor,
        params: {
          onContinue: () => {
            dispatch(webVideoExport());
            navigation.navigate(Routes.CreateBiteStack.AIExpectationFirst);
          },
        },
      });
    },
    [dispatch, editGenerationId, navigation],
  );

  const handleCloseScriptModal = useCallback(() => {
    dispatch(removeEditScript({ scriptId: activeScriptId }));
    setIsCreateVideoModalVisible(false);
  }, [activeScriptId, dispatch]);

  const handleDeleteScriptModalOpen = useCallback((script: IScript) => {
    setActiveScriptId(script.id);
    setIsDeleteScriptModalVisible(true);
  }, []);

  const handleDeleteScriptModalClose = useCallback(() => {
    setIsDeleteScriptModalVisible(false);
  }, []);

  const handleDeleteScript = useCallback(() => {
    setIsDeleteScriptModalVisible(false);
    dispatch(deleteScript({ scriptId: activeScriptId }));
  }, [activeScriptId, dispatch]);

  return (
    <>
      <S.Container>
        {scriptIds.map((scriptId) => (
          <ScriptItem
            key={scriptId}
            scriptId={scriptId}
            onCreateVideoPress={handleCreateVideoPress}
            onCreateBitePress={handleCreateBitePress}
            onDeletePress={handleDeleteScriptModalOpen}
          />
        ))}
      </S.Container>

      <DeleteScriptModal
        isVisible={isDeleteScriptModalVisible}
        onConfirm={handleDeleteScript}
        onClose={handleDeleteScriptModalClose}
      />
      <ScriptModal
        isVisible={isCreateVideoModalVisible}
        scriptId={activeScriptId}
        onClose={handleCloseScriptModal}
        onContinue={handleCreateBitePress}
      />
    </>
  );
};

const S = {
  Container: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,
};

export default ScriptList;
