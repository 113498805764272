import React from 'react';
import styled from 'styled-components/native';
import { ViewStyle } from 'react-native';

interface IProps {
  style: ViewStyle;
  colors: string[];
  children: React.ReactNode;
  angle?: number;
}

const LinearGradient: React.FC<IProps> = ({ style, children, colors, angle }) => {
  return (
    <S.LinearGradient style={style} colors={colors} angle={angle}>
      {children}
    </S.LinearGradient>
  );
};

const S = {
  LinearGradient: styled.View<{ colors: string[]; angle?: number }>`
    background-image: ${({ colors, angle }) =>
      `linear-gradient(${angle ? `${angle}deg` : 'to bottom'}, ${colors.join(',')})`};
  `,
};

export default LinearGradient;
