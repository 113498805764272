import React from 'react';
import { WEB_CONTAINER_WIDTH } from '../../../utils/dimensions';
import styled from 'styled-components/native';
import InnerFormContainer from '../../Shared/FormContainer';

const FormContainer = ({ children, width = WEB_CONTAINER_WIDTH }) => {
  return (
    <S.ShadowedContainer>
      <InnerFormContainer width={width}>{children}</InnerFormContainer>
    </S.ShadowedContainer>
  );
};

const S = {
  ShadowedContainer: styled.View`
    border: 1px ${({ theme }) => theme.colors.lightGray1} solid;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 6px 6px;
    overflow: visible;
  `,
};

export default FormContainer;
