import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGeneration, IInitialState, LoadGenerations, SearchGenerations } from './aiGeneration.types';
import AuthTypes from '../auth/auth.types';

export const initialState: IInitialState = {
  generationsMap: {},
  generationsFeed: {
    data: [],
    page: 0,
    total: 1,
    isLoading: false,
    error: null,
  },

  createGenerationStatusMap: {},
};

const AI_GENERATION = 'AI_GENERATION';

const aiGenerationSlice = createSlice({
  name: AI_GENERATION,
  initialState,
  reducers: {
    setGenerationsMap: (state, action) => {
      state.generationsMap = { ...state.generationsMap, ...action.payload };
    },
    setGeneration: (state, action) => {
      state.generationsMap[action.payload.id] = action.payload;
    },
    unshiftGenerationToFeed: (state, { payload: { generation } }: PayloadAction<{ generation: IGeneration }>) => {
      const exists = state.generationsFeed.data.some(({ generation: { id } }) => id === generation.id);
      if (!exists) {
        state.generationsFeed.data.unshift({ generation });
      }
    },
    updateGeneration: (state, action) => {
      state.generationsMap[action.payload.generationId] = {
        ...state.generationsMap[action.payload.generationId],
        ...action.payload.changes,
      };
    },
    setGenerationLoading: (state, action) => {
      if (!action.payload.state && state.createGenerationStatusMap[action.payload.generationId]) {
        delete state.createGenerationStatusMap[action.payload.generationId];
      }

      state.createGenerationStatusMap[action.payload.generationId] = {
        isLoading: action.payload.state,
        error: null,
      };
    },
    setGenerationError: (state, action) => {
      state.createGenerationStatusMap[action.payload.generationId] = {
        isLoading: false,
        error: action.payload.error,
      };
    },
    setNextPage: (state, action) => {
      state.generationsFeed.page = action.payload.page;
      state.generationsFeed.total = action.payload.totalPages;
    },
    getProjectsFeed: (state, action: PayloadAction<{ reset: boolean }>) => {
      state.generationsFeed.isLoading = true;

      if (action.payload?.reset) {
        state.generationsFeed.page = 0;
        state.generationsFeed.data = [];
      }
    },
    setFeedData: (state, action) => {
      const generationsMap = state.generationsFeed.data.reduce((acc, { generation }) => {
        acc[generation.id] = generation;
        return acc;
      }, {});

      const generations = action.payload.filter(({ generation }) => {
        return !generationsMap[generation.id];
      });

      state.generationsFeed.data = [...state.generationsFeed.data, ...generations];
      state.generationsFeed.isLoading = false;
    },
    setFeedError: (state, action) => {
      state.generationsFeed.error = action.payload;
      state.generationsFeed.isLoading = false;
    },
    removeGeneration: (state, action) => {
      delete state.generationsMap[action.payload];
      state.generationsFeed.data = state.generationsFeed.data.filter(({ generation: { id } }) => id !== action.payload);
    },
  },
  extraReducers: {
    [AuthTypes.SWITCH_ACTIVE_ORGANIZATION]: () => {
      return initialState;
    },
  },
});

export const {
  getProjectsFeed,
  setGenerationsMap,
  setGeneration,
  unshiftGenerationToFeed,
  updateGeneration,
  setGenerationLoading,
  setGenerationError,
  setNextPage,
  setFeedData,
  setFeedError,
  removeGeneration,
} = aiGenerationSlice.actions;

export default aiGenerationSlice.reducer;

export const searchGenerations = createAction<SearchGenerations>(`${AI_GENERATION}/searchGenerations`);
export const loadGenerations = createAction<LoadGenerations>(`${AI_GENERATION}/loadGenerations`);
