import { useDispatch, useSelector } from 'react-redux';
import { editScriptSelector } from '../../../../store/aiGenerationScripts/aiGenerationScripts.selector';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  saveScriptChanges,
  updateScriptGeneratedText,
} from '../../../../store/aiGenerationScripts/aiGenerationScripts.slice';
import dayjs from 'dayjs';
import styled from 'styled-components';
import defaultTheme from '../../../../themes';

const ScriptGeneratedTextItem = ({ scriptId, item }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const editedScript = useSelector(editScriptSelector(scriptId));
  const editedItem = editedScript?.config?.itemsMap[item.id];

  const handleInputChange = useCallback(
    (event) => {
      const target = event.nativeEvent.target;

      target.style.height = 'auto';
      target.style.height = `${target.scrollHeight}px`;
      dispatch(updateScriptGeneratedText({ scriptId, itemId: item.id, value: target.value }));
    },
    [dispatch, item.id, scriptId],
  );

  const handleBlur = useCallback(() => {
    dispatch(saveScriptChanges({ scriptId }));
  }, [dispatch, scriptId]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  }, []);

  return (
    <S.ScriptConfigItem>
      <S.ScriptConfigItemInput
        ref={inputRef}
        onBlur={handleBlur}
        onChange={handleInputChange}
        value={editedItem ? editedItem.generatedMeta.text : item.generatedMeta.text}
        rows={1}
      />
      <S.ScriptTimeText>
        {dayjs({ seconds: item.start }).format('mm:ss')}
        {' - '}
        {dayjs({ seconds: item.end }).format('mm:ss')}
      </S.ScriptTimeText>
    </S.ScriptConfigItem>
  );
};

const S = {
  ScriptConfigItem: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 16px 18px;
    border: 1px solid ${defaultTheme.colors.lightGray45};
    border-radius: 10px;
    margin-bottom: 12px;
    flex-grow: 1;
  `,
  ScriptConfigItemInput: styled.textarea`
    all: unset;
    align-items: center;
    height: 19px;
    width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow: hidden;
    overflow-y: auto;
    resize: none;
    outline: none;

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Webkit */
    }
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */

    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: ${defaultTheme.fontSizes.s15};
    font-weight: 400;
    color: ${defaultTheme.colors.text};
    flex-grow: 1;
  `,
  ScriptTimeText: styled.span`
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: ${defaultTheme.fontSizes.s13};
    color: ${defaultTheme.colors.gray19};
    font-weight: 400;
    margin-top: 9px;
  `,
};

export default ScriptGeneratedTextItem;
