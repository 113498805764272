import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { IFeedItem } from '../../../../store/feed/feed.types';
import { useDispatch, useSelector } from 'react-redux';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import styled, { css } from 'styled-components/native';
import { CustomText } from '../../../../components/shared';
import { biteItemSelector, bitesLoadingStateMapSelector } from '../../../../store/bite/bite.selectors';
import { openProject, startGenerationPolling } from '../../../../store/editAiGeneration/editAiGeneration.slice';
import { EGenerationStatus } from '../../../../store/aiGeneration/aiGeneration.types';
import Skeleton from '../../../../components/shared/Skeleton';
import StarsIcon from '../../../../assets/icons/stars.svg';
import Button from '../../../../components/shared/buttons/Button';
import IntroProcessingAnimation from '../../../creationBite/common/IntroProcessingAnimation';
import { GestureResponderEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import { activeOrganizationSelector } from '../../../../store/auth/auth.selectors';
import { useGenerationResultItemData } from './useGenerationResultItemData';
import { isGenerationLoadingSelector } from '../../../../store/aiGeneration/aiGeneration.selector';

interface IProps {
  item: any;
  onAddCover?: (item: IFeedItem) => void;
}

const ProjectFeedItem: FC<IProps> = ({ item }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isGenerationLoadingSelector(item.generation.id));

  const { CARDS_NUM, generation, scriptBiteIds, overflowCount, activeScripts } = useGenerationResultItemData(
    item.generation.id,
  );

  useEffect(() => {
    if (generation?.status !== EGenerationStatus.IN_PROGRESS) {
      return;
    }
    dispatch(startGenerationPolling({ generationId: generation.id }));
  }, [dispatch, generation]);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      event.stopPropagation();
      if (!generation?.id) {
        return;
      }
      dispatch(openProject({ generationId: generation.id }));
    },
    [dispatch, generation],
  );

  const renderProjectOverlay = useCallback(() => {
    if (isLoading) {
      return (
        <S.ProjectOverlay>
          <S.OverlayContent>
            <StarsIcon />
            <S.OverlayTitle>Project</S.OverlayTitle>
            <S.IntroProcessingAnimationWrapper>
              <IntroProcessingAnimation />
            </S.IntroProcessingAnimationWrapper>
            <S.OverlayDescription>Processing</S.OverlayDescription>
          </S.OverlayContent>
        </S.ProjectOverlay>
      );
    }

    if (generation?.status === EGenerationStatus.FAILED || generation?.status === EGenerationStatus.INITIAL) {
      return (
        <S.ProjectOverlay>
          <S.OverlayContent>
            <StarsIcon />
            <S.OverlayTitle>Project</S.OverlayTitle>
            <S.ContinueProjectButton
              onPress={handlePress}
              content={<S.ContinueProjectButtonText>Edit project</S.ContinueProjectButtonText>}
            />
            <S.ProjectOverlayErrorText>Something went wrong</S.ProjectOverlayErrorText>
          </S.OverlayContent>
        </S.ProjectOverlay>
      );
    }

    if (scriptBiteIds.length < activeScripts.length) {
      return (
        <S.ProjectOverlay>
          <S.OverlayContent>
            <StarsIcon />
            <S.OverlayTitle>Project</S.OverlayTitle>
            <S.OverlayDescription>Let’s finish your project!</S.OverlayDescription>
            <S.ContinueProjectButton
              onPress={handlePress}
              content={<S.ContinueProjectButtonText>Continue</S.ContinueProjectButtonText>}
            />
            {!!activeScripts.length && (
              <S.OverlayDescription>
                {scriptBiteIds.length} / {activeScripts.length} Bites
              </S.OverlayDescription>
            )}
          </S.OverlayContent>
        </S.ProjectOverlay>
      );
    }

    return null;
  }, [generation?.status, handlePress, isLoading, scriptBiteIds.length, activeScripts.length]);

  if (generation?.status === EGenerationStatus.OUTDATED || generation?.status === EGenerationStatus.DELETED) {
    return null;
  }

  return (
    <S.Container onPress={handlePress}>
      <ShadowedContainer>
        <S.ContentContainer>
          <S.BitesContainer>
            {scriptBiteIds.slice(0, CARDS_NUM).map((biteId) => (
              <SmallBitePreview key={biteId} biteId={biteId} />
            ))}
            {activeScripts.length > scriptBiteIds.length &&
              new Array(activeScripts.length - scriptBiteIds.length).fill(0).map((_, index) => (
                <S.BiteContainer key={`bite-image-${index}`}>
                  <S.BiteImageContainer />
                </S.BiteContainer>
              ))}
            {overflowCount > 0 && <S.OverflowCount>{`+${overflowCount}`}</S.OverflowCount>}
          </S.BitesContainer>
        </S.ContentContainer>
      </ShadowedContainer>
      {renderProjectOverlay()}
      <S.ProjectTitle>{generation?.name}</S.ProjectTitle>
      {!!activeScripts.length && <S.ProjectBitesCount>{activeScripts.length} Bites</S.ProjectBitesCount>}
    </S.Container>
  );
};

const SmallBitePreview = ({ biteId }: { biteId: number }) => {
  const activeOrganization = useSelector(activeOrganizationSelector);
  const biteState = useSelector(bitesLoadingStateMapSelector(biteId));
  const bite = useSelector(biteItemSelector(biteId));
  const biteCoverSource = useMemo(
    () => (bite ? { uri: bite?.linked_cover_url || activeOrganization?.brand_share } : null),
    [activeOrganization?.brand_share, bite],
  );

  return (
    <S.BiteContainer>
      <S.BiteImageContainer>
        {biteState?.isLoading ? (
          <Skeleton width={calcWidth(isWeb ? 104 : 76)} height={calcHeight(isWeb ? 132 : 119)} borderRadius={20} />
        ) : (
          <S.BiteImage source={biteCoverSource} />
        )}
      </S.BiteImageContainer>
    </S.BiteContainer>
  );
};

const S = {
  Container: styled.TouchableOpacity`
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    margin-left: ${isWeb ? calcWidth(15) : calcWidth(9)}px;
    margin-bottom: ${calcHeight(33)}px;
  `,
  ContentContainer: styled.View`
    overflow: hidden;
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    height: ${calcHeight(isWeb ? 276 : 250)}px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.colors.white};
    ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
  `,
  BitesContainer: styled.View`
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${calcHeight(4)}px;
  `,
  ProjectTitle: styled(CustomText)(
    ({ theme }) => css`
      margin-top: ${calcHeight(4)}px;
      padding: 0 ${calcWidth(12)}px;
      font-size: ${theme.fontSizes.s16}px;
      font-family: ${theme.fontFamilies.Arimo};
      color: ${theme.colors.text};
      font-weight: 400;
    `,
  ),
  ProjectBitesCount: styled(CustomText)(
    ({ theme }) => css`
      padding: 0 ${calcWidth(12)}px;
      font-size: ${theme.fontSizes.s16}px;
      font-family: ${theme.fontFamilies.Arimo};
      color: ${theme.colors.text};
      font-weight: 400;
    `,
  ),
  BiteContainer: styled.View`
    margin-top: ${calcHeight(2)}px;
    width: ${calcWidth(isWeb ? 104 : 76)}px;
    height: ${calcHeight(isWeb ? 132 : 119)}px;
  `,
  BiteImageContainer: styled.View`
    flex-grow: 1;
    flex-shrink: 1;
    align-items: stretch;
    justify-content: center;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.feedProjectBite};
    overflow: hidden;
  `,

  BiteImage: styled.Image`
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    height: 50%;
  `,
  OverflowCount: styled.Text`
    position: absolute;
    bottom: ${calcHeight(6)}px;
    right: ${calcWidth(13)}px;
    font-size: ${({ theme }) => theme.fontSizes.s34}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
  `,
  ProjectOverlay: styled.View`
    position: absolute;
    z-index: 4;
    flex: 1;
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    height: ${calcHeight(isWeb ? 276 : 250)}px;
    border-radius: 18px;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
  `,
  OverlayContent: styled.View`
    width: ${calcWidth(160)}px;
    align-items: center;
    justify-content: center;
  `,
  OverlayTitle: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s21}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    margin-top: ${calcHeight(7)}px;
  `,
  OverlayDescription: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    margin-top: ${calcHeight(7)}px;
  `,
  ContinueProjectButton: styled(Button)`
    margin-top: ${calcHeight(14)}px;
    margin-bottom: ${calcHeight(7)}px;
    width: ${calcWidth(160)}px;
    height: ${calcHeight(30)}px;
  `,
  ContinueProjectButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  `,
  IntroProcessingAnimationWrapper: styled.View`
    margin-top: ${calcHeight(18)}px;
  `,
  ProjectOverlayErrorText: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.red1};
    margin-top: ${calcHeight(5)}px;
  `,
};

export default React.memo(ProjectFeedItem);
