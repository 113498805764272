import React, { useCallback } from 'react';
import DropzoneIconLeft from '../../../assets/icons/aiGeneration/dropzoneIcon.svg';
import DropzoneIconRight from '../../../assets/icons/aiGeneration/dropzoneIconRight.svg';
import PdfIcon from '../../../assets/icons/aiGeneration/pdf-icon.svg';
import styled from 'styled-components';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import defaultTheme from '../../../themes';
import { ActivityIndicator } from 'react-native';
import { ILocalCloudAsset } from '../../../store/editAiGeneration/editAiGeneration.types';
import { retryFunctions } from '../aiGeneration.data';
import { DropzoneRootProps } from 'react-dropzone';

interface IProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  cloudAssets: ILocalCloudAsset[];
  onRemoveFile: (key: string) => void;
  onDragEnter: () => void;
  onDragLeave: () => void;
}

const DropzoneContent: React.FC<IProps> = ({ getRootProps, cloudAssets, onRemoveFile, onDragEnter, onDragLeave }) => {
  if (cloudAssets.length > 0) {
    return (
      <S.FileListContainer>
        {cloudAssets.map((cloudAsset, index: number) => (
          <CloudAssetItem key={index} index={index} cloudAsset={cloudAsset} onRemoveFile={onRemoveFile} />
        ))}
      </S.FileListContainer>
    );
  }

  return (
    <S.DropzoneContainer {...getRootProps()} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
      <S.DropzoneIconLeftContainer>
        <DropzoneIconLeft />
      </S.DropzoneIconLeftContainer>
      <S.TextContainer>
        <span>Drop your old content here!</span>
        <span>We'll turn it into a modern micro-learning course.</span>
      </S.TextContainer>
      <S.DropzoneIconRightContainer>
        <DropzoneIconRight />
      </S.DropzoneIconRightContainer>
    </S.DropzoneContainer>
  );
};

interface IFileItemProps {
  index: number;
  cloudAsset: ILocalCloudAsset;
  onRemoveFile: (key: string) => void;
}

const CloudAssetItem: React.FC<IFileItemProps> = ({ cloudAsset, onRemoveFile }) => {
  const fileExtension = cloudAsset.data.name?.split('.').pop();
  const baseName = cloudAsset.data.name?.slice(0, cloudAsset.data.name.lastIndexOf('.'));

  const handleRetry = useCallback(() => {
    retryFunctions[cloudAsset.key]();
  }, [cloudAsset.key]);

  const handleRemoveFile = useCallback(() => {
    onRemoveFile(cloudAsset.key);
  }, [cloudAsset.key, onRemoveFile]);

  return (
    <S.FileItem state={cloudAsset.isLoading ? 'loading' : cloudAsset.createError ? 'error' : 'successful'}>
      <S.FileItemLeftSide>
        <S.IconContainer>
          <PdfIcon width={24} height={24} />
        </S.IconContainer>
        <S.FileName>{baseName}</S.FileName>
        {fileExtension && <S.FileExtension>.{fileExtension}</S.FileExtension>}
        {!cloudAsset.isLoading && cloudAsset.createError && <S.ErrorText>{cloudAsset.createError}</S.ErrorText>}
      </S.FileItemLeftSide>
      <S.FileItemRightSide>
        {cloudAsset.isLoading && <ActivityIndicator />}
        {!cloudAsset.isLoading && cloudAsset.createError && (
          <S.FileItemButton onClick={handleRetry}>Retry</S.FileItemButton>
        )}
        <S.FileItemButton onClick={handleRemoveFile}>Remove</S.FileItemButton>
      </S.FileItemRightSide>
    </S.FileItem>
  );
};

const S = {
  DropzoneContainer: styled.div`
    height: ${calcHeight(311)}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    margin: ${calcHeight(4)}px ${calcHeight(16)}px ${calcHeight(16)}px;
    padding: ${calcHeight(16)}px;

    cursor: pointer;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${defaultTheme.colors.text};
    text-align: center;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    pointer-events: none;
  `,
  DropzoneIconLeftContainer: styled.div`
    position: absolute;
    top: ${calcHeight(70)}px;
    left: ${calcWidth(-198)}px;
    pointer-events: none;
  `,
  DropzoneIconRightContainer: styled.div`
    position: absolute;
    top: ${calcHeight(87)}px;
    right: ${calcWidth(-205)}px;
    pointer-events: none;
  `,
  FileListContainer: styled.div`
    margin: ${calcHeight(13)}px ${calcWidth(28)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,
  FileItem: styled.div<{ state: 'loading' | 'error' | 'successful' }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: ${calcHeight(5)}px 0;
    align-self: stretch;
    background: ${({ state }) =>
      state === 'successful'
        ? defaultTheme.colors.fileBackgroundSuccess
        : state === 'error'
        ? defaultTheme.colors.fileBackgroundError
        : defaultTheme.colors.fileBackground};
    padding: 5px;
  `,
  IconContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;
    margin-right: ${calcWidth(16)}px;
  `,
  FileName: styled.span`
    max-width: ${calcWidth(200)}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${defaultTheme.colors.text};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 16px;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
  `,
  FileExtension: styled.span`
    white-space: nowrap;
    color: ${defaultTheme.colors.text};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 16px;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
  `,
  FileItemLeftSide: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 2;
  `,
  FileItemRightSide: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  `,
  SuccessText: styled.span`
    white-space: nowrap;
    color: ${defaultTheme.colors.fileSuccessText};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-left: ${calcWidth(16)}px;
  `,
  ErrorText: styled.span`
    white-space: nowrap;
    color: ${defaultTheme.colors.fileErrorText};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-left: ${calcWidth(16)}px;
  `,
  FileItemButton: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${calcWidth(80)}px;
    height: ${calcHeight(32)}px;
    text-decoration: underline;
    cursor: pointer;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    color: ${defaultTheme.colors.primaryBlue};
  `,
};

export default DropzoneContent;
