import React from 'react';
import LottieView from 'lottie-react';
import { calcWidth } from '../../../utils/dimensions';
import styled from 'styled-components';
import { I18nManager } from 'react-native';

interface IProps {
  width?: number;
}

const CreationAnimation: React.FC<IProps> = ({ width = 450 }) => {
  return (
    <S.LottieView
      width={width}
      animationData={require('../../../assets/lottie/creation-bite-lottie.json')}
      autoPlay
      loop
    />
  );
};

const S = {
  LottieView: styled(LottieView)<{ width: number }>`
    width: ${({ width }) => calcWidth(width)}px;
    margin-right: ${I18nManager.isRTL ? 360 : 0}px;
  `,
};

export default CreationAnimation;
