import axios, { ResponseType } from 'axios';
import BitesApi, { BASE_BACKEND_URL, tokenRef } from '..';
import {
  ICloudAsset,
  IGenerateTalkingAvatarServiceResult,
  IGenerateTalkingAvatarService,
} from '../../../cloudAssets/cloudAssets.types';
import { IDataTableRequest, IDataTableResult } from '../../../../types/common';
import { IVideoConfig } from '../../../videoEditor/videoEditor.types';

const CLOUD_ASSET_BASE_URL = `${BASE_BACKEND_URL}/api/common_services/cloud_asset`;
// const CLOUD_ASSET_BASE_URL = `http://localhost:3000/api/common_services/cloud_asset`;

const CLOUD_ASSET_PROCESSING_BASE_URL = `${BASE_BACKEND_URL}/api/cloud_assets_processing`;
// const CLOUD_ASSET_PROCESSING_BASE_URL = `http://localhost:3000/api/cloud_assets_processing`;

export const searchCloudAssets = async (
  payload: IDataTableRequest<
    {
      orgId: number;
      search?: string;
      usedInBiteIds?: number[];
      usedInGenerationId?: string;
      favoriteByUserIds?: ICloudAsset['favoriteByUserIds'];
      isBrandAsset?: ICloudAsset['isBrandAsset'];
      fileType?: ICloudAsset['fileType'];
    },
    undefined,
    undefined
  >,
) => BitesApi.post<IDataTableResult<{ cloudAsset: ICloudAsset }, undefined>>(`${CLOUD_ASSET_BASE_URL}/search`, payload);

export const getCloudAsset = async ({ id }: { id: ICloudAsset['id'] }) =>
  BitesApi.get<{ cloudAsset: ICloudAsset }>(CLOUD_ASSET_BASE_URL, {
    params: {
      id,
    },
  });

export const createCloudAsset = async (cloudAsset: ICloudAsset) =>
  BitesApi.post<{ cloudAsset: ICloudAsset }>(CLOUD_ASSET_BASE_URL, {
    cloudAsset,
  });

export const processCloudAssetMeta = async ({ cloudAssetId }: { cloudAssetId: ICloudAsset['id'] }) =>
  BitesApi.post(`${CLOUD_ASSET_PROCESSING_BASE_URL}/meta`, {
    cloudAssetId,
  });
export const resizeCloudAssetForResolution = async ({
  cloudAssetId,
  resolution,
}: {
  cloudAssetId: ICloudAsset['id'];
  resolution: Partial<IVideoConfig['resolution']>;
}) =>
  BitesApi.post(`${CLOUD_ASSET_PROCESSING_BASE_URL}/resize`, {
    cloudAssetId,
    resolution,
  });
export const processCloudAssetPdf = async ({ cloudAssetId }: { cloudAssetId: ICloudAsset['id'] }) =>
  BitesApi.post(`${CLOUD_ASSET_PROCESSING_BASE_URL}/pdf`, {
    cloudAssetId,
  });
export const getCloudAssetProcessingTask = async ({ taskId }: { taskId: string }) =>
  BitesApi.get(`${CLOUD_ASSET_PROCESSING_BASE_URL}/task`, {
    params: {
      taskId,
    },
  });

export const generateTalkingAvatarService = async (props: IGenerateTalkingAvatarService) =>
  axios.post<IGenerateTalkingAvatarServiceResult>(`${BASE_BACKEND_URL}/api/common_services/d-id/talk`, {
    token: tokenRef.current,
    ...props,
  });

export const getTalkingAvatarService = async (talkId: string) =>
  axios.get<IGenerateTalkingAvatarServiceResult>(`${BASE_BACKEND_URL}/api/common_services/d-id/talk/${talkId}`);

export const downloadFileService = async (
  url: string,
  { responseType = 'blob' }: { responseType?: ResponseType } = {},
) =>
  axios.get<Blob>(url, {
    responseType,
  });

export const addCloudAssetToFavorites = async (cloudAssetId: ICloudAsset['id']) =>
  BitesApi.post(`${CLOUD_ASSET_BASE_URL}/favorite/${cloudAssetId}`);
export const removeCloudAssetFromFavorites = async (cloudAssetId: ICloudAsset['id']) =>
  BitesApi.delete(`${CLOUD_ASSET_BASE_URL}/favorite/${cloudAssetId}`);

export const addCloudAssetToBrand = async (cloudAssetId: ICloudAsset['id']) =>
  BitesApi.post(`${CLOUD_ASSET_BASE_URL}/brand/${cloudAssetId}`);
export const removeCloudAssetFromBrand = async (cloudAssetId: ICloudAsset['id']) =>
  BitesApi.delete(`${CLOUD_ASSET_BASE_URL}/brand/${cloudAssetId}`);
