import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  biteSelector,
  generationIdSelector,
  scopeSourceSelector,
} from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import Select from '../components/Select';
import { SearchVideosUsedInBites } from './Bite/SearchVideosUsedInBites';
import styled from 'styled-components';
import defaultTheme from '../../../../themes';
import { setScopeSource } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { organizationFeaturesSelector } from '../../../../store/org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../../../utils/constants/org';
import { SearchGenerations } from './Generation/SearchGenerations';
import { generationByIdSelector } from '../../../../store/aiGeneration/aiGeneration.selector';

export const Sources = () => {
  const dispatch = useDispatch();

  const bite = useSelector(biteSelector);

  const scopeSource = useSelector(scopeSourceSelector);

  const generationId = useSelector(generationIdSelector);
  const generation = useSelector(generationByIdSelector(generationId));

  const [selectIsOpened, setSelectIsOpened] = useState(false);

  const organizationFeatures = useSelector(organizationFeaturesSelector);
  const aiStudioFeatureFlag = organizationFeatures.includes(FEATURE_FLAG_IDS.AiStudio);

  const handelSelectOpen = useCallback(() => {
    setSelectIsOpened(true);
  }, []);

  const handelSelectClose = useCallback(() => {
    setSelectIsOpened(false);
  }, []);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleClickThisVideo = useCallback(() => {
    dispatch(setScopeSource('CURRENT_BITE'));
  }, [dispatch]);

  const handelAnotherBiteClick = useCallback(() => {
    dispatch(setScopeSource('ANOTHER_BITE'));
  }, [dispatch]);

  const handleGenerationClick = useCallback(() => {
    dispatch(setScopeSource('GENERATION'));
  }, [dispatch]);

  const renderBiteSearchContent = useCallback(({ onClose }: { onClose: () => void }) => {
    return <SearchVideosUsedInBites onClose={onClose} />;
  }, []);

  const renderGenerationSearchContent = useCallback(({ onClose }: { onClose: () => void }) => {
    return <SearchGenerations onClose={onClose} />;
  }, []);

  // get pdf cloud asset by createdFrom?.generationId
  // then search derived cloud assets by extractedFromCloudAssetId = createdFrom?.generationId

  return (
    <S.Sources onClick={handleClick}>
      <S.Source isSelected={!selectIsOpened && scopeSource === 'CURRENT_BITE'} onClick={handleClickThisVideo}>
        This bite
      </S.Source>
      <Select
        label={bite?.name || 'Another bite'}
        isSelected={!selectIsOpened && scopeSource === 'ANOTHER_BITE'}
        renderContent={renderBiteSearchContent}
        onClick={bite ? handelAnotherBiteClick : undefined}
        onOpen={handelSelectOpen}
        onClose={handelSelectClose}
      />
      {aiStudioFeatureFlag && (
        // {createdFrom?.generationId && (
        <Select
          label={generation?.name || 'Project'}
          isSelected={!selectIsOpened && scopeSource === 'GENERATION'}
          renderContent={renderGenerationSearchContent}
          onClick={generation ? handleGenerationClick : undefined}
          onOpen={handelSelectOpen}
          onClose={handelSelectClose}
        />
      )}
    </S.Sources>
  );
};

const S = {
  Sources: styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin: 0 12px;
  `,
  Source: styled.div<{
    isSelected: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0 8px;
    height: 30px;
    color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.primaryBlue : defaultTheme.colors.gray)};
    font-size: 13px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    line-height: 16px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    border-radius: ${8}px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
};
