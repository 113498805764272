import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { activeOrganizationSelector } from '../../../../../store/auth/auth.selectors';
import { logError } from '../../../../../store/appActivity/appActivity.slice';
import { getErrorLogData } from '../../../../../utils/getErrorLogData';
import Search from '../../Search.web';
import { searchGenerations } from '../../../../../store/aiGeneration/aiGeneration.slice';
import { IGeneration } from '../../../../../store/aiGeneration/aiGeneration.types';
import GenerationResultItem from './GenerationResultItem';

export const SearchGenerations = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();

  const org = useSelector(activeOrganizationSelector);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [results, setResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalResults, setTotalResults] = useState(0);

  const requestIdRef = useRef(0);

  const handleSearch = useCallback(
    async ({
      query,
      withReset,
      withDelayedReset,
    }: {
      query: string;
      withReset: boolean;
      withDelayedReset: boolean;
    }) => {
      const requestId = ++requestIdRef.current;

      try {
        setIsError(false);
        setIsSearching(false);

        if (withReset) {
          setResults(null);
          setPage(0);
          setTotalResults(0);
        }

        await new Promise((resolve) => setTimeout(resolve, 300));

        if (requestId !== requestIdRef.current) {
          return;
        }

        setIsSearching(true);

        const dtResult = await new Promise<{
          results: {
            generation: IGeneration;
          }[];
          page: number;
          totalPages: number;
          count: number;
        }>((onDone, onError) => {
          const filters: any = {};
          const search = query?.trim();
          if (search) {
            filters.search = search;
          }

          dispatch(
            searchGenerations({
              filters,
              additionalFields: ['activeShortVideoScripts', 'assetsNum', 'creator'],
              page: results && !withReset && !withDelayedReset ? page + 1 : 0,
              pageSize: 10,
              onDone,
              onError,
            }),
          );
        });

        if (requestId !== requestIdRef.current) {
          return;
        }

        setResults(withReset || withDelayedReset ? dtResult.results : [...(results || []), ...dtResult.results]);
        setPage(dtResult.page);
        setIsSearching(false);
        setTotalResults(dtResult.count);
      } catch (error) {
        dispatch(
          logError({
            event: 'SearchGenerations: error',
            data: {
              orgId: org.id,
              searchText,
              error: getErrorLogData(error),
            },
          }),
        );

        if (requestId !== requestIdRef.current) {
          return;
        }

        if (withDelayedReset) {
          setResults(null);
          setPage(0);
          setTotalResults(0);
        }

        setIsError(true);
        setIsSearching(false);
      }
    },
    [dispatch, org.id, page, results, searchText],
  );

  const renderItem = useCallback(
    ({ item }) => {
      return <GenerationResultItem item={item} onClose={onClose} />;
    },
    [onClose],
  );

  const getItemKey = useCallback((item) => `${item.generation.id}`, []);

  return (
    <S.Container>
      <Search
        results={results}
        isLoading={isSearching}
        isError={isError}
        hasMore={results?.length < totalResults}
        withSearchInput
        searchInputHeight={40}
        layout='list'
        setSearchInput={setSearchText}
        searchText={searchText}
        onSearch={handleSearch}
        renderItem={renderItem}
        getItemKey={getItemKey}
      />
    </S.Container>
  );
};

export const S = {
  Container: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
};
