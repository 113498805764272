import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getProjectsFeed,
  loadGenerations,
  searchGenerations,
  setFeedData,
  setFeedError,
  setGeneration,
  setGenerationsMap,
  setNextPage,
} from './aiGeneration.slice';
import * as calls from '../api/bites-api/calls/aiGeneration.calls';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { EGenerationStatus, GetProjectsFeed, LoadGenerations, SearchGenerations } from './aiGeneration.types';
import { generationsFeedSelector } from './aiGeneration.selector';
import { setGenerationScriptsMap, setScriptsMap } from '../aiGenerationScripts/aiGenerationScripts.slice';
import { fetchFullBites } from '../bite/bite.actions';
import { bitesMapSelector } from '../bite/bite.selectors';
import { logError } from '../appActivity/appActivity.slice';
import { getErrorLogData } from '../../utils/getErrorLogData';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import withRetry from '../../utils/withRetry';

function* getProjectsFeedSaga({ payload }: PayloadAction<GetProjectsFeed>) {
  const { search } = payload || {};

  const { page } = yield select(generationsFeedSelector);

  try {
    const { results, totalPages } = yield searchGenerationsSaga({
      payload: {
        filters: {
          search,
          statuses: [
            EGenerationStatus.IN_PROGRESS,
            EGenerationStatus.DONE,
            EGenerationStatus.INITIAL,
            EGenerationStatus.FAILED,
          ],
        },
        additionalFields: ['activeShortVideoScripts'],
        page,
        pageSize: 20,
        withThrow: true,
      },
    });

    yield put(setNextPage({ page: page + 1, totalPages }));
    yield put(setFeedData(results));
  } catch (e) {
    yield put(setFeedError(e.message));
  }
}

function* searchGenerationsSaga({ payload }: Pick<PayloadAction<SearchGenerations>, 'payload'>) {
  const {
    filters,
    additionalFields = ['activeShortVideoScripts'],
    page,
    pageSize = 20,
    onDone,
    onError,
    withThrow,
  } = payload;
  const { search, statuses = [EGenerationStatus.DONE] } = filters || {};

  const { id: orgId } = yield select(activeOrganizationSelector);

  try {
    const { data: dtResult } = yield calls.searchGenerations({
      orgId,
      filters: {
        orgId,
        statuses,
        search,
      },
      additionalFields,
      page,
      pageSize,
    });

    const { results, totalPages, count } = dtResult;

    const shortScriptsMap = {};
    const generationsMap = {};
    const generationScriptsMap = {};
    const biteIds = [];

    results.forEach((result) => {
      generationScriptsMap[result.generation.id] = [];
      result.activeShortVideoScripts?.forEach((script) => {
        shortScriptsMap[script.id] = script;
        generationScriptsMap[result.generation.id].push(script.id);
        if (script.biteId) {
          biteIds.push(script.biteId);
        }
      });

      generationsMap[result.generation.id] = result.generation;
    });

    const loadedBitesMap = yield select(bitesMapSelector);
    const firstBiteIds = biteIds.slice(0, 4);
    const notLoadedBiteIds = firstBiteIds.filter((id) => !loadedBitesMap[id]);
    if (notLoadedBiteIds.length) {
      yield put(fetchFullBites(notLoadedBiteIds));
    }

    yield put(setGenerationsMap(generationsMap));
    yield put(setScriptsMap(shortScriptsMap));
    yield put(setGenerationScriptsMap(generationScriptsMap));

    if (onDone) {
      onDone({ results, page: dtResult.page, totalPages, count });
    }

    return { results, page: dtResult.page, totalPages, count };
  } catch (error: any) {
    if (onError) {
      onError(error);
    }
    if (withThrow) {
      throw error;
    }
  }
}

export function* loadGenerationsSaga({ payload }: Pick<PayloadAction<LoadGenerations>, 'payload'>) {
  const { generationIds } = payload;
  const processId = payload.processId || uuid();

  const org = yield select(activeOrganizationSelector);

  try {
    const promisesResults = yield Promise.allSettled(
      generationIds.map((generationId) => {
        return withRetry(
          () =>
            calls.getGeneration({
              orgId: org.id,
              generationId,
            }),
          {
            errorContext: {
              data: {
                processId,
                action: 'loadGenerationsSaga',
                data: {
                  generationId,
                },
              },
            },
          },
        );
      }),
    );

    const results = promisesResults
      .map((promiseResult) => {
        if (promiseResult.status === 'fulfilled') {
          return promiseResult.value;
        }

        return null;
      })
      .filter(Boolean);

    for (let result of results) {
      yield put(setGeneration(result.data.generation));
    }

    if (results.length < generationIds.length) {
      throw new Error('Some assets failed to load');
    }
  } catch (error) {
    logError({
      event: 'loadCloudAssetsSaga: error',
      processId,
      data: {
        ...getErrorLogData(error),
        generationIds,
      },
    });
  }
}

export default function* aiGenerationSaga() {
  yield takeLatest(getProjectsFeed, getProjectsFeedSaga);
  yield takeEvery(searchGenerations, searchGenerationsSaga);
  yield takeEvery(loadGenerations, loadGenerationsSaga);
}
