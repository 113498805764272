import FileUpload from './common/FileUpload';
import React, { useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight } from '../../utils/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import GenerationConfigQuestionnaire from './common/GenerationConfigQuestionnaire';
import {
  createGeneration,
  loadGenerationCloudAssets,
  setIsRestartScreen,
} from '../../store/editAiGeneration/editAiGeneration.slice';
import {
  cloudAssetIsLoadingSelector,
  cloudAssetsSelector,
  editGenerationIdSelector,
  isRestartScreenSelector,
} from '../../store/editAiGeneration/editAiGeneration.selector';
import Button from '../../components/shared/buttons';
import { generationErrorSelector } from '../../store/aiGeneration/aiGeneration.selector';
import { ActivityIndicator } from 'react-native';

interface IProps {}

const UploadForm: React.FC<IProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentFiles = useSelector(cloudAssetsSelector);
  const isLoading = useSelector(cloudAssetIsLoadingSelector);
  const editGenerationId = useSelector(editGenerationIdSelector);
  const editGenerationError = useSelector(generationErrorSelector(editGenerationId));
  const isRestart = useSelector(isRestartScreenSelector);

  useEffect(() => {
    dispatch(loadGenerationCloudAssets({ generationId: editGenerationId }));
  }, [dispatch, editGenerationId]);

  const handleContinue = useCallback(() => {
    dispatch(createGeneration());
    dispatch(setIsRestartScreen(false));
  }, [dispatch]);

  const handleGoBack = useCallback(() => {
    dispatch(setIsRestartScreen(false));
  }, [dispatch]);

  return (
    <S.Form>
      <S.Title>Turn Your Legacy Content Into Bites and Playlists</S.Title>
      <S.Description>
        Transform Static Documents and Presentations Into Engaging, Interactive Training Modules
      </S.Description>
      <FileUpload />
      {currentFiles.length > 0 && (
        <>
          <GenerationConfigQuestionnaire />
          <S.Footer>
            {isRestart ? (
              <S.GoBackButton onPress={handleGoBack}>
                <S.GoBackButtonText>{'< Go back and Cancel changes'}</S.GoBackButtonText>
              </S.GoBackButton>
            ) : (
              <S.EmptyView />
            )}
            <S.ContinueButtonContainer>
              {isLoading ? (
                <S.LoadingTextContainer>
                  <S.LoadingText>Please wait until the files upload</S.LoadingText>
                  <ActivityIndicator size='small' color={theme.colors.gray19} />
                </S.LoadingTextContainer>
              ) : (
                !!editGenerationError && <S.ErrorText>Something went wrong. Please try again.</S.ErrorText>
              )}

              <S.ContinueButton disabled={isLoading} onPress={handleContinue} content={'Continue'} />
            </S.ContinueButtonContainer>
          </S.Footer>
        </>
      )}
    </S.Form>
  );
};

const S = {
  Form: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${calcHeight(50)}px ${calcHeight(50)}px ${calcHeight(30)}px;
  `,
  Title: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 100% */
    margin-bottom: ${calcHeight(16)}px;
  `,
  Description: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin-bottom: ${calcHeight(38)}px;
  `,
  Footer: styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  `,
  ContinueButtonContainer: styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 36px;
  `,
  ContinueButton: styled(Button)<{ disabled: boolean }>`
    width: 200px;
    height: 50px;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.darkGray3 : theme.colors.primaryBlue)};
    border: none;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
  `,
  LoadingTextContainer: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: ${calcHeight(10)}px;
  `,
  LoadingText: styled.Text`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    padding-right: ${calcHeight(10)}px;
  `,
  ErrorText: styled.Text`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.pinkError};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    padding-top: ${calcHeight(14)}px;
    padding-right: ${calcHeight(10)}px;
  `,
  GoBackButton: styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  `,
  GoBackButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  `,
  EmptyView: styled.View`
    flex: 1;
  `,
};

export default UploadForm;
