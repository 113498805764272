import { RootState } from '../index';

export const getState = (state: RootState) => state.videoEditorLeftSidebar;

export const primaryTabSelector = (state: RootState) => getState(state).primaryTab;
export const searchSelector = (state: RootState) => getState(state).search;
export const cloudFileTypeSelector = (state: RootState) => getState(state).cloudFileType;
export const stockFileTypeSelector = (state: RootState) => getState(state).stockFileType;
export const orientationSelector = (state: RootState) => getState(state).orientation;
export const biteSelector = (state: RootState) => getState(state).bite;
export const generationIdSelector = (state: RootState) => getState(state).generationId;
export const scopeSourceSelector = (state: RootState) => getState(state).scopeSource;
export const cloudResultsSelector = (state: RootState) => getState(state).cloudResults;
export const scopedResultsSelector = (state: RootState) => getState(state).scopedResults;
export const favoriteResultsSelector = (state: RootState) => getState(state).favoriteResults;
export const brandResultsSelector = (state: RootState) => getState(state).brandResults;
export const stockVideoResultsSelector = (state: RootState) => getState(state).stockVideoResults;
export const stockImageResultsSelector = (state: RootState) => getState(state).stockImageResults;
export const stockGifResultsSelector = (state: RootState) => getState(state).stockGifResults;
export const stockStickerResultsSelector = (state: RootState) => getState(state).stockStickerResults;
export const favoriteFlaggedToResetSelector = (state: RootState) => !!getState(state).flaggedToReset.favorite;
export const brandFlaggedToResetSelector = (state: RootState) => !!getState(state).flaggedToReset.brand;
export const overlaySelector = (state: RootState) => getState(state).overlay;
