import React, { useCallback, useEffect, useMemo } from 'react';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import SharePanel from '../../components/SharePanel/SharePanel';
import BottomPanel from '../../components/BottomPanel/BottomPanel';
import HomeDrawer from './HomeDrawer';
import CreatePlaylistStack from './CreatePlaylistStack';
import CreateQuizStack from './CreateQuizStack';
import EditPlaylistStack from './EditPlaylistStack';
import JoinOrganization from '../../screens/JoinOrganization';
import EditQuizStack from './EditQuizStack';
import { BackHeader } from '../../components/shared';
import { isWeb } from '../../utils/dimensions';
import { EHeaderBackButtonType } from '../../utils/constants/header';
import HomeStack from './HomeDrawer/HomeStack';
import CreateBiteStack from './CreateBiteStack';
import EditBiteStack from './EditBiteStack/EditBiteStack';
import Routes from '../routes';
import AppModals from '../../components/modals/AppModals';
import PreviewStack from './PreviewStack';
import useInitialData from '../../hooks/useInitialData';
import SplashScreen from 'react-native-lottie-splash-screen';
import SsoAuthLoader from '../../screens/auth/common/SsoAuthLoader';
import { runExternalAction } from '../../store/appActivity/appActivity.slice';
import { useDispatch, useSelector } from 'react-redux';
import defaultTheme from '../../themes';
import { unauthorizedExternalActionTypes } from '../../utils/runExternalAction/runExternalAction';
import { externalActionSelector } from '../../store/appActivity/appActivity.selectors';
import AnalyticsStack from './AnalyticsStack/AnalyticsStack';
import useUploadBanubaFiles from '../../hooks/useUploadBanubaFiles/useUploadBanubaFiles';
import AiContent from '../../screens/aiContent/AiContent';

const Stack = createStackNavigator();

const defaultScreenOptions = { headerShown: false, animationEnabled: !isWeb };
const screenOptions = {
  ...TransitionPresets.SlideFromRightIOS,
  animationEnabled: true,
  cardStyle: {
    backgroundColor: defaultTheme.colors.defaultGrayBg,
  },
};

const MainStack: React.FC = () => {
  const dispatch = useDispatch();

  useUploadBanubaFiles();
  const { isLoading } = useInitialData();
  const externalAction = useSelector(externalActionSelector);

  useEffect(() => {
    if (!isLoading) {
      if (
        (externalAction?.action || externalAction?.msg_type) &&
        !unauthorizedExternalActionTypes.includes(externalAction.action)
      ) {
        dispatch(runExternalAction());
      }
    }
    if (!isWeb && !isLoading) {
      setTimeout(() => SplashScreen.hide(), 0);
    }
  }, [dispatch, isLoading, externalAction]);

  const renderHeaderLeft = useCallback(
    () => (isWeb ? null : <BackHeader mode={EHeaderBackButtonType.INNER_ARROW} />),
    [],
  );

  const renderHomeStack = useCallback(() => <HomeStack isLoading={isLoading} />, [isLoading]);

  const joinOrganizationOptions = useMemo(
    () => ({
      ...defaultScreenOptions,
      headerLeft: renderHeaderLeft,
    }),
    [renderHeaderLeft],
  );

  return (
    <>
      {!isWeb && isLoading ? (
        <SsoAuthLoader />
      ) : (
        <>
          <Stack.Navigator screenOptions={screenOptions}>
            <Stack.Screen
              name={isWeb ? Routes.HomeStack.StackName : Routes.HomeDrawer.StackName}
              component={isWeb ? renderHomeStack : HomeDrawer}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.MainStack.JoinOrganization}
              component={JoinOrganization}
              options={joinOrganizationOptions}
            />
            <Stack.Screen
              name={Routes.CreateBiteStack.StackName}
              component={CreateBiteStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.EditBiteStack.StackName}
              component={EditBiteStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.CreatePlaylistStack.StackName}
              component={CreatePlaylistStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.CreateQuizStack.StackName}
              component={CreateQuizStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.EditPlaylistStack.StackName}
              component={EditPlaylistStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.EditQuizStack.StackName}
              component={EditQuizStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.PreviewStack.StackName}
              component={PreviewStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen
              name={Routes.AnalyticsStack.StackName}
              component={AnalyticsStack}
              options={defaultScreenOptions}
            />
            <Stack.Screen name={Routes.MainStack.AIContent} component={AiContent} options={defaultScreenOptions} />
          </Stack.Navigator>

          <SharePanel />
          <BottomPanel />
        </>
      )}
      <AppModals />
    </>
  );
};

export default MainStack;
