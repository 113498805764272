import React from 'react';
import LottieView from 'lottie-react';
import styled from 'styled-components';

interface IProps {
  withBlackIcons?: boolean;
}

const LoaderWavesAnimation: React.FC<IProps> = ({ withBlackIcons }) => {
  const animationData = withBlackIcons
    ? require('../../../assets/lottie/loader-waves-black-lottie.json')
    : require('../../../assets/lottie/loader-waves-lottie.json');

  return <S.LottieView animationData={animationData} autoPlay loop />;
};

const S = {
  LottieView: styled(LottieView)``,
};

export default React.memo(LoaderWavesAnimation);
