import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFullWidthContent } from '../../store/appActivity/appActivity.slice';
import styled from 'styled-components/native';
import FormContainer from './common/FormContainer';
import { calcHeight, calcWidth, WEB_CONTAINER_WIDTH } from '../../utils/dimensions';
import BitesLogoIcon from '../../assets/icons/bites-logo.svg';
import Header from './common/Header';
import UploadForm from './UploadForm';
import PdfIcon from '../../assets/icons/aiGeneration/pdf-icon.svg';
import {
  editGenerationIdSelector,
  isRestartScreenSelector,
} from '../../store/editAiGeneration/editAiGeneration.selector';
import CreationAnimation from '../../components/shared/CreationAnimation/CreationAnimation.web';
import ScriptList from './common/ScriptList';
import { EGenerationStatus } from '../../store/aiGeneration/aiGeneration.types';
import { startGenerationPolling } from '../../store/editAiGeneration/editAiGeneration.slice';
import { generationByIdSelector, isGenerationLoadingSelector } from '../../store/aiGeneration/aiGeneration.selector';
import BiteList from './common/BiteList';
import {
  generationScriptsSelector,
  scriptsMapSelector,
} from '../../store/aiGenerationScripts/aiGenerationScripts.selector';
import openBiteStats from '../../utils/bite/openBiteStats';
import { useIsFocused } from '@react-navigation/native';
import { bitesStateMapSelector } from '../../store/bite/bite.selectors';
import { BITE_ERROR_MESSAGE } from './aiContent.const';

enum EForm {
  INITIAL = 'initial',
  SCRIPT_LIST = 'scriptList',
  GENERATING_SCRIPTS = 'generatingScripts',
}

const AiContent = () => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const editGenerationId = useSelector(editGenerationIdSelector);
  const editGeneration = useSelector(generationByIdSelector(editGenerationId));
  const isGenerationLoading = useSelector(isGenerationLoadingSelector(editGenerationId));
  const isRestartScreen = useSelector(isRestartScreenSelector);

  const generationScripts = useSelector(generationScriptsSelector(editGenerationId));
  const scriptsMap = useSelector(scriptsMapSelector);
  const bitesStatusMap = useSelector(bitesStateMapSelector);

  const bitesIds = useMemo(() => {
    return generationScripts
      .map((scriptId) =>
        scriptsMap[scriptId]?.biteId && bitesStatusMap[scriptsMap[scriptId]?.biteId]?.error !== BITE_ERROR_MESSAGE
          ? scriptsMap[scriptId]?.biteId
          : null,
      )
      .filter(Boolean);
  }, [bitesStatusMap, generationScripts, scriptsMap]);

  const scriptsWithoutBites = useMemo(() => {
    return generationScripts.filter((scriptId) => !scriptsMap[scriptId]?.biteId);
  }, [generationScripts, scriptsMap]);

  const activeForm = useMemo(() => {
    if (!isGenerationLoading && editGeneration?.status === EGenerationStatus.DONE && !isRestartScreen) {
      return EForm.SCRIPT_LIST;
    }

    if (isGenerationLoading && !isRestartScreen) {
      return EForm.GENERATING_SCRIPTS;
    }

    return EForm.INITIAL;
  }, [editGeneration?.status, isGenerationLoading, isRestartScreen]);

  const handleBitePress = useCallback(
    ({ bite }) => {
      document.querySelector('.RootWrapper')?.classList.remove('full-width');
      dispatch(setIsFullWidthContent(false));
      openBiteStats({ biteId: bite?.id });
    },
    [dispatch],
  );

  useEffect(() => {
    if (editGeneration?.status !== EGenerationStatus.IN_PROGRESS || isGenerationLoading) {
      return;
    }

    dispatch(startGenerationPolling({ generationId: editGeneration.id }));
  }, [dispatch, editGeneration, isGenerationLoading]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }
    document.querySelector('.RootWrapper')?.classList.add('full-width');
    dispatch(setIsFullWidthContent(true));
  }, [dispatch, isFocused]);

  useEffect(() => {
    document.querySelector('.RootWrapper')?.classList.add('full-width');
    dispatch(setIsFullWidthContent(true));

    return () => {
      document.querySelector('.RootWrapper')?.classList.remove('full-width');
      dispatch(setIsFullWidthContent(false));
    };
  }, [dispatch]);

  const renderForm = useCallback(() => {
    switch (activeForm) {
      case EForm.SCRIPT_LIST:
        return (
          <>
            <S.GenerationHeader>
              <S.GenerationTitle>{editGeneration?.name}</S.GenerationTitle>
              <S.GenerationDescription>{editGeneration?.description}</S.GenerationDescription>
            </S.GenerationHeader>
            <S.ScriptListContainer>
              {!!bitesIds?.length && (
                <>
                  <S.ArticleTitle>Ready Bites</S.ArticleTitle>
                  <BiteList bitesIds={bitesIds} onBitePress={handleBitePress} />
                </>
              )}
              {!!scriptsWithoutBites?.length && (
                <>
                  <S.ArticleTitle>Create Bites</S.ArticleTitle>
                  <ScriptList scriptIds={scriptsWithoutBites} />
                </>
              )}
            </S.ScriptListContainer>
          </>
        );
      case EForm.GENERATING_SCRIPTS:
        return (
          <FormContainer>
            <S.LoaderTitle>Modernizing Your Content...</S.LoaderTitle>
            <S.LoaderContainer>
              <PdfIcon width={60} height={80} />
              <S.WavesContainer>
                <CreationAnimation width={270} />
              </S.WavesContainer>
            </S.LoaderContainer>
            <S.LoaderDescriptionContainer>
              <S.LoaderDescription>This process may take a few minutes.</S.LoaderDescription>
              <S.LoaderDescription>Feel free to continue with other tasks in the meantime;</S.LoaderDescription>
              <S.LoaderDescription>
                We will notify you via email as soon as your playlist is ready for review.
              </S.LoaderDescription>
            </S.LoaderDescriptionContainer>
          </FormContainer>
        );
      default:
        return (
          <FormContainer>
            <UploadForm />
          </FormContainer>
        );
    }
  }, [activeForm, bitesIds, editGeneration?.description, editGeneration?.name, handleBitePress, scriptsWithoutBites]);

  return (
    <S.Container>
      <Header />
      <S.Content>
        {activeForm !== EForm.SCRIPT_LIST && (
          <S.LogoContainer>
            <BitesLogoIcon width={180} height={85} />
            <S.LogoText>Bites AI creator</S.LogoText>
          </S.LogoContainer>
        )}
        {renderForm()}
        {activeForm === EForm.INITIAL && (
          <S.Article>
            <S.ArticleTitle>How does it work?</S.ArticleTitle>
            <S.ArticleText>
              Introducing Bites AI Studio, the revolutionary tool that transforms your old documents and presentations
              into dynamic, interactive training modules. By simply uploading your existing files, Bites leverages
              cutting-edge technology to create engaging and interactive content. Say goodbye to static training
              materials!
            </S.ArticleText>
          </S.Article>
        )}
      </S.Content>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ScriptListContainer: styled.View`
    width: ${calcWidth(886)}px;
  `,
  Content: styled.View`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: scroll;
    width: 100%;
    padding: ${calcHeight(60)}px 0 ${calcHeight(30)}px;
  `,
  ArticleTitle: styled.Text`
    width: 100%;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 17px;
    font-weight: 700;
    line-height: 150%; /* 36px */
    margin-bottom: ${calcHeight(10)}px;
  `,
  Article: styled.View`
    max-width: ${calcWidth(WEB_CONTAINER_WIDTH)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${calcHeight(64)}px;
  `,
  ArticleText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
  `,
  ArticleTextBold: styled.Text``,
  LogoContainer: styled.View`
    align-items: center;
    justify-content: center;
    width: ${calcWidth(180)}px;
    height: ${calcHeight(85)}px;
    margin-top: ${calcHeight(46)}px;
    margin-bottom: ${calcHeight(57)}px;
  `,
  LogoText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 21px;
    font-weight: 700;
    line-height: 28px;
  `,
  LoaderContainer: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${calcHeight(120)}px;
    margin: ${calcHeight(38)}px 0 ${calcHeight(48)}px;
  `,
  WavesContainer: styled.View`
    width: 270px;
    height: 100px;
  `,
  LoaderTitle: styled.Text`
    margin-top: ${calcHeight(48)}px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s28};
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
  `,
  GenerationHeader: styled.View`
    display: flex;
    width: ${calcWidth(886)}px;
    flex-direction: column;
    margin-top: ${calcHeight(36)}px;
    margin-bottom: ${calcHeight(40)}px;
  `,
  GenerationTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 26px;
    margin-bottom: ${calcHeight(14)}px;
  `,
  GenerationDescription: styled.Text`
    color: ${({ theme }) => theme.colors.lightGray40};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
  `,
  LoaderDescriptionContainer: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${calcHeight(38)}px;
  `,
  LoaderDescription: styled.Text`
    color: ${({ theme }) => theme.colors.lightGray40};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  `,
};

export default AiContent;
