import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generationConfigSelector } from '../../../store/editAiGeneration/editAiGeneration.selector';
import styled, { useTheme } from 'styled-components/native';
import {
  changeGenerationConfigTopic,
  removeGenerationConfigTopic,
  setGenerationConfigVoiceId,
  setGenerationConfigWithBgMusic,
  setGenerationConfigWithIntro,
} from '../../../store/editAiGeneration/editAiGeneration.slice';
import DeleteButton from '../../../components/shared/buttons/DeleteButton';
import Button from '../../../components/shared/buttons/Button';
import VoicesSlider from './VoiceSlider/VoicesSlider';
import { AWS_URL } from '../../../utils/constants/env';

const VOICES_LIST = [
  {
    id: 'e3SQYxMM1v1apbaHnt8w',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/evgeniya/evgeniya.png`,
    name: 'Angelina',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/evgeniya/evgeniya.mp3`,
    description: 'American, Calm, Young, Female, Narrative & Story',
  },
  {
    id: 'Xb7hH8MSUJpSbSDYk0k2',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/alice/alice.png`,
    name: 'Alice',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/alice/alice.mp3`,
    description: 'British, Confident, Middle-aged, News',
  },
  {
    id: '9BWtsMINqrJLrRacOk9x',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/aria/aria.png`,
    name: 'Aria',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/aria/aria.mp3`,
    description: 'American, Expressive, Middle-aged, Social media',
  },
  {
    id: 'pqHfZKP75CvOlQylNhV4',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/bill/bill.png`,
    name: 'Bill',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/bill/bill.mp3`,
    description: 'American, Trustworthy, Old, Narration',
  },
  {
    id: 'nPczCjzI2devNBz1zQrb',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/brian/brian.png`,
    name: 'Brian',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/brian/brian.mp3`,
    description: 'American, Deep, Middle-aged, Narration',
  },
  {
    id: 'N2lVS1w4EtoT3dr4eOWO',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/callum/callum.png`,
    name: 'Callum',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/callum/callum.mp3`,
    description: 'Transatlantic, Intense, Middle-aged, Characters',
  },
  {
    id: 'IKne3meq5aSn9XLyUdCD',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlie/charlie.png`,
    name: 'Charlie',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlie/charlie.mp3`,
    description: 'Australian, Natural, Middle aged, Conversational',
  },
  {
    id: 'XB0fDUnXU5powFXDhCwa',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlotte/charlotte.png`,
    name: 'Charlotte',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlotte/charlotte.mp3`,
    description: 'Swedish, Seductive, Young, Characters',
  },
  {
    id: 'iP95p4xoKVk53GoZ742B',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/chris/chris.png`,
    name: 'Chris',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/chris/chris.mp3`,
    description: 'American, Casual, Middle-aged, Conversational',
  },
  {
    id: 'onwK4e9ZLuTAKqWW03F9',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/daniel/daniel.png`,
    name: 'Daniel',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/daniel/daniel.mp3`,
    description: 'British, Authoritative, Middle-aged, News',
  },
  {
    id: 'cjVigY5qzO86Huf0OWal',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/eric/eric.png`,
    name: 'Eric',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/eric/eric.mp3`,
    description: 'American, Friendly, Middle-aged, Conversational',
  },
  {
    id: 'JBFqnCBsd6RMkjVDRZzb',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/george/george.png`,
    name: 'George',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/george/george.mp3`,
    description: 'British, Warm, Middle aged, Narration',
  },
  {
    id: 'cgSgspJ2msm6clMCkdW9',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/jessica/jessica.png`,
    name: 'Jessica',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/jessica/jessica.mp3`,
    description: 'American, Expressive, Young, Conversational',
  },
  {
    id: 'FGY2WhTYpPnrIDTdsKH5',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/laura/laura.png`,
    name: 'Laura',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/laura/laura.mp3`,
    description: 'American, Upbeat, Young, Social media',
  },
  {
    id: 'TX3LPaxmHKxFdv7VOQHJ',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/liam/liam.png`,
    name: 'Liam',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/liam/liam.mp3`,
    description: 'American, Articulate, Young, Narration',
  },
  {
    id: 'pFZP5JQG7iQjIQuC4Bku',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/lily/lily.png`,
    name: 'Lily',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/lily/lily.mp3`,
    description: 'British, Warm, Middle-aged, Narration',
  },
  {
    id: 'XrExE9yKIg1WjnnlVkGX',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/matilda/matilda.png`,
    name: 'Matilda',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/matilda/matilda.mp3`,
    description: 'American, Friendly, Middle-aged, Narration',
  },
  {
    id: 'SAz9YHcvj6GT2YYXdXww',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/river/river.png`,
    name: 'River',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/river/river.mp3`,
    description: 'American, Confident, Middle-aged, Social media',
  },
  {
    id: 'CwhRBWXzGAHq8TQ4Fs17',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/roger/roger.png`,
    name: 'Roger',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/roger/roger.mp3`,
    description: 'American, Confident, Middle-aged, Social media',
  },
  {
    id: 'EXAVITQu4vr4xnSDxMaL',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/sarah/sarah.png`,
    name: 'Sarah',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/sarah/sarah.mp3`,
    description: 'American, Soft, Young, News',
  },
  {
    id: 'bIHbv24MWmeRgasZH58o',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/will/will.png`,
    name: 'Will',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/will/will.mp3`,
    description: 'American, Friendly, Young, Social media',
  },
];

const GenerationConfigQuestionnaire = () => {
  const dispatch = useDispatch();
  const { topics, withBgMusic, withIntro, voiceId } = useSelector(generationConfigSelector);

  const handleChangeTopic = useCallback(
    (text: string, index: number) => {
      dispatch(changeGenerationConfigTopic({ index, value: text }));
    },
    [dispatch],
  );

  const handleRemoveTopic = useCallback(
    (index: number) => {
      dispatch(removeGenerationConfigTopic(index));
    },
    [dispatch],
  );

  const handleToggleWithIntro = useCallback(
    (value: boolean) => {
      dispatch(setGenerationConfigWithIntro(value));
    },
    [dispatch],
  );

  const handleToggleWithBgMusic = useCallback(
    (value: boolean) => {
      dispatch(setGenerationConfigWithBgMusic(value));
    },
    [dispatch],
  );

  const handleVoiceSelect = useCallback(
    (id: string) => {
      dispatch(setGenerationConfigVoiceId(id));
    },
    [dispatch],
  );

  return (
    <S.Container>
      <S.TopicInputsList>
        <S.TopicListTitle>What topics would you like to focus on:</S.TopicListTitle>
        {topics.map((topic, index) => (
          <Topic
            key={`topic-${index}`}
            withDelete={topics.length === index + 1}
            topic={topic}
            index={index}
            onChange={handleChangeTopic}
            onRemove={handleRemoveTopic}
          />
        ))}
      </S.TopicInputsList>
      <S.Toggle>
        <S.ToggleText>Do we want to add an intro to the video?</S.ToggleText>
        <ToggleButtons isActive={withIntro} onChange={handleToggleWithIntro} />
      </S.Toggle>

      <S.Toggle>
        <S.ToggleText>Do we want background music?</S.ToggleText>
        <ToggleButtons isActive={withBgMusic} onChange={handleToggleWithBgMusic} />
      </S.Toggle>

      <S.VoiceSliderContainer>
        <S.VoiceSliderTitle>Who will narrate your video?</S.VoiceSliderTitle>
        <VoicesSlider activeVoiceId={voiceId} voicesList={VOICES_LIST} onSelect={handleVoiceSelect} />
      </S.VoiceSliderContainer>
    </S.Container>
  );
};

const ToggleButtons = ({ isActive, onChange }) => {
  const handlePressYes = useCallback(() => {
    onChange(true);
  }, [onChange]);

  const handlePressNo = useCallback(() => {
    onChange(false);
  }, [onChange]);

  return (
    <S.ToggleButtons>
      <S.ToggleButton
        isActive={isActive}
        onPress={handlePressYes}
        content={<S.ButtonText isActive={isActive}>Yes</S.ButtonText>}
      />
      <S.ToggleButton
        isActive={!isActive}
        onPress={handlePressNo}
        content={<S.ButtonText isActive={!isActive}>No</S.ButtonText>}
      />
    </S.ToggleButtons>
  );
};

const Topic = ({ index, topic, onChange, onRemove, withDelete }) => {
  const theme = useTheme();
  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value, index);
    },
    [index, onChange],
  );

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  return (
    <S.Topic>
      <S.TopicInput
        placeholder={'Write a topic here'}
        placeholderTextColor={theme.colors.gray19}
        value={topic}
        onChange={handleChange}
      />
      {withDelete ? (
        <S.EmptyView />
      ) : (
        <DeleteButton containerViewStyle={S.TopicRemoveButtonStyles} onPress={handleRemove} width={40} height={40} />
      )}
    </S.Topic>
  );
};

const S = {
  Container: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 38px;
  `,
  TopicListTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin-bottom: 14px;
  `,
  TopicInput: styled.TextInput`
    width: 100%;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-radius: 14px;
    padding: 0 10px;
  `,
  TopicInputsList: styled.View`
    width: 100%;
  `,
  Topic: styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  `,
  TopicRemoveButtonStyles: {
    marginLeft: 10,
  },
  EmptyView: styled.View`
    margin-left: 10px;
    width: 40px;
    height: 40px;
  `,
  Toggle: styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
  `,
  ToggleText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
  ToggleButtons: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  `,
  ToggleButton: styled(Button)<{ isActive: boolean }>`
    width: 110px;
    height: 50px;
    margin: 0 5px;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.toggleButtonActiveBackground : theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
    align-items: center;
    justify-content: center;
  `,
  ButtonText: styled.Text<{ isActive: boolean }>`
    text-align: center;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryBlue : theme.colors.text)};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
    line-height: 140%; /* 22.4px */
  `,
  VoiceSliderContainer: styled.View`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 36px;
  `,
  VoiceSliderTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin-bottom: 14px;
  `,
};

export default GenerationConfigQuestionnaire;
