import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState, ILocalCloudAsset } from './editAiGeneration.types';
import { v4 as uuid } from 'uuid';

export const INITIAL_GENERATION_ID = 'initial';
export const initialState: IInitialState = {
  editGenerationId: INITIAL_GENERATION_ID,
  isRestartScreen: false,
  generationConfig: {
    inputCloudAssetIds: [],
    topics: [''],
    withIntro: true,
    withBgMusic: true,
    voiceId: 'e3SQYxMM1v1apbaHnt8w',
  },
  cloudAssets: [],
};

const EDIT_AI_GENERATION = 'EDIT_AI_GENERATION';

export const openProject = createAction<{ generationId: string }>(`${EDIT_AI_GENERATION}/openProject`);
export const createGeneration = createAction(`${EDIT_AI_GENERATION}/createGeneration`);
export const loadGenerationCloudAssets = createAction<{ generationId: string }>(
  `${EDIT_AI_GENERATION}/loadGenerationCloudAssets`,
);
export const startGenerationPolling = createAction<{ generationId: string }>(
  `${EDIT_AI_GENERATION}/startGenerationPolling`,
);

const editAiGenerationSlice = createSlice({
  name: EDIT_AI_GENERATION,
  initialState,
  reducers: {
    setGenerationConfig: (state, action) => {
      state.generationConfig = action.payload;
    },
    uploadCloudAssetSuccess: (state, action: PayloadAction<ILocalCloudAsset>) => {
      const index = state.cloudAssets.findIndex((asset) => asset.key === action.payload.key);
      if (index === -1) {
        return;
      }

      state.cloudAssets[index] = { ...state.cloudAssets[index], ...action.payload };
      state.generationConfig.inputCloudAssetIds.push(action.payload.data.cloudAssetId);
    },
    removeGenerationConfigTopic: (state, action) => {
      state.generationConfig.topics.splice(action.payload, 1);
    },
    changeGenerationConfigTopic: (state, action) => {
      state.generationConfig.topics[action.payload.index] = action.payload.value;

      if (!state.generationConfig.topics.some((t) => t.trim().length === 0)) {
        state.generationConfig.topics.push('');
      }
    },
    setGenerationConfigWithIntro: (state, action) => {
      state.generationConfig.withIntro = action.payload;
    },
    setGenerationConfigWithBgMusic: (state, action) => {
      state.generationConfig.withBgMusic = action.payload;
    },
    setGenerationConfigVoiceId: (state, action) => {
      state.generationConfig.voiceId = action.payload;
    },
    setGenerationCloudAssets: (state, action: PayloadAction<Partial<ILocalCloudAsset>[]>) => {
      const formattedCloudAssets = action.payload.map((cloudAsset) => {
        if (!cloudAsset.key) {
          return {
            ...cloudAsset,
            key: uuid(),
          };
        }
        return cloudAsset;
      });

      formattedCloudAssets.forEach((formattedCloudAsset) => {
        const index = state.cloudAssets.findIndex((asset) => asset.key === formattedCloudAsset.key);
        if (index === -1) {
          state.cloudAssets.push(formattedCloudAsset as ILocalCloudAsset);
          return;
        }

        state.cloudAssets[index] = { ...state.cloudAssets[index], ...formattedCloudAsset };
      });
    },
    removeCloudAsset: (state, action: PayloadAction<string>) => {
      const index = state.cloudAssets.findIndex((asset) => asset.key === action.payload);
      state.generationConfig.inputCloudAssetIds = state.generationConfig.inputCloudAssetIds.filter(
        (id) => id !== state.cloudAssets[index].data.cloudAssetId,
      );
      state.cloudAssets.splice(index, 1);
    },
    setEditGenerationId: (state, action) => {
      state.editGenerationId = action.payload;
    },
    setIsRestartScreen: (state, action) => {
      state.isRestartScreen = action.payload;
    },
    resetEditGeneration: () => initialState,
  },
});

export const {
  setGenerationConfig,
  uploadCloudAssetSuccess,
  removeGenerationConfigTopic,
  changeGenerationConfigTopic,
  setGenerationConfigWithIntro,
  setGenerationConfigWithBgMusic,
  setGenerationConfigVoiceId,
  setGenerationCloudAssets,
  removeCloudAsset,
  setEditGenerationId,
  setIsRestartScreen,
  resetEditGeneration,
} = editAiGenerationSlice.actions;

export default editAiGenerationSlice.reducer;
