import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/native';
import PlayIcon from '../../../../assets/icons/aiGeneration/play-icon.svg';
import PauseIcon from '../../../../assets/icons/aiGeneration/pause-icon.svg';
import Button from '../../../../components/shared/buttons/Button';
import CheckmarkIcon from '../../../../assets/icons/checkmark.svg';

const VoiceCard = ({ voice, activeVoiceId, activeAudioId, onPlayVoice, onSelect, width, height }) => {
  const handlePlay = useCallback(() => {
    onPlayVoice(voice.id, voice.sample);
  }, [onPlayVoice, voice.id, voice.sample]);

  const avatar = useMemo(() => ({ uri: voice.avatar }), [voice.avatar]);

  const handleSelect = useCallback(() => {
    onSelect(voice.id);
  }, [onSelect, voice]);

  return (
    <S.VoiceCard width={width} height={height}>
      <S.VoiceCardTop>
        <S.VoiceAvatarContainer>
          <S.VoiceCardAvatar source={avatar} />
          <S.PlayButton onPress={handlePlay} activeOpacity={1}>
            {activeAudioId === voice.id ? <PauseIcon width={24} height={24} /> : <PlayIcon width={24} height={24} />}
          </S.PlayButton>
        </S.VoiceAvatarContainer>
        <S.VoiceCardName>{voice.name}</S.VoiceCardName>
        <S.VoiceCardDescription>{voice.description}</S.VoiceCardDescription>
      </S.VoiceCardTop>
      <S.VoiceCardButton
        onPress={handleSelect}
        isActive={voice.id === activeVoiceId}
        content={
          <S.ButtonContent>
            {voice.id === activeVoiceId ? <CheckmarkIcon /> : <S.ButtonText>Choose</S.ButtonText>}
          </S.ButtonContent>
        }
      />
    </S.VoiceCard>
  );
};

const S = {
  VoiceCard: styled.View<{ width: number; height: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    margin: 0 10px;
  `,
  VoiceCardTop: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  VoiceCardAvatar: styled.Image`
    width: 75px;
    height: 75px;
    border-radius: 50px;
  `,
  VoiceAvatarContainer: styled.View`
    position: relative;
  `,
  VoiceCardName: styled.Text`
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 19.5px */
  `,
  VoiceCardDescription: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  `,
  VoiceCardButton: styled(Button)<{ isActive: boolean }>`
    width: 110px;
    height: 50px;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.toggleButtonActiveBackground : theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
    align-items: center;
    justify-content: center;
  `,
  PlayButton: styled.TouchableOpacity`
    z-index: 3;
    cursor: pointer;
    position: absolute;
    right: -2px;
    bottom: 4px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  `,
  ButtonText: styled.Text`
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
  ButtonContent: styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
};

export default VoiceCard;
