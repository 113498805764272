import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calcHeight } from '../../../../utils/dimensions';
import FeedItemsList from '../../../../components/feed/FeedItemsList';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';

import ShadowedContainer from '../../../../components/ShadowedContainer';
import { StyleProp, ViewStyle } from 'react-native';
import { navigate } from '../../../../navigation/RootNavigation';
import Routes from '../../../../navigation/routes';
import { getProjectsFeed } from '../../../../store/aiGeneration/aiGeneration.slice';
import { generationsFeedSelector } from '../../../../store/aiGeneration/aiGeneration.selector';
import ProjectFeedItem from './ProjectsFeedItem';
import Button from '../../../../components/shared/buttons/Button';

interface IProps {}

const shadowedMainContainerViewStyle: StyleProp<ViewStyle> = {
  alignSelf: 'center',
  marginTop: calcHeight(53),
};

const newBiteBtnStyle: StyleProp<ViewStyle> = { alignSelf: 'center' };

const ProjectFeed: FC<IProps> = () => {
  const dispatch = useDispatch();
  const { isLoading, page, total, data } = useSelector(generationsFeedSelector);

  const handlePullToRefresh = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'reload',
        props: { page_title: 'projects_feed' },
      }),
    );
    dispatch(getProjectsFeed({ reset: true }));
  }, [dispatch]);

  useEffect(() => {
    if (page !== 0) {
      return;
    }
    dispatch(getProjectsFeed());
  }, [dispatch, page]);

  const renderItem = useCallback(({ item }) => <ProjectFeedItem item={item} />, []);

  const keyExtractor = useCallback(({ generation: { id } }) => `${id}`, []);
  const handleEndReached = useCallback(() => {
    if (page === 0 || isLoading || page + 1 > total) {
      return;
    }

    dispatch(getProjectsFeed());
  }, [dispatch, isLoading, page, total]);

  const handleCreateProject = useCallback(() => {
    navigate(Routes.MainStack.AIContent);
  }, []);

  const renderEmptyList = useCallback(() => {
    return (
      <ShadowedContainer containerViewStyle={shadowedMainContainerViewStyle}>
        <Button content='Create a new project' style={newBiteBtnStyle} onPress={handleCreateProject} />
      </ShadowedContainer>
    );
  }, [handleCreateProject]);

  return (
    <FeedItemsList
      items={data}
      isLoading={isLoading}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      onEndReached={handleEndReached}
      onPullToRefresh={handlePullToRefresh}
      renderEmptyList={renderEmptyList}
    />
  );
};

export default ProjectFeed;
