import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import BiteItem from '../../../components/feed/BiteItem';
import { IBiteItem } from '../../../types/bite';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { useDispatch, useSelector } from 'react-redux';
import { bitesLoadingStateMapSelector } from '../../../store/bite/bite.selectors';
import Skeleton from '../../../components/shared/Skeleton';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import StarsIcon from '../../../assets/icons/stars.svg';
import Button from '../../../components/shared/buttons';
import { fetchFullBites } from '../../../store/bite/bite.actions';

interface IProps {
  bitesIds: number[];
  onBitePress: (options: {
    bite: IBiteItem;
    uploadingMediaStatus: EIntroMediaProcessingStatus;
    isDraft?: boolean;
  }) => void;
}

const BiteList: React.FC<IProps> = ({ bitesIds, onBitePress }) => {
  return (
    <S.BitesListContainer>
      {bitesIds.map((biteId) => (
        <Bite key={biteId} biteId={biteId} onPress={onBitePress} />
      ))}
    </S.BitesListContainer>
  );
};

const Bite = ({ biteId, onPress }) => {
  const dispatch = useDispatch();
  const biteStatus = useSelector(bitesLoadingStateMapSelector(biteId));

  const handleRetry = useCallback(() => {
    dispatch(fetchFullBites([biteId]));
  }, [biteId, dispatch]);

  if (biteStatus?.error) {
    return (
      <S.ErrorBiteContainer>
        <S.Content>
          <StarsIcon />
          <S.Title>Bite</S.Title>
          <S.ContinueProjectButton
            onPress={handleRetry}
            content={<S.ContinueProjectButtonText>Retry</S.ContinueProjectButtonText>}
          />
          <S.ErrorText>Something went wrong</S.ErrorText>
        </S.Content>
      </S.ErrorBiteContainer>
    );
  }

  if (biteStatus?.isLoading) {
    return (
      <S.BiteContainer>
        <Skeleton width={calcWidth(220)} height={calcHeight(276)} borderRadius={18} />
        <S.TitleSkeletonContainer>
          <Skeleton width={calcWidth(150)} height={calcHeight(15)} borderRadius={4} />
        </S.TitleSkeletonContainer>
      </S.BiteContainer>
    );
  }

  return <BiteItem biteId={biteId} onPress={onPress} />;
};

const S = {
  BitesListContainer: styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 40px;
  `,
  ErrorBiteContainer: styled.View`
    margin-left: ${calcWidth(10)}px;
    margin-bottom: ${calcHeight(22)}px;
    width: ${calcWidth(220)}px;
    height: ${calcHeight(276)}px;
    background-color: ${({ theme }) => theme.colors.shadowColor2};
    border-radius: 18px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  `,
  BiteContainer: styled.View`
    margin-left: ${calcWidth(10)}px;
    margin-bottom: ${calcHeight(22)}px;
    width: ${calcWidth(220)}px;
    height: ${calcHeight(276)}px;
    border-radius: 18px;
  `,
  TitleSkeletonContainer: styled.View`
    padding: 0 ${calcWidth(12)}px;
  `,

  Content: styled.View`
    width: ${calcWidth(160)}px;
    align-items: center;
    justify-content: center;
  `,
  Title: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s21}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    margin-top: ${calcHeight(7)}px;
  `,
  ContinueProjectButton: styled(Button)`
    margin-top: ${calcHeight(14)}px;
    margin-bottom: ${calcHeight(7)}px;
    width: ${calcWidth(160)}px;
    height: ${calcHeight(30)}px;
  `,
  ContinueProjectButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  `,
  ErrorText: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.red1};
    margin-top: ${calcHeight(5)}px;
  `,
};

export default BiteList;
