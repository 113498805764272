import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { MAX_ASSET_FILE_SIZE_MB } from '../../videoEditor/videoEditor.constants';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { getUrlExtension } from '../../../utils/getUrlExtension';
import styled from 'styled-components';
import defaultTheme from '../../../themes';
import DropzoneContent from './DropzoneContent';
import {
  cloudAssetsSelector,
  editGenerationIdSelector,
} from '../../../store/editAiGeneration/editAiGeneration.selector';
import {
  loadGenerationCloudAssets,
  removeCloudAsset,
  setGenerationCloudAssets,
  uploadCloudAssetSuccess,
} from '../../../store/editAiGeneration/editAiGeneration.slice';
import { v4 as uuid } from 'uuid';
import { retryFunctions } from '../aiGeneration.data';
import { processCloudAssetPdf } from '../../../store/api/bites-api/calls/cloudAssets.calls';
import { getErrorLogData } from '../../../utils/getErrorLogData';

const DROPZONE_ACCEPT = { 'application/pdf': [] };

const acceptedExtensions = ['.pdf'];
const getIsAllowedFileType = (file: FileWithPath) => {
  const extension = getUrlExtension(file.name);
  const extensionFormatted = `.${extension}`.toLowerCase();

  return acceptedExtensions.includes(extensionFormatted);
};

interface IProps {}

const FileUpload: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const webUpload = useRef(null);

  const editGenerationId = useSelector(editGenerationIdSelector);
  const currentCloudAssets = useSelector(cloudAssetsSelector);

  const [isDragging, setIsDragging] = useState(false);

  const downloadCloudAssetsWithError = useMemo(
    () => currentCloudAssets.map((asset) => (asset.downloadError ? asset : null)).filter(Boolean),
    [currentCloudAssets],
  );

  const handleFileSelect = useCallback(
    async (files: FileWithPath[]) => {
      setIsDragging(false);
      files.forEach((file) => {
        const isAllowedFileType = getIsAllowedFileType(file);
        if (!isAllowedFileType) {
          return;
        }

        const key = uuid();

        const sizeMb = Math.round(file.size / 1024 / 1024);
        if (sizeMb > MAX_ASSET_FILE_SIZE_MB) {
          dispatch(
            logError({
              event: 'FileUpload.handleFileSelect: file too large',
              data: {
                sizeMb,
                maxFileSizeMb: MAX_ASSET_FILE_SIZE_MB,
              },
            }),
          );
          dispatch(setGenerationCloudAssets([{ key, createError: 'File is too large' }]));
          return;
        }

        const retryFunction = async () => {
          try {
            dispatch(
              setGenerationCloudAssets([
                {
                  key,
                  data: {
                    name: file.name,
                    fileType: file.type,
                    cloudAssetId: null,
                  },
                  isLoading: true,
                  createError: null,
                  downloadError: null,
                },
              ]),
            );

            const { cloudAsset } = await new Promise<{
              cloudAsset: ICloudAsset;
            }>(async (resolve, reject) => {
              dispatch(
                createCloudAsset({
                  fileType: 'pdf',
                  file,
                  fileMeta: {
                    name: file.name,
                    mimeType: file.type,
                    size: file.size,
                  },
                  originalSrc: 'local',
                  onCreate: resolve,
                  onFail: reject,
                }),
              );
            });

            if (file.type === 'application/pdf') {
              processCloudAssetPdf({ cloudAssetId: cloudAsset.id }).catch((error) => {
                dispatch(
                  logError({
                    event: 'FileUpload.handleFileSelect: error processing cloud asset pdf',
                    data: {
                      error: getErrorLogData(error),
                    },
                  }),
                );
              });
            }

            dispatch(
              uploadCloudAssetSuccess({
                key,
                data: {
                  name: file.name,
                  fileType: file.type,
                  cloudAssetId: cloudAsset.id,
                },
                isLoading: false,
                createError: null,
                downloadError: null,
              }),
            );
          } catch (e) {
            dispatch(
              logError({
                event: 'FileUpload.handleFileSelect: error creating cloud asset',
                data: {
                  error: e,
                },
              }),
            );

            dispatch(setGenerationCloudAssets([{ key, createError: 'Error creating cloud asset' }]));
          }
        };

        retryFunctions[key] = retryFunction;
        retryFunction();
      });
    },
    [dispatch],
  );

  const handleRemoveFile = useCallback(
    (key: string) => {
      dispatch(removeCloudAsset(key));
    },
    [dispatch],
  );

  const handleRetryFailedCloudAssets = useCallback(() => {
    dispatch(loadGenerationCloudAssets({ generationId: editGenerationId }));
  }, [dispatch, editGenerationId]);

  const handleRemoveFailedCloudAssets = useCallback(() => {
    downloadCloudAssetsWithError.forEach((asset) => {
      dispatch(removeCloudAsset(asset.key));
    });
  }, [downloadCloudAssetsWithError, dispatch]);

  const handleDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  return (
    <Dropzone ref={webUpload} onDrop={handleFileSelect} maxFiles={1} accept={DROPZONE_ACCEPT}>
      {({ getRootProps, getInputProps }) => (
        <>
          <S.DropzoneUI isDragging={isDragging}>
            <DropzoneContent
              getRootProps={getRootProps}
              cloudAssets={currentCloudAssets}
              onRemoveFile={handleRemoveFile}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            />
            <S.DropzoneInput {...getInputProps()} />
          </S.DropzoneUI>

          {!!downloadCloudAssetsWithError.length && (
            <S.ErrorText>
              Failed to load {downloadCloudAssetsWithError.length} assets.{' '}
              <S.TextButton onClick={handleRetryFailedCloudAssets}>Retry</S.TextButton> |{' '}
              <S.TextButton onClick={handleRemoveFailedCloudAssets}>Remove</S.TextButton>
            </S.ErrorText>
          )}
        </>
      )}
    </Dropzone>
  );
};

const S = {
  DropzoneUI: styled.div<{ isDragging: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    border: 1px solid ${defaultTheme.colors.backgroundPurple};
    background: ${({ isDragging }) => (isDragging ? '#e3f2ff' : '#fbfcff')};
  `,
  DropzoneInput: styled.input`
    display: none;
  `,
  ErrorText: styled.div`
    color: ${defaultTheme.colors.failRed};
    font-size: 14px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-weight: 400;
    line-height: 140%;
    margin-top: 8px;
  `,
  TextButton: styled.span`
    color: ${defaultTheme.colors.primaryBlue};
    cursor: pointer;
    text-decoration: underline;
  `,
};

export default FileUpload;
