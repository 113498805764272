import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICloneCloudAssetCacheForTimelineItem,
  ICloudAsset,
  ICreateCloudAssetCache,
  ICreateCloudAssetPayload,
  IGenerateTalkingAvatar,
  IGetCloudAssets,
  IInitialState,
  IProcessCloudAssetMetaPayload,
  IResizeCloudAssetForResolutionPayload,
  LoadCloudAssets,
  ToggleIsBrandCloudAsset,
  ToggleIsBrandCloudAssetDone,
  ToggleIsBrandCloudAssetError,
  ToggleIsFavoriteCloudAsset,
  ToggleIsFavoriteCloudAssetDone,
  ToggleIsFavoriteCloudAssetError,
} from './cloudAssets.types';

export const initialState: IInitialState = {
  cloudAssets: {},
  cloudAssetCacheReady: {},
  toggleIsFavoriteById: {},
  toggleIsBrandById: {},
};

const sliceName = 'cloudAssets';

const cloudAssetsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCloudAsset: (
      state,
      {
        payload,
      }: PayloadAction<{
        cloudAsset: ICloudAsset;
      }>,
    ) => {
      state.cloudAssets[payload.cloudAsset.id!] = {
        cloudAsset: payload.cloudAsset,
        // isLoading: false,
        // isError: false,
      };
    },
    setCloudAssets: (
      state,
      {
        payload,
      }: PayloadAction<{
        cloudAssets: ICloudAsset[];
      }>,
    ) => {
      payload.cloudAssets.forEach((cloudAsset) => {
        state.cloudAssets[cloudAsset.id!] = {
          cloudAsset,
          // isLoading: false,
          // isError: false,
        };
      });
    },
    setCloudAssetCacheReady: (state, { payload }: PayloadAction<{ cloudAssetId: ICloudAsset['id'] }>) => {
      const n = state.cloudAssetCacheReady[payload.cloudAssetId] || 0;
      state.cloudAssetCacheReady[payload.cloudAssetId] = n + 1;
    },

    // favorite
    toggleIsFavoriteCloudAsset: (state, { payload }: PayloadAction<ToggleIsFavoriteCloudAsset>) => {
      state.toggleIsFavoriteById[payload.cloudAssetId] = {
        isLoading: true,
        error: null,
      };
    },
    toggleIsFavoriteCloudAssetDone: (state, { payload }: PayloadAction<ToggleIsFavoriteCloudAssetDone>) => {
      delete state.toggleIsFavoriteById[payload.cloudAssetId];
    },
    toggleIsFavoriteCloudAssetError: (state, { payload }: PayloadAction<ToggleIsFavoriteCloudAssetError>) => {
      state.toggleIsFavoriteById[payload.cloudAssetId] = {
        isLoading: false,
        error: null,
      };
    },
    // brand
    toggleIsBrandCloudAsset: (state, { payload }: PayloadAction<ToggleIsBrandCloudAsset>) => {
      state.toggleIsBrandById[payload.cloudAssetId] = {
        isLoading: true,
        error: null,
      };
    },
    toggleIsBrandCloudAssetDone: (state, { payload }: PayloadAction<ToggleIsBrandCloudAssetDone>) => {
      delete state.toggleIsBrandById[payload.cloudAssetId];
    },
    toggleIsBrandCloudAssetError: (state, { payload }: PayloadAction<ToggleIsBrandCloudAssetError>) => {
      state.toggleIsBrandById[payload.cloudAssetId] = {
        isLoading: false,
        error: null,
      };
    },
  },
});
export default cloudAssetsSlice.reducer;

export const {
  setCloudAsset,
  setCloudAssets,
  setCloudAssetCacheReady,
  // favorite
  toggleIsFavoriteCloudAsset,
  toggleIsFavoriteCloudAssetDone,
  toggleIsFavoriteCloudAssetError,
  // brand
  toggleIsBrandCloudAsset,
  toggleIsBrandCloudAssetDone,
  toggleIsBrandCloudAssetError,
} = cloudAssetsSlice.actions;

export const createCloudAsset = createAction<ICreateCloudAssetPayload>(`${sliceName}/createCloudAsset`);
export const generateTalkingAvatar = createAction<IGenerateTalkingAvatar>(`${sliceName}/generateTalkingAvatar`);
export const createCloudAssetCache = createAction<ICreateCloudAssetCache>(`${sliceName}/createCloudAssetCache`);

export const loadVideoAssets = createAction<IGetCloudAssets>(`${sliceName}/loadVideoAssets`);

export const cloneCloudAssetCacheForTimelineItem = createAction<ICloneCloudAssetCacheForTimelineItem>(
  `${sliceName}/cloneCloudAssetCacheForTimelineItem`,
);

export const resizeCloudAssetForResolution = createAction<IResizeCloudAssetForResolutionPayload>(
  `${sliceName}/resizeCloudAssetForResolution`,
);
export const processCloudAssetMeta = createAction<IProcessCloudAssetMetaPayload>(`${sliceName}/processCloudAssetMeta`);
export const loadCloudAssets = createAction<LoadCloudAssets>(`${sliceName}/loadCloudAssets`);
