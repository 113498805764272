import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { biteItemSelector, bitesLoadingStateMapSelector } from '../../../../../store/bite/bite.selectors';
import {
  setGenerationId,
  setScopeSource,
} from '../../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { activeOrganizationSelector } from '../../../../../store/auth/auth.selectors';
import Skeleton from '../../../../../components/shared/Skeleton';
import { IGeneration } from '../../../../../store/aiGeneration/aiGeneration.types';
import { useGenerationResultItemData } from '../../../../landing/Home/components/useGenerationResultItemData';
import defaultTheme from '../../../../../themes/defaultTheme';
import { getCreatedDateString } from '../../../../../utils/getCreatedDateString';

interface IProps {
  item: {
    generation: IGeneration;
    assetsNum: number;
    creator: {
      firstName: string;
      lastName: string;
    };
  };
  onClose?: () => void;
}

const GenerationResultItem: FC<IProps> = ({ item, onClose }) => {
  const dispatch = useDispatch();

  const { generation, assetsNum, creator } = item;
  const { CARDS_NUM, scriptBiteIds, overflowCount } = useGenerationResultItemData(item.generation.id);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(setGenerationId(generation.id));
      dispatch(setScopeSource('GENERATION'));
      onClose();
    },
    [dispatch, generation.id, onClose],
  );

  const renderBiteIds = [...Array(CARDS_NUM)].map((_, index) => scriptBiteIds[index] || null);
  const createdDateText = getCreatedDateString(new Date(generation.createDate));

  return (
    <S.ResultContainer onClick={handleClick}>
      <S.ImagesContainer>
        {renderBiteIds.map((biteId, index) =>
          biteId ? (
            <SmallBitePreview biteId={biteId} key={biteId} />
          ) : (
            <S.SmallBitePlaceholder key={`placeholder-${index}`} />
          ),
        )}
        {overflowCount > 0 && <S.OverflowCount>{`+${overflowCount}`}</S.OverflowCount>}
      </S.ImagesContainer>
      <S.ContentContainer>
        <S.Title>{generation.name}</S.Title>
        {creator && (
          <S.Description>
            {creator.firstName} {creator.lastName}
          </S.Description>
        )}
        <S.Description>{assetsNum} assets</S.Description>
        <S.Description>{createdDateText}</S.Description>
      </S.ContentContainer>
    </S.ResultContainer>
  );
};

const SmallBitePreview = ({ biteId }: { biteId: number }) => {
  const activeOrganization = useSelector(activeOrganizationSelector);
  const biteState = useSelector(bitesLoadingStateMapSelector(biteId));
  const bite = useSelector(biteItemSelector(biteId));

  return biteState?.isLoading ? (
    <Skeleton width={22} height={32} borderRadius={4} />
  ) : bite?.linked_cover_url ? (
    <S.Image src={bite.linked_cover_url} />
  ) : (
    <S.SmallBitePlaceholder>
      {activeOrganization?.brand_icon ? <S.Image src={activeOrganization?.brand_icon} /> : null}
    </S.SmallBitePlaceholder>
  );
};

const S = {
  ResultContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 14px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  ImagesContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    flex-shrink: 0;
    padding: 1px;
    width: 48px;
    height: 68px;
    border-radius: 4px;
    background-color: ${defaultTheme.colors.white};
    box-shadow: 0px 2.172px 8.253px rgba(106, 105, 125, 0.15);
  `,
  Image: styled.img`
    width: 22px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
    box-shadow: 0px 2.172px 8.253px 0px rgba(106, 105, 125, 0.15);
  `,
  SmallBitePlaceholder: styled.div`
    width: 22px;
    height: 32px;
    border-radius: 4px;
    background-color: ${defaultTheme.colors.feedProjectBite};
  `,
  OverflowCount: styled.div`
    position: absolute;
    bottom: 3px;
    right: 5px;
    font-size: 8px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    color: ${defaultTheme.colors.white};
  `,
  ContentContainer: styled.div``,
  Title: styled.div`
    overflow: hidden;
    color: ${defaultTheme.colors.text};
    text-overflow: ellipsis;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    line-height: 16px;
  `,
  Description: styled.div`
    color: ${defaultTheme.colors.gray19};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 13px;
    line-height: 16px;
  `,
};

export default React.memo(GenerationResultItem);
