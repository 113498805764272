import ShadowedContainer from '../../../components/ShadowedContainer';
import React, { useCallback, useMemo } from 'react';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import HomeButton from '../../../components/shared/HomeButton';
import Routes from '../../../navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { setIsFullWidthContent } from '../../../store/appActivity/appActivity.slice';
import { useDispatch, useSelector } from 'react-redux';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { INITIAL_GENERATION_ID, setIsRestartScreen } from '../../../store/editAiGeneration/editAiGeneration.slice';
import {
  editGenerationIdSelector,
  isRestartScreenSelector,
} from '../../../store/editAiGeneration/editAiGeneration.selector';
import ImproveIcon from '../../../assets/icons/aiGeneration/improve-icon.svg';
import { generationByIdSelector, isGenerationLoadingSelector } from '../../../store/aiGeneration/aiGeneration.selector';
import { EGenerationStatus } from '../../../store/aiGeneration/aiGeneration.types';

const OFFSET: [number, number] = [0, 1];
const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const activeOrganization = useSelector(activeOrganizationSelector);
  const editGenerationId = useSelector(editGenerationIdSelector);
  const isRestartScreen = useSelector(isRestartScreenSelector);
  const generation = useSelector(generationByIdSelector(editGenerationId));
  const isGenerationLoading = useSelector(isGenerationLoadingSelector(editGenerationId));

  const orgLogoSource = useMemo(() => ({ uri: activeOrganization?.brand_icon }), [activeOrganization?.brand_icon]);

  const handlePress = useCallback(() => {
    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
    dispatch(setIsFullWidthContent(false));
  }, [dispatch, navigation]);

  const handleRestartPress = useCallback(() => {
    dispatch(setIsRestartScreen(true));
  }, [dispatch]);

  const isScriptScreen = !isGenerationLoading && generation?.status === EGenerationStatus.DONE && !isRestartScreen;
  const isLoadingScreen = isGenerationLoading && !isRestartScreen;
  const showRestartButton = editGenerationId !== INITIAL_GENERATION_ID && (isScriptScreen || isLoadingScreen);

  return (
    <ShadowedContainer distance={4} offset={OFFSET} containerViewStyle={S.HeaderContainerStyles}>
      <S.Header>
        <HomeButton onPress={handlePress} />
        <S.LogoContainer>
          <S.OrgLogo resizeMode='contain' source={orgLogoSource} />
        </S.LogoContainer>
        {showRestartButton && (
          <S.RestartButton onPress={handleRestartPress}>
            <ImproveIcon />
            <S.RestartText>Restart</S.RestartText>
          </S.RestartButton>
        )}
      </S.Header>
    </ShadowedContainer>
  );
};

const S = {
  HeaderContainerStyles: {
    zIndex: 5,
    position: 'absolute',
    height: calcHeight(60),
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 8,
  } as StyleProp<ViewStyle>,
  Header: styled.View`
    height: ${calcHeight(60)}px;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 ${calcWidth(20)}px;
  `,
  LogoContainer: styled.View`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: ${calcHeight(50)}px;
    margin-left: 10px;
  `,
  OrgLogo: styled.Image`
    width: 100%;
    height: 100%;
  `,
  RestartButton: styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    margin-left: auto;
  `,
  RestartText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 16px;
  `,
};

export default Header;
