import { all, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createVideo,
  setGenerationScript,
  deleteScript,
  setCreateVideoStatusIsLoading,
  setCreateVideoStatusError,
  startScriptPolling,
  saveScriptChanges,
  setSavingScriptsStatusError,
  setSavingScriptsStatusIsLoading,
  fetchFullScript,
  setFullScriptsStatusError,
  assignBiteIdToScript,
  updateScript,
  removeEditScript,
  deleteScriptSuccess,
} from './aiGenerationScripts.slice';
import withRetry from '../../utils/withRetry';
import * as calls from '../api/bites-api/calls/aiGeneration.calls';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { IAction } from '../common/types';
import { EVideoScriptStatus } from './aiGenerationScripts.types';
import { logError } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import { editScriptSelector, isCreateVideoLoadingSelector, scriptSelector } from './aiGenerationScripts.selector';
import { getErrorLogData } from '../../utils/getErrorLogData';
import { isEqual } from 'lodash';

function* createVideoSaga({ payload: { scriptId } }: IAction<{ scriptId: string }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);

  yield put(setCreateVideoStatusIsLoading({ id: scriptId, state: true }));
  yield put(removeEditScript({ scriptId }));
  try {
    yield withRetry(
      () =>
        calls.createVideo({
          orgId,
          scriptId,
        }),
      {
        errorContext: {
          data: {
            action: 'createVideoSaga: createVideo',
          },
        },
      },
    );

    yield put(setCreateVideoStatusIsLoading({ id: scriptId, state: false }));
    yield spawn(startGeneratedScriptPollingSaga, { payload: { scriptId } });
  } catch (error) {
    yield put(setCreateVideoStatusError({ id: scriptId, error: error.message }));
  }
}

function* startGeneratedScriptPollingSaga({ payload: { scriptId } }: IAction<{ scriptId: string }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const isLoading = yield select(isCreateVideoLoadingSelector(scriptId));

  if (isLoading) {
    return;
  }

  yield put(setCreateVideoStatusIsLoading({ id: scriptId, state: true }));
  while (true) {
    try {
      const {
        data: { videoScript },
      } = yield withRetry(
        () =>
          calls.searchGeneratedScript({
            orgId,
            scriptId,
          }),
        {
          errorContext: {
            data: {
              action: 'startGeneratedScriptPollingSaga: searchGeneratedScript',
            },
          },
        },
      );

      if (videoScript.status === EVideoScriptStatus.VIDEO_IN_REVIEW) {
        yield put(setCreateVideoStatusIsLoading({ id: scriptId, state: false }));
        yield put(setGenerationScript(videoScript));
        break;
      }

      if (videoScript.status === EVideoScriptStatus.GENERATING_VIDEO_FAILED) {
        yield put(setCreateVideoStatusIsLoading({ id: scriptId, state: false }));
        yield put(setGenerationScript(videoScript));
        break;
      }

      yield new Promise((resolve) => setTimeout(resolve, 2000));
    } catch (e) {}
  }
}

function* deleteScriptSaga({ payload: { scriptId } }: IAction<{ scriptId: string }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);

  try {
    yield withRetry(
      () =>
        calls.deleteScript({
          orgId,
          scriptId,
        }),
      {
        errorContext: {
          data: {
            action: 'deleteScriptSaga: deleteScript request',
          },
        },
      },
    );

    yield put(deleteScriptSuccess({ scriptId }));
  } catch (error) {
    yield put(
      logError({
        event: 'deleteScriptSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* saveGeneratedTextChangesSaga({ payload: { scriptId } }: IAction<{ scriptId: string }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);

  const script = yield select(scriptSelector(scriptId));
  const editedScript = yield select(editScriptSelector(scriptId));

  if (!editedScript || isEqual(editedScript, script)) {
    return;
  }

  yield put(setSavingScriptsStatusIsLoading({ scriptId: scriptId, state: true }));
  try {
    yield withRetry(
      () =>
        calls.updateScript({
          orgId,
          scriptId,
          script: {
            config: editedScript.config,
          },
        }),
      {
        errorContext: {
          data: {
            action: 'saveGeneratedTextChangesSaga: saveGeneratedTextChanges',
          },
        },
      },
    );

    yield put(updateScript({ scriptId, script: editedScript }));
    yield put(setSavingScriptsStatusIsLoading({ scriptId: scriptId, state: false }));
  } catch (error) {
    yield put(
      logError({
        event: 'saveGeneratedTextChangesSaga: error',
        error,
      }),
    );

    yield put(setSavingScriptsStatusError({ scriptId: scriptId, error: error.message }));
  }
}

function* fetchFullScriptSaga({ payload: { scriptId } }: IAction<{ scriptId: string }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);

  try {
    const {
      data: { videoScript },
    } = yield withRetry(
      () =>
        calls.searchGeneratedScript({
          orgId,
          scriptId,
        }),
      {
        errorContext: {
          data: {
            action: 'fetchFullScriptSaga: searchGeneratedScript',
          },
        },
      },
    );

    yield put(setGenerationScript(videoScript));
  } catch (error) {
    yield put(setFullScriptsStatusError({ id: scriptId, error: error.message }));
  }
}

function* assignBiteIdToScriptSaga({ payload: { scriptId, biteId } }: IAction<{ scriptId: string; biteId: number }>) {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const script = yield select(scriptSelector(scriptId));

  try {
    yield withRetry(
      () =>
        calls.updateScript({
          orgId,
          scriptId,
          script: {
            biteId,
          },
        }),
      {
        errorContext: {
          data: {
            action: 'assignBiteIdToScript: assignBiteIdToScript',
          },
        },
      },
    );

    const updatedScript = {
      ...script,
      status: EVideoScriptStatus.BITE_GENERATED,
      biteId,
    };
    yield put(updateScript({ scriptId, script: updatedScript }));
  } catch (error) {
    yield put(
      logError({
        event: 'assignBiteIdToScript: error',
        data: {
          error: getErrorLogData(error),
          scriptId,
          biteId,
        },
      }),
    );
  }
}

export default function* aiGenerationScriptsSaga() {
  yield all([
    takeEvery(createVideo, createVideoSaga),
    takeLatest(deleteScript, deleteScriptSaga),
    takeEvery(startScriptPolling, startGeneratedScriptPollingSaga),
    takeLatest(saveScriptChanges, saveGeneratedTextChangesSaga),
    takeLatest(fetchFullScript, fetchFullScriptSaga),
    takeLatest(assignBiteIdToScript, assignBiteIdToScriptSaga),
  ]);
}
